import QuoteDetails from "../../QuoteDetails/QuoteDetails";
import { useQuoteContext } from "../../../contexts/QuoteContext/QuoteContext";
import ErrorComponentWrapper from "../../ErrorHandling/ErrorComponentWrapper";
import { useQuoteDetailsContext } from "../../../contexts/QuoteDetailsContext";

const QuoteComponent: React.FC = () => {
  const { selected, setupError } = useQuoteContext();
  const { quoteDraft, loadingQuoteError, loadingQuote } = useQuoteDetailsContext();

  return (
    <ErrorComponentWrapper
      isLoading={loadingQuote || (!selected && !quoteDraft && !setupError && !loadingQuoteError)}
      error={setupError || loadingQuoteError}
    >
      {selected && quoteDraft && <QuoteDetails quote={selected.quoteData} quoteDraft={quoteDraft} />}
    </ErrorComponentWrapper>
  );
};

export default QuoteComponent;
