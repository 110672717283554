import { LineItem, Quote, SalesItemCode, SalesItemSubType, SalesItemType } from "../Types";

export function getOptionalItems(segment: number, quote?: Quote, draft?: Quote): Array<LineItem> {
  if (!quote || !draft) {
    return [];
  }
  if (segment >= quote.segments.length) {
    return [];
  }
  const currentSegment = quote.segments[segment];
  const currentDraftSegment = draft.segments[segment];
  const optionalItems = currentSegment?.lineItems.filter(
    (lineItem) =>
      lineItem.isOptional &&
      lineItem.type !== SalesItemType.Accommodation &&
      lineItem.subType !== SalesItemSubType.Discount &&
      (lineItem.subType !== SalesItemSubType.Flight || lineItem.code === SalesItemCode.FTEFX) &&
      lineItem.totalPrice.amount > 0
  );
  return currentDraftSegment?.lineItems.filter((lineItem) =>
    optionalItems?.some((optionalItem) => optionalItem.sku === lineItem.sku)
  );
}
