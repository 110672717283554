import React, { useEffect, useMemo, useState } from "react";
import styles from "./QuoteDetails.module.scss";
import Surface from "../Surface/Surface";
import { Button, Chip, Icon, ImageCard, Loading, Text } from "@ilc-technology/luik";
import QuoteHeader from "./QuoteHeader/QuoteHeader";
import WhatsIncluded from "../WhatsIncluded/WhatsIncluded";
import BannerImage from "../BannerImage/BannerImage";
import Enrich from "../../Common/services/TextEnricher";
import {
  CourseCategories,
  CourseCategoryContent,
  CourseContent,
  EFComProgramCodes,
  HostFamilyCodes,
  ProgramCodes,
  Quote,
  QuotePaymentStatus,
  SalesItemSubType,
  SalesItemType,
  SegmentType,
  UpgradeCourseCode,
  WhatsIncludedCategories,
  WhatsIncludedItemType,
} from "../../Common/Types";
import { useDatasources } from "../../contexts/StoryblokContext/StoryblokContext";
import { useQuoteContext } from "../../contexts/QuoteContext/QuoteContext";
import { AppConfig } from "../../AppConfig";
import { LabelKey } from "../../Common/StoryblokTypes";
import QuoteSegment from "./QuoteSegment/QuoteSegment";
import { TRUE } from "../../Common/Constants";
import QuoteDetailsFooter from "./QuoteDetailsFooter/QuoteDetailsFooter";
import UpgradeCourse from "../UpgradeCourse/UpgradeCourse";
import { ContentType, CustomTrackingEvent, StandardTrackingEvent, trackEvent } from "../../Common/services/Analytics";
import UpgradeDuration from "../UpgradeDuration/UpgradeDuration";
import { UpgradeAccommodation } from "../UpgradeAccommodation/UpgradeAccommodation";
import { AtlasFallbackDestination, LineItemIsExtraNight } from "../../Common/Helpers/LineItemHelper";
import { UpgradeOptionalItems } from "../UpgradeOptionalItems/UpgradeOptionalItems";
import { getOptionalItems } from "../../Common/services/UpgradeService";
import { QuoteDraft } from "../../contexts/QuoteDetailsContext";
import { getQuoteInformation } from "../../Common/getQuoteInformation";
import { generatePriceQuoteEventItems } from "../../Common/services/AnalyticsMappings";

type CourseItems = {
  destinationCode: string;
  courses: Array<CourseContent>;
};

interface QuoteDetailsProps {
  quote: Quote;
  quoteDraft: QuoteDraft;
}

const QuoteDetails: React.FC<QuoteDetailsProps> = ({ quote, quoteDraft }) => {
  const quoteData = quoteDraft.quoteDraft.quoteData;
  const { labels, featureSettings, programs, activeLanguage, atlasfallbackDestinationMappings } = useDatasources();
  const { quotesContentCache, isQuotesDestinationsLoading } = useQuoteContext();
  const quoteInfo = getQuoteInformation(quoteDraft.quoteDraft.quoteData);

  const firstCourseSegmentCode = quoteInfo.courseSegments[0]?.course.code;
  const [currentSegmentIndex, setCurrentSegmentIndex] = useState(0);
  const [isGroupCourse, setGroupCourse] = useState(false);
  const [whatsIncludedContent, setWhatsIncludedContent] = useState<Array<WhatsIncludedItemType>>([]);
  const [whatsIncludedContentIsLoading, setWhatsIncludedContentIsLoading] = useState(false);
  const [courseContent, setCourseContent] = useState<Array<CourseItems>>();
  const [isSaveInProgress, setIsSaveInProgress] = useState(false);
  const isCourseIntensityUpgradeEnabled = featureSettings.isCourseIntensityUpgradeEnabled === TRUE;
  const isDurationUpgradeEnabled = featureSettings.isDurationUpgradeEnabled === TRUE;
  const isAccommodationUpgradeEnabled = featureSettings.isAccommodationUpgradeEnabled === TRUE;
  const isUpgradeOptionalItemsEnabled = featureSettings.isUpgradeOptionalItemsEnabled === TRUE;
  const currentSegment = quoteData.segments[currentSegmentIndex];

  const isInternationalLanguageProgram =
    quoteData.program === ProgramCodes.ILC || quoteData.program === ProgramCodes.ILS;
  const upsellActivationDate = new Date("2023-11-16");
  const hasSomeOptionalItems = getOptionalItems(currentSegmentIndex, quote, quoteData).length > 0;
  const isUpgradeOptionalItemsAvailable =
    !quoteInfo.isMultiQuote && isUpgradeOptionalItemsEnabled && hasSomeOptionalItems;
  const isCourseUpgradeAvailable =
    !quoteInfo.isMultiQuote &&
    isCourseIntensityUpgradeEnabled &&
    !isGroupCourse &&
    isInternationalLanguageProgram &&
    (firstCourseSegmentCode === UpgradeCourseCode.Basic || firstCourseSegmentCode === UpgradeCourseCode.General) &&
    new Date(quoteData.createdAt) >= upsellActivationDate;
  const upgradeCourseCode =
    firstCourseSegmentCode === UpgradeCourseCode.Basic ? UpgradeCourseCode.General : UpgradeCourseCode.Intensive;
  const isAccommodationUpgradeAvailable =
    !quoteInfo.isMultiQuote &&
    isAccommodationUpgradeEnabled &&
    quoteInfo.courseSegments?.[0]?.lineItems.find(
      (lineItem) =>
        lineItem.type === SalesItemType.Accommodation && lineItem.isOptional && !LineItemIsExtraNight(lineItem)
    ) &&
    //Upgrade Accommodation works only if we have one mandatory accommodation
    quoteInfo.courseSegments?.[0]?.lineItems.filter(
      (lineItem) =>
        lineItem.type === SalesItemType.Accommodation && !lineItem.isOptional && !LineItemIsExtraNight(lineItem)
    ).length === 1;
  const efcomProgramCode = quoteData.program
    ? programs[quoteData.program] ?? AppConfig.efcom.defaultProgramCode
    : AppConfig.efcom.defaultProgramCode;

  const weeksLabel =
    quoteInfo.totalDuration === 1
      ? labels[LabelKey.durationWeek]
      : quoteInfo.totalDuration > 4
        ? labels[LabelKey.duration5orMoreWeeks]
        : labels[LabelKey.duration2to4Weeks];
  const isDurationUpgradeAvailable =
    !quoteInfo.isMultiQuote &&
    isDurationUpgradeEnabled &&
    !isGroupCourse &&
    isInternationalLanguageProgram &&
    quoteInfo.courseSegments[0]?.duration.value < 16;
  const isUpsellAvailable =
    isDurationUpgradeAvailable ||
    isCourseUpgradeAvailable ||
    isAccommodationUpgradeAvailable ||
    isUpgradeOptionalItemsAvailable;

  const bannerImage = useMemo(
    () =>
      !isQuotesDestinationsLoading && quoteInfo.courseSegments.length
        ? quotesContentCache.destinations.get(quoteInfo.courseSegments[0].destinationCode)?.heroImage ??
          AppConfig.content.defaultBannerImage
        : "",
    [isQuotesDestinationsLoading, quoteInfo.courseSegments]
  );

  const schoolImage = useMemo(
    () =>
      !isQuotesDestinationsLoading && currentSegment
        ? quotesContentCache.destinations.get(currentSegment.destinationCode)?.schoolImage ??
          AppConfig.content.defaultSchoolImage
        : undefined,
    [isQuotesDestinationsLoading, currentSegment]
  );

  const destinationName = useMemo(
    () =>
      !isQuotesDestinationsLoading && quoteInfo.courseSegments.length
        ? quoteInfo.courseSegments
            .map((segment) => quotesContentCache?.destinations.get(segment.destinationCode)?.name)
            .join(" - ")
        : "",
    [isQuotesDestinationsLoading, quoteInfo.courseSegments]
  );

  const factSheetLink = useMemo(
    () =>
      !isQuotesDestinationsLoading &&
      activeLanguage?.atlas &&
      currentSegment?.destinationCode &&
      quotesContentCache.destinations.get(currentSegment.destinationCode)?.name
        ? `${AppConfig.api.atlasWebsite}/public/${activeLanguage.atlas}/destinations/${currentSegment.destinationCode.toLowerCase()}/school/fact-sheet/`
        : "",
    [isQuotesDestinationsLoading, activeLanguage, currentSegment]
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (quoteData) {
      trackEvent(StandardTrackingEvent.ViewItem, {
        currency: quoteData.prices.totalPrice.currency,
        value: quoteData.prices.totalPrice.amount,
        items: generatePriceQuoteEventItems(quoteData),
      });
    }
  }, [quote.id]);

  useEffect(() => {
    if (activeLanguage && quote) {
      const allDestinationCodes = quoteData.segments
        .filter((segment) => segment.type === SegmentType.Course)
        .map((segment) => segment.destinationCode);
      Promise.all(
        allDestinationCodes.map((destinationCode) =>
          fetch(
            //We need to modify Quotation to be able to return PrimaryDestinations with QuoteDrafts and Quotes. Until then this is a workaround
            `${AppConfig.api.productWebsite}/v2/courseCategories/${activeLanguage.efcom}/${efcomProgramCode}/?destination=${AtlasFallbackDestination(destinationCode, atlasfallbackDestinationMappings).toLocaleUpperCase()}`
          )
        )
      )
        .then((responses) => Promise.all(responses.map((response) => response.json())))
        .then((results) => {
          const allCourseTypes = results.map((destination, index) => ({
            destinationCode: allDestinationCodes[index],
            courses: destination
              .map((courseType: CourseCategories) =>
                courseType.courses.map((course: CourseCategoryContent) => ({
                  courseCode: course.code,
                  title: course.story.content.title,
                  lessonsPerWeek: course.story.content.lessonsPerWeek,
                  lessonsPerWeekText: course.story.content.lessonsPerWeekText,
                  lessonsPerWeekDescription: course.story.content.lessonsPerWeekDescription,
                  description: course.story.content.description,
                }))
              )
              .flat(),
          }));
          setCourseContent(allCourseTypes);
        })
        .catch(() => {
          setCourseContent([]);
        });
    }
  }, [activeLanguage, quote.id]);

  useEffect(() => {
    setWhatsIncludedContentIsLoading(true);

    if (activeLanguage && courseContent && currentSegment) {
      const storyFields =
        efcomProgramCode === EFComProgramCodes.LT
          ? currentSegment.group
            ? "whatsIncludedGroups"
            : "whatsIncludedIndividual"
          : "whatsIncluded";
      const showMeals =
        currentSegment.lineItems.filter((lineItem) =>
          Object.values(HostFamilyCodes).includes(lineItem.code as HostFamilyCodes)
        ).length > 0;
      const showFlights =
        currentSegment.lineItems.filter((lineItem) => lineItem.subType === SalesItemSubType.Flight).length > 0;

      fetch(
        `${AppConfig.api.productWebsite}/v2/destinations/${activeLanguage.efcom}/${efcomProgramCode}/?storyFields=${storyFields}&destinationCode=${currentSegment.destinationCode}`
      )
        .then((response) => response.json())
        .then((result) => {
          const currentCourseContent = courseContent
            .find((destination) => destination.destinationCode === currentSegment.destinationCode)
            ?.courses.find((course) => course.courseCode === currentSegment.course.code);
          const whatsIncluded = result[0].story.content[storyFields];
          const filteredWhatsIncluded = whatsIncluded
            .filter((whatsIncludedItem: WhatsIncludedItemType) => {
              if (!showMeals && whatsIncludedItem.category === WhatsIncludedCategories.Meals) return false;
              if (!showFlights && whatsIncludedItem.category === WhatsIncludedCategories.Flights) return false;
              return whatsIncludedItem;
            })
            .map((whatsIncludedItem: WhatsIncludedItemType) => {
              const courseAdditionalDescription =
                currentCourseContent?.lessonsPerWeek &&
                currentCourseContent?.lessonsPerWeekText &&
                currentCourseContent?.lessonsPerWeekDescription &&
                `${currentCourseContent?.lessonsPerWeek} ${currentCourseContent?.lessonsPerWeekText}\n${currentCourseContent?.lessonsPerWeekDescription}`;
              const currentCourseDescription = currentSegment.lineItems.find(
                (lineItem) => lineItem.type == SalesItemType.Course
              )?.description;
              if (whatsIncludedItem.category === WhatsIncludedCategories.Course)
                return {
                  ...whatsIncludedItem,
                  _uid: null,
                  description: courseAdditionalDescription
                    ? `${currentCourseDescription}\n${courseAdditionalDescription}`
                    : currentCourseDescription,
                };

              if (whatsIncludedItem.category === WhatsIncludedCategories.Accommodation)
                return {
                  ...whatsIncludedItem,
                  _uid: null,
                  description: currentSegment.group
                    ? whatsIncludedItem.description
                    : labels[LabelKey.accommodationOfYourChoice],
                };

              return whatsIncludedItem;
            });
          setWhatsIncludedContent(
            filteredWhatsIncluded.map((whatsIncludedItem: WhatsIncludedItemType) => {
              return {
                ...whatsIncludedItem,
                icon: {
                  iconName: "is-valid",
                  color: "#3C9957",
                },
              };
            })
          );
          setWhatsIncludedContentIsLoading(false);
        })
        .catch(() => {
          setWhatsIncludedContent([]);
          setWhatsIncludedContentIsLoading(false);
        });
    }
  }, [courseContent]);

  useEffect(() => {
    if (quote) {
      setCurrentSegmentIndex(quoteData.segments.findIndex((segment) => segment.id === quoteInfo.courseSegments[0].id));
      setGroupCourse(quoteInfo.courseSegments[0].group ? true : false);
    }
  }, [quote.id]);

  return (
    <div data-testid="quote-details">
      <BannerImage url={bannerImage} size="base" />
      <div className={styles.quoteContainer}>
        <QuoteHeader
          destinationName={destinationName}
          courseInfo={quoteInfo.courseInfo}
          startAt={quoteInfo.startAt}
          endAt={quoteInfo.endAt}
          duration={Enrich(weeksLabel, { numWeeks: quoteInfo.totalDuration })}
          totalPrice={quoteData.prices.totalPrice}
          payments={quote.payments}
        />
        {!quoteDraft.isQuoteDraftEdited && (
          <div className={styles.actionsSection}>
            <Button
              size="base"
              intent="secondary-black"
              iconName="cloud-download-outlined"
              onPress={() => {
                trackEvent(CustomTrackingEvent.DownloadPdf);
                window.open(quote.document.pdfUrl, "_blank");
              }}
            >
              {labels[LabelKey.pdf]}
            </Button>
          </div>
        )}
        <div className={`${styles.fullWidthBox} ${styles.xlScreenRowDirection}`} data-testid="quoteDetails-page">
          <div className={styles.fullWidthBox}>
            {courseContent &&
              [...quoteData.segments]
                .filter((segment) => segment.type !== SegmentType.Break)
                .sort((a, b) => b.type - a.type)
                .map((segment, index) => (
                  <QuoteSegment
                    key={index}
                    segmentType={segment.type}
                    lineItems={segment.lineItems
                      .filter((lineItem) => !lineItem.isOptional)
                      .map((lineItem) => {
                        const courseInfo = courseContent
                          ?.find((destination) => destination.destinationCode === segment.destinationCode)
                          ?.courses.find((course) => course.courseCode === segment.course.code);
                        return {
                          ...lineItem,
                          additionalDescription:
                            lineItem.courseType === courseInfo?.courseCode &&
                            courseInfo?.lessonsPerWeek &&
                            courseInfo?.lessonsPerWeekText
                              ? `${courseInfo.lessonsPerWeek} ${courseInfo.lessonsPerWeekText}`
                              : lineItem.additionalDescription,
                        };
                      })}
                    isMultiQuote={quoteInfo.isMultiQuote}
                    destinationName={quotesContentCache?.destinations.get(segment.destinationCode)?.name ?? ""}
                    duration={Enrich(weeksLabel, { numWeeks: segment.duration?.value })}
                    startAt={segment.startAt}
                    endAt={segment.endAt}
                    price={segment.price.totalPrice?.amount}
                    currency={segment.price.totalPrice?.currency}
                  />
                ))}
            {quote.status !== QuotePaymentStatus.Paid && isUpsellAvailable && (
              <div>
                <Surface>
                  <Text variant="heading-5-bold">{labels[LabelKey.upgrade]}</Text>
                  {isUpgradeOptionalItemsAvailable && (
                    <UpgradeOptionalItems
                      data-testid="upgrade-optional-items"
                      isSaveInProgress={isSaveInProgress}
                      setIsSaveInProgress={setIsSaveInProgress}
                      currentSegmentIndex={currentSegmentIndex}
                      quote={quote}
                      quoteDraft={quoteDraft.quoteDraft.quoteData}
                    />
                  )}
                  {isDurationUpgradeAvailable && (
                    <UpgradeDuration isSaveInProgress={isSaveInProgress} quote={quote} quoteDraft={quoteDraft} />
                  )}
                  {isCourseUpgradeAvailable && (
                    <UpgradeCourse
                      content={courseContent
                        ?.find((destination) => destination.destinationCode === currentSegment.destinationCode)
                        ?.courses.find((course) => course.courseCode === upgradeCourseCode)}
                      isSaveInProgress={isSaveInProgress}
                      startsOpen={!isDurationUpgradeAvailable}
                      quote={quote}
                      quoteDraft={quoteDraft.quoteDraft}
                    />
                  )}
                  {isAccommodationUpgradeAvailable && (
                    <UpgradeAccommodation
                      isSaveInProgress={isSaveInProgress}
                      startsOpen={!isDurationUpgradeAvailable && !isCourseUpgradeAvailable}
                      quote={quote}
                      quoteDraft={quoteDraft.quoteDraft}
                    />
                  )}
                </Surface>
              </div>
            )}
          </div>
          <div className={`${styles.fullWidthBox} ${styles.xlScreenReducedWidthBox}`}>
            <Text variant="heading-5-bold" className={styles.whatToExpectTitle}>
              {labels[LabelKey.whatToExpect]}
            </Text>
            {quoteInfo.courseSegments.length > 1 && (
              <div className={styles.segmentsNav}>
                {quoteInfo.courseSegments.map((segment) => {
                  const isCurrentSegment = segment.id == currentSegment.id;
                  return (
                    <div className={styles.whatsIncludedMultiButton} key={segment.id}>
                      <Chip
                        key={segment.id}
                        isSelected={isCurrentSegment}
                        onChange={() => setCurrentSegmentIndex(quoteData.segments.indexOf(segment))}
                      >
                        {quotesContentCache?.destinations.get(segment.destinationCode)?.name}
                      </Chip>
                    </div>
                  );
                })}
              </div>
            )}
            {whatsIncludedContentIsLoading ? (
              <Surface>
                <Loading className="loading" size="lg" intent="black" />
              </Surface>
            ) : (
              <>
                {whatsIncludedContent.length > 0 && (
                  <WhatsIncluded
                    title={Enrich(labels[LabelKey.includedInDestinationPrice], {
                      destinationName: `<span className=${styles.destinationLabel}>${
                        quotesContentCache?.destinations.get(currentSegment.destinationCode)?.name
                      }</span>`,
                    })}
                    whatsIncludedItems={whatsIncludedContent}
                  />
                )}
              </>
            )}
            {factSheetLink && (
              <div
                className={styles.factSheetCard}
                onClick={() => {
                  trackEvent(StandardTrackingEvent.SelectContent, {
                    content_type: ContentType.DestinationDetails,
                  });
                  window.open(factSheetLink, "_blank");
                }}
              >
                {schoolImage && (
                  <ImageCard
                    title={Enrich(labels[LabelKey.discoverDestination], {
                      destinationName: quotesContentCache?.destinations.get(currentSegment.destinationCode)?.name,
                    })}
                    aspectRatio="3/2"
                    image={<img alt="" src={schoolImage} />}
                    className="z-10"
                  >
                    <div className={styles.factSheetCardContent}>
                      {labels[LabelKey.infoSheet]} <Icon size="sm" iconName="arrow-right" />
                    </div>
                  </ImageCard>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {quote.status !== QuotePaymentStatus.Paid && (
        <QuoteDetailsFooter
          isSaveInProgress={isSaveInProgress}
          setSaveInProgress={setIsSaveInProgress}
          quote={quote}
          quoteDraft={quoteDraft}
        />
      )}
    </div>
  );
};

export default QuoteDetails;
