import React, { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { AppConfig } from "../../AppConfig";
import { AUTH_TOKEN_REGEX, SUCCESS } from "../../Common/Constants";
import "../../App.scss";
import { fetchRetry } from "../../Common/services/FetchRetry";
import { Loading } from "@ilc-technology/luik";
import { AuthorizationFailedErrorPage, TokenErrorPage } from "../../components/ErrorPages/ErrorPages";
import { ApiRequestType, ErrorCode, ErrorDetails } from "../../Common/Types";
import { GenerateHeaderInformation } from "../../Common/Helpers/ApiHelper";
import { useSearchParams } from "react-router-dom";
import { useImmer } from "use-immer";
import { convertToErrorDetails, returnJsonOrThrowError, logError } from "../../Common/services/ErrorService";

export interface SalesUser {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  photoUrl: string;
  title: string;
  aboutMe: string;
}

export interface User {
  accountId: string;
  languageCode: string;
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  invoiceEmail: string;
  gender: string;
  dateOfBirth: string;
  nationalityCountryCode: string;
  birthCountry: string;
  passportNumber: string;
  market: string;
  street: string;
  postalCode: string;
  city: string;
  country: string;
  mobilePhone: string;
  isFullUpdate: boolean;
  firstNameLatin: string;
  middleNameLatin: string;
  lastNameLatin: string;
  relatedContact: RelatedContact | undefined;
}

export interface AccountUpdateRequest {
  firstName: string;
  middleName: string | undefined;
  lastName: string;
  personDetails: PersonDetails;
  latin: Latin;
  addresses: Addresses;
  isFullUpdate: boolean;
}

interface Latin {
  firstName: string;
  lastName: string;
  middleName: string | undefined;
}

interface PersonDetails {
  gender: string | undefined;
  dateOfBirth: string | undefined;
  email: string | undefined;
  invoiceEmail: string | undefined;
  nationalityCountryCode: string;
  passportNumber: string | undefined;
  mobilePhone: string | undefined;
  birthCountry: string | undefined;
}

interface Addresses {
  mailingAddress: Address;
}

interface Address {
  countryCode: string;
  street: string | undefined;
  city: string | undefined;
  postalCode: string | undefined;
}

export interface RelatedContact {
  id: string | undefined;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  role: string;
  isEmergencyContact: boolean;
}

export interface Opportunity {
  id: string;
  salesforceId: string;
  salesOfficeCode: string;
  telephone: Telephone;
}

interface Session {
  accessToken: string;
  jwtAccessToken: string;
  salesUser: SalesUser;
  user: User;
  opportunity: Opportunity;
}

export type UserSessionResponse = {
  status: string;
  message?: string;
  session: {
    accessToken: string;
    user: User;
    opportunity: Opportunity;
    salesUser: SalesUser;
  };
  jwtAccessToken: string;
};

interface ContextValue {
  session: Session;
  updateAccount: (x: AccountUpdateRequest) => Promise<UpdateAccountResponse>;
  updateRelatedContact: (x: RelatedContact) => Promise<UpdateRelatedContactResponse>;
  language: string;
}

interface SalesOffice {
  poseidonCode: string;
  telephone: Telephone;
}

export type UpdateRelatedContactResponse = {
  isSuccessful: boolean;
  id?: string;
  error?: ErrorDetails;
};

export type UpdateAccountResponse = {
  isSuccessful: boolean;
  error?: ErrorDetails;
};

export interface Telephone {
  generalNumber: string;
  generalNumberFormattedForMobile: string;
}

const SessionContext = createContext<ContextValue | null>(null);

interface SessionContextProviderProps {
  children: ReactNode;
}

const SessionContextProvider: React.FC<SessionContextProviderProps> = ({ children }) => {
  const [session, updateSession] = useImmer({} as Session);
  const [language, setLanguage] = useState(navigator.language);
  const [isLoading, setIsLoading] = useState(true);
  const [authError, setAuthError] = useState<ErrorDetails | undefined>(undefined);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [noTokenError, setNoTokenError] = useState<ErrorDetails | undefined>(undefined);
  const [searchParams, setSearchParams] = useSearchParams();

  const removeTokenFromQuery = () => {
    if (searchParams.has("token")) {
      searchParams.delete("token");
      setSearchParams(searchParams);
    }
  };

  useEffect(() => {
    const token =
      document.location.search.match(AUTH_TOKEN_REGEX)?.[1] ??
      localStorage.getItem(AppConfig.sessionStorageKeys.authToken);

    if (!token) {
      setNoTokenError({
        code: ErrorCode.NoTokenProvidedOrInvalidFormat,
      });
    } else {
      localStorage.setItem(AppConfig.sessionStorageKeys.authToken, token);
    }

    if (!isAuthorized && token) {
      fetchRetry(`${AppConfig.api.efPlanner}/authorizations`, {
        method: "POST",
        mode: "cors",
        redirect: "manual",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => returnJsonOrThrowError<UserSessionResponse>(response))
        .then((result) => {
          if (result.status === SUCCESS) {
            updateSession({
              opportunity: result.session.opportunity,
              user: result.session.user,
              salesUser: result.session.salesUser,
              accessToken: result.session.accessToken,
              jwtAccessToken: result.jwtAccessToken,
            });
            setLanguage(result.session.user.languageCode ?? navigator.language);
            sessionStorage.setItem(AppConfig.sessionStorageKeys.jwtToken, result.jwtAccessToken);
            removeTokenFromQuery();
            setIsAuthorized(true);
          } else {
            setIsAuthorized(false);
            setNoTokenError({
              code: ErrorCode.AuthenticationFailed,
              details: {
                additionalDetails: `Authorization result status not successful: ${result.status}`,
              },
            });
            console.error(`Authorization result status not successful: ${result.status}`);
          }
        })
        .catch((ex) => {
          const errorDetails = convertToErrorDetails(ex, ErrorCode.AuthenticationFailed);
          logError(errorDetails);
          setAuthError(errorDetails);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!session?.opportunity?.salesOfficeCode || !session?.user?.market) {
      return;
    }

    fetchRetry(`${AppConfig.api.centralApi}/common/office/v2/getsalesoffices/`)
      .then((response) => returnJsonOrThrowError<Array<SalesOffice>>(response))
      .then((result: Array<SalesOffice>) => {
        if (session.opportunity.salesOfficeCode !== "" && session.user.market !== "") {
          const salesOffice = result.find(
            (entry: SalesOffice) => entry.poseidonCode === session.opportunity.salesOfficeCode
          );
          const marketSalesOffice = result.find((entry: SalesOffice) => entry.poseidonCode === session.user.market);
          if (salesOffice || marketSalesOffice) {
            const telephone = salesOffice?.telephone ?? marketSalesOffice?.telephone;
            updateSession((s) => {
              s.opportunity.telephone = telephone!;
            });
          }
        }
      })
      .catch((ex) => {
        const errorDetails = convertToErrorDetails(ex, ErrorCode.SalesOfficesLoadFailed);
        logError(errorDetails);
      });
  }, [session?.user?.market, session?.opportunity?.salesOfficeCode]);

  const updateAccount = async (body: AccountUpdateRequest): Promise<UpdateAccountResponse> => {
    if (!session?.user?.accountId) {
      return Promise.resolve({ isSuccessful: false });
    }

    return await fetchRetry(`${AppConfig.api.efPlanner}/customers/${session.user.accountId}`, {
      method: ApiRequestType.PATCH,
      headers: GenerateHeaderInformation(session.accessToken),
      body: JSON.stringify(body),
    })
      .then((response) => returnJsonOrThrowError<UpdateAccountResponse>(response))
      .then(async (result) => {
        if (result.isSuccessful) {
          updateSession((draft) => {
            draft.user.firstName = body.firstName;
            draft.user.lastName = body.lastName;
            draft.user.country = body.addresses.mailingAddress.countryCode;
          });
        }
        return {
          isSuccessful: result.isSuccessful,
          error: {
            code: ErrorCode.AccountUpdateRequestFailed,
            details: {
              additionalDetails: JSON.stringify(result),
            },
          },
        };
      })
      .catch((ex) => {
        const errorDetails = convertToErrorDetails(ex, ErrorCode.AccountUpdateRequestFailed);
        logError(errorDetails);
        return { isSuccessful: false, error: errorDetails };
      });
  };

  const updateRelatedContact = async (body: RelatedContact): Promise<UpdateRelatedContactResponse> => {
    if (!session?.user?.accountId) {
      return Promise.resolve({ isSuccessful: false });
    }

    return await fetchRetry(`${AppConfig.api.efPlanner}/customers/${session.user.accountId}/related-contact`, {
      method: ApiRequestType.PATCH,
      headers: GenerateHeaderInformation(session.accessToken),
      body: JSON.stringify(body),
    })
      .then((response) => returnJsonOrThrowError<UpdateRelatedContactResponse>(response))
      .then(async (result) => {
        if (result.isSuccessful) {
          updateSession((draft) => {
            draft.user.relatedContact = {
              ...draft.user.relatedContact,
              id: !!body.id ? body.id : result.id,
              firstName: body.firstName,
              lastName: body.lastName,
              email: body.email,
              phone: body.phone,
              role: body.role,
              isEmergencyContact: body.isEmergencyContact,
            };
          });
        }
        return {
          isSuccessful: result.isSuccessful,
          error: {
            code: ErrorCode.RelatedContactUpdateRequestFailed,
            details: {
              additionalDetails: JSON.stringify(result),
            },
          },
        };
      })
      .catch((ex) => {
        const errorDetails = convertToErrorDetails(ex, ErrorCode.RelatedContactUpdateRequestFailed);
        logError(errorDetails);
        return { isSuccessful: false, error: errorDetails };
      });
  };

  if (isLoading) {
    return (
      <div className="center">
        <Loading className="loading" size="lg" intent="black" />
      </div>
    );
  }

  if (authError) {
    return <AuthorizationFailedErrorPage errorDetails={authError} />;
  }

  if (noTokenError) {
    return <TokenErrorPage errorDetails={noTokenError} />;
  }

  return (
    <SessionContext.Provider
      value={{
        session,
        updateAccount,
        updateRelatedContact,
        language,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};

function useSessionContext() {
  const context = useContext(SessionContext);

  if (!context) {
    throw new Error("useSessionContext must be used within SessionContextProvider!");
  }

  return context;
}

export { SessionContextProvider, useSessionContext };
