import styles from "./QuoteCard.module.scss";
import { Button, Text } from "@ilc-technology/luik";
import { PaymentInfoStatus, SalesItemType, SegmentType } from "../../../Common/Types";
import Enrich from "../../../Common/services/TextEnricher";
import { useDatasources } from "../../../contexts/StoryblokContext/StoryblokContext";
import { LabelKey } from "../../../Common/StoryblokTypes";
import { QuoteData, useQuoteContext } from "../../../contexts/QuoteContext/QuoteContext";
import React, { useMemo } from "react";
import { AppConfig } from "../../../AppConfig";
import parse from "html-react-parser";
import { Link, useNavigate } from "react-router-dom";
import { addDays, formatDate } from "../../../Common/Helpers/DateHelper";
import { useSessionContext } from "../../../contexts/SessionContext/SessionContext";
import { ContentType, StandardTrackingEvent, trackEvent } from "../../../Common/services/Analytics";
import { getPaymentOrReservation } from "../../../Common/services/PaymentsService";

interface QuoteCardProps {
  quote: QuoteData;
}

const QuoteCard: React.FC<QuoteCardProps> = ({ quote }) => {
  const { language } = useSessionContext();
  const { labels } = useDatasources();
  const { quotesContentCache, isQuotesDestinationsLoading } = useQuoteContext();
  const { id, segments, payments } = quote.quoteData;
  const quoteUrl = !id ? "" : `/quote/${id}`;
  const courseSegments = quote.quoteData.segments.filter((segment) => segment.type === SegmentType.Course);
  const firstDestinationCode = courseSegments[0].destinationCode;

  const allDestinationNames = useMemo(
    () =>
      !isQuotesDestinationsLoading &&
      courseSegments.map((segment) => quotesContentCache.destinations.get(segment.destinationCode)?.name),
    [isQuotesDestinationsLoading]
  );

  const destinationLabel = useMemo(
    () =>
      !isQuotesDestinationsLoading && allDestinationNames && !allDestinationNames.includes(undefined)
        ? allDestinationNames.join(" & ")
        : "",
    [isQuotesDestinationsLoading, allDestinationNames]
  );

  const bannerImage = useMemo(
    () =>
      !isQuotesDestinationsLoading
        ? quotesContentCache.destinations.get(firstDestinationCode)?.heroImage ?? AppConfig.content.defaultBannerImage
        : "",
    [isQuotesDestinationsLoading]
  );

  const navigate = useNavigate();

  const startDate = formatDate(segments[0].startAt, language);
  const endDate = formatDate(segments[segments.length - 1].endAt, language);

  const courses = segments
    .filter((s) => s.type === SegmentType.Course)!
    .map((s) => s.lineItems.find((li) => li.type === SalesItemType.Course));
  const courseDescriptions = courses.map((s) => s?.description).join(" & ");
  const courseDuration = courses.reduce((acc, val) => acc + (val?.quantity ?? 0), 0);
  const weeksLabel =
    courseDuration === 1
      ? labels[LabelKey.durationWeek]
      : courseDuration > 4
        ? labels[LabelKey.duration5orMoreWeeks]
        : labels[LabelKey.duration2to4Weeks];

  const isQuotePaidOrReserved =
    payments && payments.find((x) => x.status === PaymentInfoStatus.Reserve || x.status === PaymentInfoStatus.Paid);
  const payment = getPaymentOrReservation(payments);

  const paymentMadeAt = payment?.madeAt
    ? payment?.status === PaymentInfoStatus.Paid
      ? formatDate(payment?.madeAt, language)
      : formatDate(addDays(new Date(payment?.madeAt), 1).toString(), language)
    : undefined;

  const enricherContext = isQuotePaidOrReserved
    ? {
        currentQuote: quote,
        paymentStatus: payment?.status,
        paymentMadeAt: paymentMadeAt,
      }
    : {
        currentQuote: quote,
        paymentStatus: "",
        paymentMadeAt: undefined,
      };
  const paymentInfoLabel = "paymentInfo_";

  const isMultiQuote = courses.length != 1;
  const bgColorStyle =
    isQuotePaidOrReserved && payment?.status == PaymentInfoStatus.Reserve
      ? styles.reservedBackground
      : styles.paidBackground;

  return (
    <div data-testid="quote-card">
      {isQuotePaidOrReserved && (
        <div className={`${styles.paymentInfo} ${bgColorStyle}`}>
          <Text variant="label-sm-bold">{Enrich(labels[paymentInfoLabel + payment?.status], enricherContext)}</Text>
        </div>
      )}
      <div className={`${styles.containerGrid} ${isQuotePaidOrReserved ? styles.paymentContainerGridBackground : ""}`}>
        <div className={styles.firstColumn}>
          <Link to={quoteUrl}>
            {bannerImage ? (
              <img className={styles.destinationImage} src={bannerImage} />
            ) : (
              <div className={styles.destinationImage} />
            )}
          </Link>
        </div>
        <div className={styles.secondColumn}>
          <div className={styles.courseInformation}>
            <label className={`${styles.duration} color-gray-45`}>
              {startDate} - {endDate} | {Enrich(weeksLabel, { numWeeks: courseDuration }).toUpperCase()}
            </label>
            <h3 className={styles.destination}>{destinationLabel}</h3>
            {!isMultiQuote && <Text variant="paragraph-small-body">{courseDescriptions}</Text>}
          </div>
          <div className={styles.priceInformation}>
            <Text variant="label-lg-bold" as="p">
              {parse(
                Enrich(
                  labels[LabelKey.quotePriceTotal]?.replace(/(\{quoteTotalPrice\})/g, "<nobr>$1</nobr>"),
                  enricherContext
                )
              )}
            </Text>
            <Text variant="label-sm-bold" className="color-gray-45" as="p">
              {Enrich(labels[LabelKey.quotePricePerWeek], enricherContext)}
            </Text>
          </div>
          <div className={styles.quoteButton}>
            <Button
              size="sm"
              intent="secondary-black"
              onPress={() => {
                trackEvent(StandardTrackingEvent.SelectContent, {
                  content_type: ContentType.QuoteDetails,
                  content_id: id,
                });
                navigate(quoteUrl);
              }}
            >
              {labels[LabelKey.showDetails]}
            </Button>
          </div>
          <div className={`${styles.quoteValidityInformation} color-gray-45`}>
            <Text variant="paragraph-caption" className="color-gray-45" as="p">
              {Enrich(labels[LabelKey.quoteValidUntil], {
                quoteValidToDate: formatDate(quote?.quoteData?.validUpTo, language),
              })}
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuoteCard;
