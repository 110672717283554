import { PaymentStatus } from "../Types";
import { useDatasources } from "../../contexts/StoryblokContext/StoryblokContext";
import { LabelKey } from "../StoryblokTypes";

export type PaymentDetails = {
  status?: PaymentStatus;
  icon: string;
  color: string;
  headerMessage: string;
  contentMessage?: string;
  statusText?: string;
};

const ConstructPaymentDetails = (status: PaymentStatus | null): PaymentDetails => {
  const { labels } = useDatasources();

  switch (status) {
    case PaymentStatus.PaymentSuccess:
      return {
        status: PaymentStatus.PaymentSuccess,
        headerMessage: labels[LabelKey.paymentStatus_PaymentSuccessHeader],
        icon: "check",
        color: "bg-success-dark",
        statusText: labels[LabelKey.paid],
      };
    case PaymentStatus.PaymentProcessed:
      return {
        status: PaymentStatus.PaymentProcessed,
        headerMessage: labels[LabelKey.paymentStatus_PaymentProcessedHeader],
        icon: "hourglass",
        color: "bg-warning-dark",
        statusText: labels[LabelKey.pending],
      };
    case PaymentStatus.ReservationSuccess:
      return {
        status: PaymentStatus.ReservationSuccess,
        headerMessage: labels[LabelKey.paymentStatus_ReservationSuccessHeader],
        icon: "locked",
        color: "bg-default-dark",
        statusText: labels[LabelKey.toBePaid],
      };
    // Should never reach this case,
    // unless the payment summary page is accessed directly, without the query params
    default:
      return {
        icon: "support",
        color: "bg-danger-dark",
        headerMessage:
          "There was an issue displaying the payment status at the moment. Please contact support if you have any questions.",
        statusText: "Unknown",
      };
  }
};

export default ConstructPaymentDetails;
