import { AppConfig } from "../AppConfig"
import { convertToErrorDetails, logError, returnJsonOrThrowError } from "../Common/services/ErrorService";
import { fetchRetry } from "../Common/services/FetchRetry"
import { ApiRequestType, ErrorCode, ErrorDetails } from "../Common/Types"

const AUTHORIZATION_SCHEME = "Bearer";

export type Allergies = "PETS_ALLERGY" | "POLLEN_ALLERGY" | "NUTS_ALLERGY" | "INSECT_ALLERGY" | "DUST_ALLERGY" | "PENICILLIN_ALLERGY";
export type DietaryNeeds = "HALAL" | "GLUTEN_FREE" | "VEGAN" | "VEGETARIAN" | "KOSHER" | "LACTOSE_FREE";
export type Disabilities = "USES_A_WHEELCHAIR" | "VISUALLY_IMPAIRED" | "DEAF";
export type MedicalNeeds = "EPILEPTIC" | "ASTHMATIC" | "DIABETIC";

const medicalMap: { [key: string]: Allergies | DietaryNeeds | Disabilities | MedicalNeeds } = {
  "Dust": "DUST_ALLERGY",
  "Insects": "INSECT_ALLERGY",
  "Nuts": "NUTS_ALLERGY",
  "Pets": "PETS_ALLERGY",
  "Pollen": "POLLEN_ALLERGY",
  "Vegan": "VEGAN",
  "Halal": "HALAL",
  "Gluten free": "GLUTEN_FREE",
  "Kosher": "KOSHER",
  "Vegetarian": "VEGETARIAN",
  "Lactose free": "LACTOSE_FREE",
  "Asthmatic": "ASTHMATIC",
  "Epileptic": "EPILEPTIC",
  "Diabetic": "DIABETIC",
  "Deaf": "DEAF",
  "Visually impaired": "VISUALLY_IMPAIRED",
  "Uses a Wheelchair": "USES_A_WHEELCHAIR",
  "Penicillin": "PENICILLIN_ALLERGY"
};

export interface MedicalsUpsertRequest {
  flags: string[],
  allergyNotes: string,
  dietaryNotes: string,
  disabilityNotes: string,
  carriesMedication: string,
  otherMedicalNotes: string
}

export type MedicalsUpsertResponse = {
  isSuccessful: boolean;
  error?: ErrorDetails;
};

export interface MedicalDetailsResponse {
  allergies: Allergies[],
  dietaryNeeds: DietaryNeeds[],
  disabilities: Disabilities[],
  medicalNeeds: MedicalNeeds[],
  allergyNotes: string,
  dietaryNotes: string,
  disabilityNotes: string,
  medicalNotes: string,
  medications: string
}

interface MedicalsResponse {
  id: string,
  customerId: string,
  items: MedicalItem[]
}

export interface MedicalItem {
  description: string,
  category: string,
  note: string,
  carriesMedication: string,
  types: string[]
}

export const getMedicals = async (accountId: string): Promise<MedicalDetailsResponse> => {
  const accessToken = sessionStorage.getItem(AppConfig.sessionStorageKeys.jwtToken);

  return await fetchRetry(`${AppConfig.api.customerServiceApi}/customers/${accountId}/medicals`, {
    method: ApiRequestType.GET,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `${AUTHORIZATION_SCHEME} ${accessToken}`,
    }
  })
    .then((response) => returnJsonOrThrowError<MedicalsResponse>(response))
    .then((result) => {
      const allergies = result.items.find(item => item.category === "allergies");
      const dietaryNeeds = result.items.find(item => item.category === "dietary-needs");
      const disabilities = result.items.find(item => item.category === "disabilities");
      const medicals = result.items.find(item => item.category === "others");

      return {
        allergies: allergies?.types.filter(i => medicalMap[i]).map(item => medicalMap[item]),
        allergyNotes: allergies?.note,
        dietaryNeeds: dietaryNeeds?.types.filter(i => medicalMap[i]).map(item => medicalMap[item]),
        dietaryNotes: dietaryNeeds?.note,
        disabilities: disabilities?.types.filter(i => medicalMap[i]).map(item => medicalMap[item]),
        disabilityNotes: disabilities?.note,
        medicalNeeds: medicals?.types.filter(i => medicalMap[i]).map(item => medicalMap[item]),
        medicalNotes: medicals?.note,
        medications: medicals?.carriesMedication
      } as MedicalDetailsResponse;
    })
};

export const upsertMedicals = async (accountId: string, body: MedicalsUpsertRequest): Promise<MedicalsUpsertResponse> => {
  const accessToken = sessionStorage.getItem(AppConfig.sessionStorageKeys.jwtToken);

  return await fetchRetry(`${AppConfig.api.customerServiceApi}/customers/${accountId}/medicals`, {
    method: ApiRequestType.PUT,
    body: JSON.stringify(body),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `${AUTHORIZATION_SCHEME} ${accessToken}`,
    }
  })
    .then((response) => {
      if (response.ok) {
        return { isSuccessful: true }
      }

      const responseCode = response.status;
      return response.text().then((errorBodyAsText) => {
        throw new ErrorDetails({
          responseBody: errorBodyAsText,
          responseStatusCode: responseCode,
          efFlowId: response.efFlowId,
          url: response.url,
        });
      });
    })
    .catch((ex) => {
      const errorDetails = convertToErrorDetails(ex, ErrorCode.MedicalDetailsUpdateFailed);
      logError(errorDetails);
      return { isSuccessful: false, error: errorDetails };
    });
}