import React, { useState } from "react";
import { Accordion, Button, Text } from "@ilc-technology/luik";
import { useDatasources } from "../../contexts/StoryblokContext/StoryblokContext";
import { LabelKey } from "../../Common/StoryblokTypes";
import UpgradeAccommodationModal from "./UpgradeAccommodationModal";
import { QuoteData } from "../../contexts/QuoteContext/QuoteContext";
import { Quote } from "../../Common/Types";

interface UpgradeAccommodationProps {
  isSaveInProgress: boolean;
  startsOpen: boolean;
  quote: Quote;
  quoteDraft: QuoteData;
}

export const UpgradeAccommodation: React.FC<UpgradeAccommodationProps> = ({
  isSaveInProgress,
  startsOpen,
  quote,
  quoteDraft,
}) => {
  const { labels } = useDatasources();
  const [isModalOpen, setModalOpen] = useState(false);

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <div className="flex flex-col" data-testid="upgrade-accommodation-component">
      <Accordion
        startsOpen={startsOpen}
        intent="black"
        className="h-full"
        data={[
          {
            title: labels[LabelKey.changeAccommodation],
            children: (
              <>
                <div className="mb-5 flex">
                  <Text variant="paragraph-body">{labels[LabelKey.changeAccommodationDescription]}</Text>
                </div>

                <div className="mx-6 mb-4 flex justify-end">
                  <div>
                    <Button
                      data-testid="change-accommodation-button"
                      size="base"
                      intent="primary-black"
                      iconName="arrow-right"
                      iconPosition="end"
                      onPress={handleModalOpen}
                      isDisabled={isSaveInProgress}
                    >
                      {labels[LabelKey.change]}
                    </Button>
                  </div>
                </div>
              </>
            ),
          },
        ]}
      />
      {isModalOpen && (
        <UpgradeAccommodationModal
          isModalOpen={isModalOpen}
          isSaveInProgress={isSaveInProgress}
          handleModalClose={handleModalClose}
          quote={quote}
          quoteDraft={quoteDraft}
        />
      )}
    </div>
  );
};
