import { IconProps } from "@ilc-technology/luik";

export type Guid = string;

export type Iso8601Date = string;

export type stringNullable = string | null | undefined;

export type StringIndexableDictionary<T> = {
  [key: string]: T;
};

export enum ApiRequestType {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  PATCH = "PATCH",
  DELETE = "DELETE",
}

export enum HttpPatchOperationType {
  REPLACE = "REPLACE",
}

export enum Gender {
  Male = "Male",
  Female = "Female",
}

export enum SalesItemType {
  None = 0,
  Tax = 10,
  CourseSpecific = 100,
  SpecialArticle = 105,
  SystemArticle = 30,
  EnrollmentFee = 35,
  Item = 40,
  Vtm = 45,
  Other = 50,
  Insurance = 60,
  Travel = 70,
  Accommodation = 80,
  Deposit = 85,
  Course = 90,
}

export enum SalesItemSubType {
  NoSubtype = 1,
  AgentCommission = 2,
  Discount = 3,
  ExplorePack = 4,
  FunPack = 5,
  SportsOptions = 6,
  Transfer = 7,
  CaxInsurance = 8,
  EnrollmentFee = 9,
  HostFamily = 10,
  HostFamilyExtraNight = 11,
  Hotel = 12,
  NoAccommodation = 13,
  Other = 14,
  Residence = 15,
  ResidenceExtraNight = 16,
  OptionalExcursions = 17,
  PostTours = 18,
  HotelExtraNight = 19,
  ProtectionCoverage = 20,
  Activities = 21,
  Flight = 22,
  ReunionParty = 23,
  Meals = 24,
  TravelInsurance = 25,
  ActivityVouchers = 26,
  AcademicClasses = 27,
  Internship = 28,
  Books = 29,
  AccommodationServices = 30,
  Gadgets = 31,
  Scholarship = 32,
  VisaRelated = 33,
  UniversityCounselling = 34,
  TuitionDeposit = 35,
  AccommodationDeposit = 36,
  WorkAndTravel = 37,
  Item = 38,
  BoatTrainBus = 39,
  ExamExtension = 40,
  VtmCard = 41,
  ArrivalTransfer = 42,
  DepartureTransfer = 43,
  ArrivalAndDeparture = 44,
  Extension = 45,
  Termination = 46,
  LocalTransportation = 47,
  BalanceCleanup = 48,
  Refunds = 50,
  Course = 100,
}

export enum SalesItemCode {
  FTEFX = "FTEFX",
}

export enum ApplyType {
  None = 0,
  Mandatory = 1,
  Suggested = 2,
}

export enum UnitType {
  Unit = 0,
  Week = 1,
  Term = 2,
  Day = 3,
}

export enum LineItemCategory {
  Course = 0,
  SalesItem = 1,
  GroupCourse = 2,
}

export enum PriceType {
  FP = 0,
  EP = 1,
  FI = 2,
  NP = 3,
}

export enum CategoryType {
  PRW = 1,
}

export enum SegmentType {
  Default = 0,
  Course = 1,
  Break = 2,
}

export enum DurationUnit {
  Week = "Week",
  Day = "Day",
}

export enum PaymentStatus {
  ReservationSuccess = "ReservationSuccess",
  PaymentSuccess = "PaymentSuccess",
  PaymentProcessed = "PaymentProcessed",
}

export enum PaymentOptions {
  PartialPayment = "PartialPayment",
  FullPayment = "FullPayment",
}

export type LineItem = {
  id: Guid;
  bundleId: Guid;
  code: string;
  courseType: string;
  sku: string;
  isBundled: boolean;
  type: SalesItemType;
  subType: SalesItemSubType;
  destination: string;
  departurePoint: stringNullable;
  description: string;
  englishDescription: string;
  additionalDescription: string;
  category: LineItemCategory;
  unitType: UnitType;
  order: number;
  startAt: Iso8601Date;
  endAt: Iso8601Date;
  price: Money;
  quantity: number;
  totalPrice: Money;
  isOptional: boolean;
  applyType: ApplyType;
  parentId: Guid;
  isPreparationCourse: boolean;
  priceType: PriceType;
  isDeleted: boolean;
  isPublic: boolean;
};

export type Duration = {
  value: number;
  unit: DurationUnit;
};

export type SegmentCourse = {
  code: string;
  name: string;
  price: Money;
};

export type SegmentGroupCourse = {
  code: string;
  id: string;
  sellingEntity: string;
  accommodationType: SalesItemSubType;
  hfArticleCode: string;
  residenceArticleCode: string;
};

export type Segment = {
  id: Guid;
  type: SegmentType;
  duration: Duration;
  destinationCode: string;
  primaryDestinationCode: string;
  departurePoint: stringNullable;
  course: SegmentCourse;
  group: SegmentGroupCourse | null;
  startAt: Iso8601Date;
  endAt: Iso8601Date;
  note: string;
  price: Price;
  category: CategoryType;
  lineItems: Array<LineItem>;
};

export type Money = {
  amount: number;
  currency: string;
};

export enum PaymentInfoStatus {
  Reserve = "Reserve",
  Paid = "Paid",
}

export type PaymentInfo = {
  payment: Money;
  status: PaymentInfoStatus;
  madeAt: Iso8601Date;
};

export type Price = {
  brochure: string;
  season: string;
  totalPrice: Money;
  optionalPrice: Money;
};

export type Customer = {
  firstName: string;
  lastName: string;
  gender: Gender | undefined;
  dob: string;
  countryOfResidence: string;
};

export type QuoteSalesUser = {
  salesOffice: string;
  userName: string;
  email: string;
};

export type Document = {
  isHideDatesInPDF: boolean;
  emailSubject: string;
  pdfName: string;
  pdfUrl: string;
  note: string;
  status: number;
};

export type Quote = {
  id: Guid;
  opportunityUuid: Guid;
  product: string;
  program: string;
  market: string;
  language: string;
  isPublic: boolean;
  createdAt: Iso8601Date;
  validUpTo: Iso8601Date;
  prices: Price;
  payments: Array<PaymentInfo> | null;
  customer: Customer;
  salesUser: QuoteSalesUser;
  document: Document;
  segments: Array<Segment>;
  status: QuotePaymentStatus;
};

export type InitiatePaymentRequest = {
  quoteExternalId: Guid;
  languageCode: string;
  description: string;
  termsVersion: string;
};

export type InitiateOraclePaymentRequest = {
  externalId: Guid;
  termsVersion: string;
  isFullPayment: boolean;
  returnUrl: string;
};

export type InitiateOraclePaymentSessionResponse = {
  sessionData: string;
  sessionId: string;
  correlationId: string;
  merchantReference: string;
};

export type ReservationRequest = {
  quoteId: Guid;
  termsVersion: string;
};

export type ReservationResponse = {
  quoteId: Guid;
  message: string;
};

export type CourseContent = {
  title: string;
  courseCode: string;
  lessonsPerWeek: string;
  lessonsPerWeekText: string;
  lessonsPerWeekDescription: string;
  description: string;
};

export type CourseCategoryContent = {
  code: string;
  story: {
    content: {
      title: string;
      courseCode: string;
      lessonsPerWeek: string;
      lessonsPerWeekText: string;
      lessonsPerWeekDescription: string;
      description: string;
    };
  };
};

export type CourseCategories = {
  courses: Array<CourseCategoryContent>;
};

type PatchCourseRequestValue = {
  sku: string;
  quantity: number;
  startDate: string;
  endDate: string;
};

type PatchOptionalItemRequestValue = {
  isOptional: boolean;
};

export type PatchRequestBody = {
  op: HttpPatchOperationType;
  path: string;
  value: PatchCourseRequestValue | PatchOptionalItemRequestValue;
};

export type CourseIntensityUpgradeResponse = {
  newPrice: Money;
  sku: string;
};

export type DurationUpgradesQuoteDraftResponse = {
  durationOptions: Array<DurationUpgradeOptions>;
};

export type DurationUpgradeOptions = {
  duration: Duration;
  startDateAt: string;
  endDateAt: string;
  totalPrice: Money;
  optionalPrice: Money;
  difference: UpgradeDifference;
};

export type UpgradeDifference = {
  pricePerWeek: Money;
  duration: Duration;
  totalPrice: Money;
  optionalPrice: Money;
};

export enum EFComProgramCodes {
  LT = "lt",
  ILS = "ils",
  AYA = "aya",
  EXC = "exc",
}

export enum ProgramCodes {
  ILC = "ILC",
  ILS = "ILS",
}

export enum WhatsIncludedCategories {
  Course = "course",
  Flights = "flights",
  Accommodation = "accommodation",
  Meals = "meals",
  Materials = "materials",
  Test = "test",
  Certificate = "certificate",
  CampusConnect = "campusconnect",
  PostCourse = "postcourse",
  Wifi = "wifi",
  Activities = "activities",
  Other = "other",
}

export enum HostFamilyCodes {
  FMW = "FMW",
  FTW = "FTW",
  FSW = "FSW",
}

export type CurrencyInfo = {
  currencyCode: string;
  currencySymbol: string;
  displayPosition: string;
};

export enum QuotePaymentStatus {
  Unreserved = "Unreserved",
  Reserved = "Reserve",
  Paid = "Paid",
}

export enum UpgradeCourseCode {
  Basic = "B",
  General = "G",
  Intensive = "I",
}

export type PaymentResult = {
  merchantReferenceId: string;
  paymentStatus: string;
  returnUrl: string;
  amount: number;
  currency: string;
};

export type OraclePaymentLibraryResult = {
  resultCode: string;
  sessionData: string;
  sessionResult: string;
};

export type OraclePaymentResult = {
  resultCode: string;
  sessionId: string;
  correlationId: string;
};

export type LanguageMapping = {
  [key: string]: string;
};

export type DestinationMapping = {
  [key: string]: string;
};

export type WhatsIncludedItemType = {
  _uid: string;
  category: string;
  component: string;
  title: string;
  description: string;
  icon: IconProps;
};

export class Payment {
  payment: Money;
  status: string;
  madeAt: string;

  constructor(amount: number, currency: string, status: string, madeAt: string) {
    this.payment = { amount, currency };
    this.status = status;
    this.madeAt = madeAt;
  }
}

export type ResponseWithFlowId = Response & { efFlowId: string };

export enum ErrorCode {
  NoError = "noError",
  NoQuotesLoaded = "noQuotesLoaded",
  IdentityPropertiesMisconfigured = "identityPropertiesMisconfigured",
  QuotesLoadingFailed = "quotesLoadingFailed",
  QuoteLoadingFailed = "quoteLoadingFailed",
  LoadedQuoteNotListed = "loadedQuoteNotListed",
  NoUpgradesLoaded = "noUpgradesLoaded",
  UpgradesLoadingFailed = "upgradesLoadingFailed",
  AuthenticationFailed = "authenticationFailed",
  NoTokenProvidedOrInvalidFormat = "noTokenProvidedOrInvalidFormat",
  QuoteUpgradeFailed = "quoteUpgradeFailed",
  QuoteSaveFailed = "quoteSaveFailed",
  QuoteDraftLoadingFailed = "quoteDraftLoadingFailed",
  ReservationPriceLoadingFailed = "reservationPriceLoadingFailed",
  DownloadingTermsFailed = "termsConditionsLoadingFailed",
  TermsForChosenProgramNotFound = "termsForSelectedProgramNotFound",
  TermsVersionForChosenProgramNotFound = "termsVersionForSelectedProgramNotFound",
  ReservationsPostFailed = "reservationsPostFailed",
  OracleSessionInitFailed = "oracleSessionInitFailed",
  PaymentsWithCorrelationFailedToFetch = "paymentsWithCorrelationFailedToFetch",
  CourseUpgradeFetchFailed = "courseUpgradeFetchFailed",
  SalesOfficesLoadFailed = "salesOfficesLoadFailed",
  AccountUpdateRequestFailed = "accountUpdateRequestFailed",
  RelatedContactUpdateRequestFailed = "relatedContactUpdateRequestFailed",
  TryingToPayAlreadyPaidQuote = "tryingToPayAlreadyPaidQuote",
  InitPaymentSessionFailed = "initPaymentSessionFailed",
  PaymentFailed = "paymentFailed",
  PaymentGatewayReturnedError = "paymentGatewayError",
  ReceivingPaymentsWasCancelled = "receivingPaymentsWasCancelled",
  ReceivingPaymentsError = "receivingPaymentsError",
  PrivacyPolicyForMarketNotFound = "privacyPolicyForMarketNotFound",
  MedicalDetailsUpdateFailed = "medicalDetailsUpdateFailed",
}

export type OptionalErrorDetails = {
  fallbackMessage?: string;
  fallbackTitle?: string;
  additionalDetails?: string;
};

/*
  Code must be provided - it's shown to the user and decides on the title and message.
  Provide customTitle/customMessage to skip using the deducted title and message from the error code itself.
  AdditionalDetails are shown to the user in the technical (less visible section).
  rawResponse - provide raw response from the server to allow this class to create a generic details message that can
  be shown together with the error.  
 */
class ErrorDetails {
  public code: ErrorCode;
  public details?: OptionalErrorDetails;
  public responseStatusCode?: number;
  public responseBody?: string;
  public efFlowId?: string;
  public url?: string;

  constructor({ code, details, responseStatusCode, responseBody, efFlowId, url }: Partial<ErrorDetails> = {}) {
    // Set additional properties specific to ErrorDetails
    this.code = code || ErrorCode.NoError;
    this.details = details;
    this.responseStatusCode = responseStatusCode;
    this.responseBody = responseBody;
    this.efFlowId = efFlowId;
    this.url = url;

    // Ensure that the prototype chain is correct
    Object.setPrototypeOf(this, ErrorDetails.prototype);
  }
}

// Expose the ErrorDetails class
export { ErrorDetails };
