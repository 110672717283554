const options = { timeZone: "UTC" };

export const formatDate = (date: string, languageCode: string) => {
  return new Intl.DateTimeFormat(languageCode, options).format(new Date(date));
};

export const formatDateRange = (startDate: string, endDate: string, languageCode: string): string => {
  return `${formatDate(startDate, languageCode)} - ${formatDate(endDate, languageCode)}`;
};

export const addDays = (date: Date, days: number): Date => {
  date.setDate(date.getDate() + days);
  return date;
};
