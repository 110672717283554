import ReactGA from "react-ga4";
import { AppConfig } from "../../AppConfig";
import { Money } from "../Types";
import { convertUrlToPageTitle } from "../utils";

export enum StandardTrackingEvent {
  Login = "login", // logs in
  SelectContent = "select_content", // selects content on your website or app
  Share = "share", // shares content from your website or app

  // GA standard events for online sales
  PageView = "page_view", // submits information about viewing new page
  AddPaymentInfo = "add_payment_info", // submits their payment information during checkout
  AddShippingInfo = "add_shipping_info", // submits their shipping information during checkout
  AddToCart = "add_to_cart", // adds items to their shopping cart -> used to record changes applied to the quote
  BeginCheckout = "begin_checkout", // begins checkout
  Purchase = "purchase", // completes a purchase
  RemoveFromCart = "remove_from_cart", // removes items from their shopping cart
  SelectItem = "select_item", // selects an item from a list of items or offerings
  SelectPromotion = "select_promotion", // selects a promotion
  ViewCart = "view_cart", // views their shopping cart
  ViewItem = "view_item", // views an item
  ViewItemList = "view_item_list", // views a list of items or offerings
  ViewPromotion = "view_promotion", // views a promotion on your website or app
}

export enum CustomTrackingEvent {
  Sort = "sort",
  Contact = "contact",
  DownloadPdf = "download_pdf", // download pdf clicked
  PayNow = "pay_now", // begin checkout
  SaveCompleteStudentDetailsPayment = "save_complete_student_details_payment",
  FullPaymentChosen = "full_payment_chosen",
  DownPaymentChosen = "down_payment_chosen",
  Reserved = "reserved",
  SaveStudentDetailsCheckout = "save_student_details_checkout",
  SaveParentDetailsCheckout = "save_parent_details_checkout",
  SaveMedicalDetailsCheckout = "save_medical_details_checkout",
  SaveNewQuote = "save_new_quote",
  TermsAndConditionsAccepted = "terms_accepted",
  PrivacyPolicyAccepted = "privacy_policy_accepted",
}

export type ProductItem = {
  item_id: string;
  item_name?: string;
  item_brand?: string;
  affiliation?: string;
  item_category?: string;
  item_category2?: string;
  item_category3?: string;
  item_category4?: string;
  item_category5?: string;
  item_list_id?: string;
  item_list_name?: string;
  price?: number;
  currency?: string;
  quantity?: number;
  item_variant?: string;
};

type ProductEvent = {
  item_list_id?: string;
  item_list_name?: string;
  items: Array<ProductItem>;
};

type PaymentEvent = {
  payment_type?: string;
  items: Array<ProductItem>;
};

type PurchaseEvent = {
  transaction_id: string;
  value: number;
  currency: string;
};

type SelectContentEvent = {
  content_type?: string;
  content_id?: string;
};

export enum ContentType {
  AccommodationDetails = "accommodation_details",
  DestinationDetails = "destination_details",
  QuoteDetails = "quote_details",
}

export enum ItemListNames {
  OptionalItems = "optional_items",
  CourseUpgrades = "course_upgrades",
  AccommodationUpgrades = "accommodation_upgrades",
  DurationUpgrades = "duration_upgrades",
}

export enum ItemBrand {
  EF = "EF",
}

type AddToCartEvent = {
  currency: string;
  value: number;
  items: Array<ProductItem>;
};

type SelectItemEvent = {
  item_list_id?: string;
  item_list_name?: string;
  items: Array<ProductItem>;
};

type IdentityIdDimension = {
  opportunity_id: string;
  account_id: string;
};

type TrackingEvent = StandardTrackingEvent | CustomTrackingEvent;
type TrackingEventParams =
  | ProductEvent
  | PaymentEvent
  | PurchaseEvent
  | SelectContentEvent
  | AddToCartEvent
  | SelectItemEvent;
type TrackingDimensionParams = IdentityIdDimension;

export const initializeAnalytics = (userId: string = "unknown") => {
  ReactGA.gtag("consent", "default", {
    analytics_storage: "denied",
  });
  ReactGA.gtag("consent", "default", {
    ad_storage: "denied",
  });
  ReactGA.gtag("config", AppConfig.analytics.googleMeasurementId, {
    send_page_view: false,
    cookie_flags: "SameSite=None;Secure",
    user_id: userId,
  });

  ReactGA.initialize(AppConfig.analytics.googleMeasurementId);
};

export const setTrackingDimensions = (customDimensions: TrackingDimensionParams) => {
  ReactGA.gtag("set", "user_properties", {
    ...customDimensions,
  });
};

export const trackPageView = (pageLocation: string) => {
  ReactGA.event(StandardTrackingEvent.PageView, {
    page_location: pageLocation,
    page_title: convertUrlToPageTitle(pageLocation),
  });
};

export const trackEvent = (eventName: TrackingEvent, eventParams?: TrackingEventParams) => {
  ReactGA.event(eventName, {
    ...eventParams,
  });
};

export const trackAddToCartEvent = (item: ProductItem) => {
  const total = (item?.price ?? 0) * (item?.quantity ?? 1);
  ReactGA.event(StandardTrackingEvent.AddToCart, {
    currency: item.currency,
    value: total,
    items: [item],
    upgrade_value: total,
    upgrade_article: item.item_id,
  });
};

export const trackRemoveFromCartEvent = (item: ProductItem) => {
  const total = (item?.price ?? 0) * (item?.quantity ?? 1);
  ReactGA.event(StandardTrackingEvent.RemoveFromCart, {
    currency: item.currency,
    value: total,
    items: [item],
    upgrade_value: -total,
    upgrade_article: item.item_id,
  });
};

export const trackPurchaseEvent = (
  paymentReference: string,
  opportunityUuid: string,
  items: ProductItem[],
  price: Money
) => {
  ReactGA.event(StandardTrackingEvent.Purchase, {
    transaction_id: paymentReference,
    value: price.amount,
    currency: price.currency,
    items: items,
    opportunity_id: opportunityUuid,
  });
};
