import React, { PropsWithChildren } from "react";
import { StoryblokComponent } from "@storyblok/react";
import { useDatasources, useStoryblokSlug } from "../../../contexts/StoryblokContext/StoryblokContext";

interface BasePageProps extends PropsWithChildren {
  withHeader?: boolean;
  withFooter?: boolean;
}

const BasePage: React.FC<BasePageProps> = ({ children, withHeader = true, withFooter = true }) => {
  const { activeLanguage } = useDatasources();
  const headerStory = withHeader && useStoryblokSlug("header", activeLanguage);
  const footerStory = withFooter && useStoryblokSlug("footer", activeLanguage);
  return (
    <>
      {headerStory && headerStory.content && (
        <StoryblokComponent blok={headerStory.content} key={headerStory.content._uid} />
      )}
      {children}
      {footerStory && footerStory.content && (
        <StoryblokComponent blok={footerStory.content} key={footerStory.content._uid} />
      )}
    </>
  );
};

export default BasePage;
