import styles from "./OpeningChevron.module.scss";
import { Icon } from "@ilc-technology/luik";
import React from "react";

interface OpeningChevronProps {
  open: boolean;
  setOpen: (value: boolean) => void;
}

const OpeningChevron: React.FC<OpeningChevronProps> = ({ open, setOpen }) => {
  return (
    <div className={styles.openingChevronContainer}>
      <button onClick={() => setOpen(!open)}>
        <Icon className={styles.openingChevronIcon} iconName={open ? "error" : "chevron-down"} size="sm"></Icon>
      </button>
    </div>
  );
};

export default OpeningChevron;
