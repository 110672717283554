import React, { useState } from "react";
import { Accordion, Button, Text } from "@ilc-technology/luik";
import { useDatasources } from "../../contexts/StoryblokContext/StoryblokContext";
import { LabelKey } from "../../Common/StoryblokTypes";
import UpgradeDurationModal from "./UpgradeDurationModal";
import { FormatCurrency } from "../../Common/Helpers/MoneyHelper";
import Enrich from "../../Common/services/TextEnricher";
import { QuoteDraft } from "../../contexts/QuoteDetailsContext";
import { Quote } from "../../Common/Types";

interface UpgradeDurationProps {
  isSaveInProgress: boolean;
  quote: Quote;
  quoteDraft: QuoteDraft;
}

const UpgradeDuration: React.FC<UpgradeDurationProps> = ({ isSaveInProgress, quote, quoteDraft }) => {
  const { labels } = useDatasources();
  const [isModalOpen, setModalOpen] = useState(false);
  const originalQuoteSegment = quote.segments[0];
  const quoteDraftSegment = quoteDraft.quoteDraft.quoteData.segments[0];
  const isDurationChanged =
    quoteDraftSegment &&
    originalQuoteSegment &&
    originalQuoteSegment?.duration.value != quoteDraftSegment.duration.value;
  const durationDifference = isDurationChanged
    ? quoteDraftSegment?.duration.value - originalQuoteSegment.duration.value
    : 0;
  const totalPriceDifference = isDurationChanged
    ? quoteDraftSegment.price.totalPrice.amount - originalQuoteSegment.price.totalPrice.amount
    : 0;
  const weeksLabel = durationDifference === 1 ? labels[LabelKey.durationWeek] : labels[LabelKey.durationWeeks];

  const handleAdd = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <div className="flex flex-col" data-testid="upgrade-duration-component">
      <Accordion
        startsOpen
        intent="black"
        data={[
          {
            title: labels[LabelKey.addWeeks],
            children: (
              <>
                <div className="mb-8 flex">
                  <Text variant="paragraph-body">{labels[LabelKey.durationUpgradeDescription]}</Text>
                </div>
                {isDurationChanged ? (
                  <div className="shadow-md flex justify-between rounded-md bg-neutral-50 px-3 py-3">
                    <div className="flex flex-col">
                      <Text variant="label-md-bold"> + {Enrich(weeksLabel, { numWeeks: durationDifference })}</Text>
                      <Text variant="label-sm" className="color-gray-45">
                        <Text variant="paragraph-body">
                          + {FormatCurrency(totalPriceDifference, quoteDraftSegment.price.totalPrice.currency, 0)}
                        </Text>
                      </Text>
                    </div>
                    <div className="flex gap-4">
                      <Button
                        data-testid="change-duration-button"
                        size="base"
                        intent="secondary-black"
                        iconName="arrow-right"
                        iconPosition="end"
                        onPress={handleAdd}
                        isDisabled={isSaveInProgress}
                      >
                        {labels[LabelKey.change]}
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div className="mx-6 mb-4 flex justify-end">
                    <div>
                      <Button
                        data-testid="add-duration-button"
                        size="base"
                        intent="primary-black"
                        iconName="arrow-right"
                        iconPosition="end"
                        onPress={handleAdd}
                        isDisabled={isSaveInProgress}
                      >
                        {labels[LabelKey.addButton]}
                      </Button>
                    </div>
                  </div>
                )}
              </>
            ),
          },
        ]}
      />
      {isModalOpen && (
        <UpgradeDurationModal isModalOpen={isModalOpen} editableQuoteDraft={quoteDraft} handleModalClose={handleModalClose} />
      )}
    </div>
  );
};

export default UpgradeDuration;
