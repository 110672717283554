import React, { Key, useState } from "react";
import styles from "./StudentDetails.module.scss";
import { Button, Item, Loading, Select, Text, TextInput } from "@ilc-technology/luik";
import { IconButton } from "@ilc-technology/luik";
import Surface from "../../Surface/Surface";
import { useDatasources } from "../../../contexts/StoryblokContext/StoryblokContext";
import { LabelKey } from "../../../Common/StoryblokTypes";
import { User, useSessionContext } from "../../../contexts/SessionContext/SessionContext";
import { convertToAccountUpdateRequest } from "../../../Common/Helpers/AccountHelper";
import InformationBox from "../../InformationBox/InformationBox";
import { CustomTrackingEvent, trackEvent } from "../../../Common/services/Analytics";
import { getErrorDetailsToDisplay, logError } from "../../../Common/services/ErrorService";
import { ErrorDetails } from "../../../Common/Types";
import { TRUE } from "../../../Common/Constants";
import { getCountryName } from "../../../Common/services/Countries";

interface StudentDetailsProps {}

const StudentDetails: React.FC<StudentDetailsProps> = () => {
  const { labels, countries, featureSettings } = useDatasources();
  const { session, language, updateAccount } = useSessionContext();
  const { user } = session;

  const [isFormLoading, setIsFormLoading] = useState(false);
  const [accountUpdateError, setAccountUpdateError] = useState<ErrorDetails | undefined>(undefined);
  const [isStudentDataEditable, setIsStudentDataEditable] = useState(false);
  const [formData, setFormData] = useState({
    firstName: user.firstName,
    middleName: user.middleName,
    lastName: user.lastName,
    email: user.email,
    country: user.country,
    mobilePhone: user.mobilePhone,
  });

  const getUsersFullDisplayName = (firstName: string, middleName: string, lastName: string) => {
    const nameParts = [firstName, featureSettings.isMiddleNameEnabled === TRUE ? middleName : "", lastName].filter(
      Boolean
    );
    return nameParts.join(" ");
  };

  const handleSelectionChange = (code: Key) => {
    setFormData({
      ...formData,
      ["country"]: code.toString(),
    });
  };

  const handleChange = (e: { target: { name: string; value: string } }) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();

    trackEvent(CustomTrackingEvent.SaveCompleteStudentDetailsPayment);
    setIsFormLoading(true);
    setAccountUpdateError(undefined);

    const request = convertToAccountUpdateRequest(formData as User);
    const result = await updateAccount(request);

    if (result.isSuccessful) {
      setIsFormLoading(false);
      setIsStudentDataEditable(false);
    } else {
      logError(result.error);
      setIsFormLoading(false);
      setIsStudentDataEditable(true);
      setAccountUpdateError(result.error);
    }
  };

  return (
    <Surface>
      <div className={styles.detailsContainer}>
        <div className={styles.detailsContainerHeader}>
          <div className={styles.studentDetailsTitle}>
            <Text variant="heading-5-bold">{labels[LabelKey.studentDetails]}</Text>
            <IconButton
              data-testid="edit-student-details"
              isDisabled={isStudentDataEditable}
              iconName="crayon"
              intent="primary-black"
              size="xs"
              aria-label={undefined}
              onPress={() => {
                setIsStudentDataEditable(!isStudentDataEditable);
              }}
            />
          </div>
          <div>
            <Text variant="paragraph-body">{labels[LabelKey.studentDetailsDescription]}</Text>
            {accountUpdateError && (
              <InformationBox
                intent="alert"
                title={labels[LabelKey.saveFailed]}
                content={labels[LabelKey.saveFailedDescription]}
                iconName={"alert-circle-outlined"}
                additionalInfo={getErrorDetailsToDisplay(accountUpdateError)}
              />
            )}
          </div>
        </div>
        {!isStudentDataEditable && countries && (
          <div className={styles.studentDetails}>
            <Text data-testid="person" variant="paragraph-body">
              {getUsersFullDisplayName(formData.firstName, formData.middleName, formData.lastName)}
            </Text>
            <Text data-testid="person-mobilePhone" variant="paragraph-body">
              {formData.mobilePhone}
            </Text>
            <Text data-testid="person-email" variant="paragraph-body">
              {formData.email}
            </Text>
            <Text data-testid="person-country" variant="paragraph-body">
              {countries[formData.country]}
            </Text>
          </div>
        )}
        {isStudentDataEditable && (
          <div>
            <form onSubmit={handleSubmit}>
              <div className={styles.studentDetailsForm}>
                <TextInput
                  data-testid="firstName"
                  isRequired={true}
                  name="firstName"
                  label={labels[LabelKey.firstName]}
                  placeholder={labels[LabelKey.firstName]}
                  type="text"
                  value={formData.firstName ?? ""}
                  onChange={handleChange}
                  isDisabled={isFormLoading}
                />
                {featureSettings.isMiddleNameEnabled === TRUE && (
                  <TextInput
                    data-testid="middleName"
                    name="middleName"
                    label={labels[LabelKey.middleName]}
                    placeholder={labels[LabelKey.middleName]}
                    type="text"
                    value={formData.middleName ?? ""}
                    onChange={handleChange}
                    isDisabled={isFormLoading}
                  />
                )}
                <TextInput
                  data-testid="lastName"
                  isRequired={true}
                  name="lastName"
                  label={labels[LabelKey.lastName]}
                  placeholder={labels[LabelKey.lastName]}
                  type="text"
                  value={formData.lastName ?? ""}
                  onChange={handleChange}
                  isDisabled={isFormLoading}
                />
                <Select
                  data-testid="country"
                  errorMessage="Input is required"
                  label={labels[LabelKey.country]}
                  className={styles.countrySelect}
                  defaultSelectedKey={formData.country}
                  trackingInfo="track-select"
                  isRequired={true}
                  onSelectionChange={handleSelectionChange}
                  disabled={isFormLoading}
                >
                  {Object.entries(countries).map(([countryCode, countryName]) => (
                    <Item key={countryCode} value={countryCode}>
                      {getCountryName(countryCode, language, countryName)}
                    </Item>
                  ))}
                </Select>
              </div>
              <div className={styles.buttonsContainer}>
                <Button
                  data-testid="save-button"
                  intent="secondary-black"
                  size="base"
                  onPress={() => {
                    setFormData(user);
                    setIsStudentDataEditable(!isStudentDataEditable);
                    setAccountUpdateError(undefined);
                  }}
                  isDisabled={isFormLoading}
                >
                  {labels[LabelKey.cancel]}
                </Button>
                {isFormLoading ? (
                  <div className="save-details-button save-details-loading">
                    <Loading className="loading save-details-loading-spinner" size="lg" />
                  </div>
                ) : (
                  <input
                    data-testid="save-details-button"
                    className="save-details-button"
                    type="submit"
                    disabled={isFormLoading}
                    value={labels[LabelKey.save]}
                  />
                )}
              </div>
            </form>
          </div>
        )}
      </div>
    </Surface>
  );
};

export default StudentDetails;
