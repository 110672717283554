import accounting from "accounting";
import { CURRENCY_MAPPING } from "../Constants";
import { Money } from "../Types";

/**
 * Formats a numeric amount with x decimal places together with a given currency,
 * eg. (1020, 2, USD) => $ 1,020.00. If currency isn't passed, default is $.
 * @param {number} amount
 * @param {number} precision
 * @param currency
 * @returns {string}
 */
export const FormatCurrency = (amount: number, currency: string, precision: number = 2) => {
  const currencyInfo = CURRENCY_MAPPING.find((c) => c.currencyCode == currency?.toUpperCase());
  const currencyFormatting = currencyInfo?.displayPosition == "after" ? "%v %s" : "%s %v";
  return accounting.formatMoney(amount, currencyInfo?.currencySymbol, precision, ",", ".", currencyFormatting);
};

export const FormattedMoney = (money: Money, precision: number = 2) => {
  return FormatCurrency(money.amount, money.currency, precision);
};
