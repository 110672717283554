import React, { ReactNode } from "react";

interface SurfaceProps {
  children?: ReactNode;
  borderRadius?: "all" | "top" | "bottom";
  className?: string;
}

const Surface: React.FC<SurfaceProps> = ({ children, className = "", borderRadius = "all" }) => {
  const radius = {
    all: "rounded-3xl md:rounded-lg",
    top: "rounded-t-3xl md:rounded-t-lg",
    bottom: "rounded-b-3xl md:rounded-b-lg",
  };

  return <div className={`${radius[borderRadius]} bg-white p-6 shadow md:p-8 ${className}`}>{children}</div>;
};

export default Surface;
