import React from "react";
import styles from "./BannerImage.module.scss";
import { pathJoin } from "../../Common/utils";

interface BannerImageProps {
  url: string;
  focusPoint?: string;
  size?: "base" | "lg";
  hasOverlay?: boolean;
}

const BannerImage: React.FC<BannerImageProps> = ({ url, focusPoint, size = "base", hasOverlay = false }) => {
  const bannerImageSize = {
    base: styles.base,
    lg: styles.lg,
  };

  if (!url) {
    return <div className={`bg-gray-300 w-full ${bannerImageSize[size]}`}></div>;
  }

  const tryGetLongEdge = (url: string): string => {
    const match = url.match(/(\d+)x(\d+)/);
    const width = match ? parseInt(match[1]) : 0;
    const height = match ? parseInt(match[2]) : 0;

    return (width < height ? width : height).toString();
  };

  const edgeSize = tryGetLongEdge(url);

  // Resize the image and maintain 1:1 aspect ratio
  const squareSize = edgeSize ? `${edgeSize}x${edgeSize}` : "";

  let portraitUrl = squareSize ? pathJoin([url, "/m/", squareSize]) : url;

  // if the image has a focus point then append the focus point filters to the image
  if (focusPoint) {
    portraitUrl = pathJoin([portraitUrl, `filters:focal(${focusPoint})`]);
  }

  return (
    <div className={`relative z-[-1] block w-full ${hasOverlay && styles.imageOverlay}`}>
      <picture>
        <source media="(orientation: portrait)" srcSet={portraitUrl} />
        <img className={`w-full object-cover object-center ${bannerImageSize[size]}`} src={url} />
      </picture>
    </div>
  );
};

export default BannerImage;
