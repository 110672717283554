import styles from "./TopBar.module.scss";
import { Icon, Text } from "@ilc-technology/luik";
import React from "react";
import { Link } from "react-router-dom";
import { Telephone } from "../../contexts/SessionContext/SessionContext";

interface Logo {
  filename: string;
}

interface TopBarProps {
  logo: Logo;
  telephone?: Telephone;
}

const TopBar: React.FC<TopBarProps> = ({ logo, telephone }) => {
  //In central api general number is the one that's to show on screen: 06 1 327 8050
  const generalNumber = telephone?.generalNumber;

  // formatted number is the one that can be called and looks like this: 0613278050
  const formattedNumber = telephone?.generalNumberFormattedForMobile;

  return (
    <>
      <div className={styles.topBar} data-testid="top-bar">
        <Link to="/">
          <img src={logo.filename} className={styles.topBarLogo} alt="Centered" data-testid="top-bar-logo" />
        </Link>
        {formattedNumber && (
          <a className={styles.topBarPhone} href={`tel:${formattedNumber}`}>
            <Text variant="label-md" className={styles.topBarPhoneNumber} data-testid="top-bar-phone">
              {generalNumber ?? formattedNumber}
            </Text>
            <Icon className={styles.topBarPhoneIcon} size="sm" iconName="phone" />
          </a>
        )}
      </div>
    </>
  );
};

export default TopBar;
