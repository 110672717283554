import React, { useEffect } from "react";
import { StoryblokComponent } from "@storyblok/react";
import { useSessionContext } from "../../../contexts/SessionContext/SessionContext";
import SalesUserInfo from "../../SalesUserInfo/SalesUserInfo";
import { SALESFORCE_INTERGRATION_USER_MAIL } from "../../../Common/Constants";
import { useDatasources, useStoryblokSlug } from "../../../contexts/StoryblokContext/StoryblokContext";
import ErrorComponentWrapper from "../../ErrorHandling/ErrorComponentWrapper";
import QuoteListBox from "../../QuoteList/QuoteListBox/QuoteListBox";
import { useQuoteContext } from "../../../contexts/QuoteContext/QuoteContext";

const HomePage: React.FC = () => {
  const { activeLanguage } = useDatasources();
  const { session } = useSessionContext();
  const { salesUser } = session;
  const { quotes, isQuoteListLoading, quotesContentCache, setupError } = useQuoteContext();

  const bannerStory = useStoryblokSlug("home-banner", activeLanguage);
  const bannerCardStory = useStoryblokSlug("home-banner-card", activeLanguage);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {bannerStory?.content && <StoryblokComponent blok={bannerStory.content} key={bannerStory.content._uid} />}
      {bannerCardStory?.content && (
        <StoryblokComponent blok={bannerCardStory.content} key={bannerCardStory.content._uid} />
      )}
      <div className="sections-separator" />
      <div id="quoteList" className="section">
        <ErrorComponentWrapper isLoading={isQuoteListLoading} error={setupError}>
          <QuoteListBox quotes={quotes} quotesContent={quotesContentCache} />
        </ErrorComponentWrapper>
      </div>
      <div className="sections-separator" />
      {salesUser?.firstName && salesUser?.lastName && salesUser.email !== SALESFORCE_INTERGRATION_USER_MAIL && (
        <SalesUserInfo
          title={salesUser.title}
          firstName={salesUser.firstName}
          lastName={salesUser.lastName}
          description={salesUser.aboutMe}
          phone={salesUser.phone}
          email={salesUser.email}
          imageUrl={salesUser.photoUrl}
        />
      )}
    </>
  );
};

export default HomePage;
