import React, { useEffect } from "react";

interface ScriptProps {
  src: string;
  type?: string;
  integrity?: string;
  crossOrigin?: string;
  appendTo?: string;
  data?: object;
  retries?: number;
  retryTimeout?: number;
  runCode?: () => void;
  successHandler?: () => void;
  errorHandler?: () => void;
}

const Script: React.FC<ScriptProps> = ({
  src,
  type,
  integrity,
  crossOrigin,
  appendTo = "head",
  data,
  retries = 3,
  retryTimeout = 2000,
  runCode,
  successHandler,
  errorHandler,
}) => {
  const scriptId = encodeURIComponent(src);
  const isScriptLoaded = !!document.getElementById(scriptId);

  const loadScript = (currentRetry: number) => {
    const updatedRetryCount = currentRetry + 1;
    const updatedRetryTimeout = retryTimeout + currentRetry * 1000;
    const scriptElement: HTMLScriptElement = document.createElement("script");

    scriptElement.setAttribute("id", scriptId);
    scriptElement.setAttribute("src", src);
    type && scriptElement.setAttribute("crossOrigin", type);
    crossOrigin && scriptElement.setAttribute("crossOrigin", crossOrigin);
    integrity && scriptElement.setAttribute("crossOrigin", integrity);
    data &&
      Object.entries(data).forEach(([key, value]) => {
        scriptElement.setAttribute(`data-${key}`, value);
      });
    scriptElement.onload = () => {
      successHandler && successHandler();
      runCode && runCode();
    };
    scriptElement.onerror = () => {
      if (currentRetry < retries) {
        setTimeout(() => loadScript(updatedRetryCount), updatedRetryTimeout);
      } else {
        errorHandler && errorHandler();
      }
    };

    document.getElementById(scriptElement.id)?.remove();
    document.getElementsByTagName(appendTo)[0].appendChild(scriptElement);
  };

  useEffect(() => {
    !isScriptLoaded && loadScript(0);
  }, []);

  return <></>;
};

export default Script;
