import React, { useMemo } from "react";
import { Text } from "@ilc-technology/luik";
import { useDatasources } from "../../../contexts/StoryblokContext/StoryblokContext";
import Enrich from "../../../Common/services/TextEnricher";
import { formatDateRange } from "../../../Common/Helpers/DateHelper";
import { LabelKey } from "../../../Common/StoryblokTypes";
import { FormatCurrency, FormattedMoney } from "../../../Common/Helpers/MoneyHelper";
import ExpandableText from "../../ExpandableText/ExpandableText";
import { getWeekLabel } from "../../../Common/getWeekLabel";
import { useSessionContext } from "../../../contexts/SessionContext/SessionContext";
import { useQuoteContext } from "../../../contexts/QuoteContext/QuoteContext";
import { getQuoteInformation } from "../../../Common/getQuoteInformation";
import { PaymentDetails } from "../../../Common/Helpers/PaymentHelper";
import { Money, PaymentStatus, Quote } from "../../../Common/Types";
import Surface from "../../Surface/Surface";
import { Link } from "react-router-dom";

interface PaymentSummaryCardProps {
  quote: Quote;
  reservationPrice?: Money;
  paymentDetails: PaymentDetails;
  totalAmount: number;
  paid: Money | null;
}

const PaymentSummaryCard: React.FC<PaymentSummaryCardProps> = ({
  quote,
  reservationPrice,
  paymentDetails,
  totalAmount,
  paid,
}) => {
  const { language } = useSessionContext();
  const { labels } = useDatasources();
  const { quotesContentCache, isQuotesDestinationsLoading } = useQuoteContext();
  const { courseSegments, startAt, endAt, totalDuration } = getQuoteInformation(quote);

  const weeksLabel = getWeekLabel(labels, totalDuration);

  const reservationPriceAmount = reservationPrice?.amount;
  const summaryLineAmount =
    paymentDetails.status === PaymentStatus.ReservationSuccess && reservationPrice
      ? FormattedMoney(reservationPrice)
      : paid
        ? FormattedMoney(paid)
        : "";

  const remainingAmount = useMemo(
    () => totalAmount - (paid?.amount ?? reservationPriceAmount ?? 0),
    [totalAmount, paid, reservationPriceAmount]
  );

  const destinationName = useMemo(
    () =>
      !isQuotesDestinationsLoading && courseSegments.length
        ? courseSegments
            .map((segment) => quotesContentCache?.destinations.get(segment.destinationCode)?.name)
            .join(" - ")
        : "",
    [isQuotesDestinationsLoading, courseSegments]
  );

  const quoteCurrency = useMemo(() => quote.prices.totalPrice.currency ?? "", [quote]);

  return (
    <div className="flex flex-col" data-testid="payment-summary-card">
      <Surface borderRadius="top">
        <div className="flex w-full flex-col gap-4">
          <div className="flex flex-col justify-center gap-2">
            <Text variant="special-eyebrow" data-testid="psc-summary-text">
              {labels[LabelKey.summary]}
            </Text>
            <Text variant="heading-5-bold" data-testid="psc-destination-text">
              {destinationName}
            </Text>
            <Text variant="paragraph-body" data-testid="psc-date-range-text">
              {`${formatDateRange(startAt, endAt, language)} | ${Enrich(weeksLabel, { numWeeks: totalDuration })}`}
            </Text>
          </div>

          <ExpandableText>
            <div className="flex flex-col gap-4 pb-3 pt-2">
              <div className="flex flex-row justify-between">
                <Text variant="label-lg" data-testid="psc-total-text">
                  {labels[LabelKey.total]}
                </Text>
                <Text variant="label-lg" data-testid="psc-total-value">
                  {FormatCurrency(totalAmount, quoteCurrency)}
                </Text>
              </div>
              {paid && (
                <div className="flex flex-row justify-between">
                  <Text variant="label-lg" data-testid="psc-remaining-text">
                    {labels[LabelKey.remaining]}
                  </Text>
                  <Text variant="label-lg" data-testid="psc-remaining-value">
                    {FormatCurrency(remainingAmount, paid.currency)}
                  </Text>
                </div>
              )}
            </div>
          </ExpandableText>
        </div>
      </Surface>

      <div className={`rounded-b-3xl shadow md:rounded-b-lg ${paymentDetails.color}`} data-testid="psc-summary-line">
        <div className="flex flex-row items-center justify-between gap-4 px-6 py-3 text-white md:px-8">
          <div>
            {paymentDetails.statusText && paymentDetails.status === PaymentStatus.ReservationSuccess && (
              <Link to={`/payment/${quote.id}`}>
                <Text variant="label-lg-bold" data-testid="psc-summary-line-status-text">
                  {paymentDetails.statusText}
                </Text>
              </Link>
            )}
            {paymentDetails.statusText && paymentDetails.status !== PaymentStatus.ReservationSuccess && (
              <Text variant="label-lg-bold" data-testid="psc-summary-line-status-text">
                {paymentDetails.statusText}
              </Text>
            )}
          </div>
          <div>
            {summaryLineAmount && (
              <Text variant="label-lg-bold" data-testid="psc-summary-line-status-value">
                {summaryLineAmount}
              </Text>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentSummaryCard;
