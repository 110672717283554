import React from "react";
import { StoryblokComponent } from "@storyblok/react";
import { storyblokEditable } from "../../../Common/services/StoryblokService";
import { ListBoxItemBlok } from "../ListBoxItem/ListBoxItem";
import { BlokBase } from "../../../Common/StoryblokTypes";

export interface ListBoxBlok extends BlokBase {
  listBoxItems: Array<ListBoxItemBlok>;
  verticalDirection: boolean;
}

interface ListBoxProps {
  blok: ListBoxBlok;
}

const ListBox: React.FC<ListBoxProps> = ({ blok }) => {
  return (
    <div {...storyblokEditable(blok)} key={blok._uid}>
      <div className={`${blok.verticalDirection ? "" : "max-w-[960px] mt-[-40px] mx-6 lg:mx-auto"}`}>
        <div
          className={`justify-between inline-flex flex-col ${blok.verticalDirection ? "gap-4" : "md:flex-row w-full p-8 bg-white rounded-3xl shadow gap-8"}`}
        >
          {blok.listBoxItems.map((blok: ListBoxItemBlok) => (
            <StoryblokComponent blok={blok} key={blok._uid} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ListBox;
