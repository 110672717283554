import React from "react";
import { StoryblokComponent } from "@storyblok/react";
import BannerImage from "../../BannerImage/BannerImage";
import { storyblokEditable } from "../../../Common/services/StoryblokService";
import { BlokBase, SbImage } from "../../../Common/StoryblokTypes";
import { TextBlok } from "../Text/Text";
import { TitleBlok } from "../Title/Title";
import { LinkBlok } from "../Link/Link";

type ContentBlok = TitleBlok | TextBlok | LinkBlok;

export interface BannerBlok extends BlokBase {
  backgroundImage: SbImage;
  content: Array<ContentBlok>;
}

interface BannerProps {
  blok: BannerBlok;
}

const Banner: React.FC<BannerProps> = ({ blok }) => {
  return (
    <div {...storyblokEditable(blok)} key={blok._uid} data-testid="banner">
      <div className="flex flex-col w-full h-[450px]">
        <div className="absolute w-full z-[-1]">
          <BannerImage url={blok.backgroundImage.filename} focusPoint={blok.backgroundImage.focus} hasOverlay />
        </div>
        <div className="w-full h-full flex-col justify-end items-center inline-flex">
          <div className="self-stretch pb-20 justify-center items-center gap-8 inline-flex">
            <div className="max-w-[960px] mx-6 lg:mx-auto grow shrink basis-0 flex-col justify-center items-start gap-8 inline-flex">
              <div className="self-stretch flex-col justify-start md:items-center gap-4 flex">
                <div className="self-stretch flex-col justify-start md:items-center gap-2 flex text-white">
                  {blok.content.map((blok: ContentBlok) => (
                    <StoryblokComponent blok={blok} key={blok._uid} />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
