import React, { createContext, ReactNode, useContext, useEffect, useState } from "react";
import {
  ApiRequestType,
  ErrorCode,
  ErrorDetails,
  Guid,
  Money,
  PatchRequestBody,
  Quote,
  QuotePaymentStatus,
} from "../Common/Types";
import { QuoteData, QuoteModificationResponse, useQuoteContext } from "./QuoteContext/QuoteContext";
import { fetchRetry } from "../Common/services/FetchRetry";
import { AppConfig } from "../AppConfig";
import { GenerateHeaderInformation } from "../Common/Helpers/ApiHelper";
import { useSessionContext } from "./SessionContext/SessionContext";
import { useImmer } from "use-immer";
import { convertToErrorDetails, returnJsonOrThrowError, logError } from "../Common/services/ErrorService";
import { processBundleItemsAndPaymentStatus } from "../Common/services/QuoteService";

export type ReservationPriceResponse = Money & {
  quoteId: Guid;
};

export type QuoteDraft = {
  quoteDraft: QuoteData;
  isQuoteDraftEdited: boolean;
};

interface QuotesDetailsContextValue {
  updateQuote: (x: Quote, body: PatchRequestBody[]) => Promise<QuoteModificationResponse>;
  saveQuote: (x: Quote) => Promise<QuoteModificationResponse>;
  quoteDraft?: QuoteDraft;
  loadingQuoteError?: ErrorDetails;
  loadingQuote: boolean;
  reservationPrice?: Money;
}

const initialValue: QuotesDetailsContextValue = {
  updateQuote: (_) => Promise.resolve(null as unknown as QuoteModificationResponse),
  saveQuote: (_) => Promise.resolve(null as unknown as QuoteModificationResponse),
  quoteDraft: undefined,
  loadingQuoteError: undefined,
  loadingQuote: true,
  reservationPrice: undefined,
};

const QuoteDetailsContext = createContext<QuotesDetailsContextValue>(initialValue);

interface QuoteContextProviderProps {
  children: ReactNode;
  fetchDraft: boolean;
}

function getErrorDetails(errorDetails: ErrorDetails): QuoteModificationResponse {
  return {
    isSuccessful: false,
    error: errorDetails,
  };
}

function getQuoteWithReservationPrice(rawQuote: Quote, resPrice: ReservationPriceResponse, isEdited: boolean) {
  const newDraft: QuoteData = {
    quoteData: {
      ...rawQuote,
      status: QuotePaymentStatus.Unreserved,
    },
    reservationPrice: resPrice,
  };
  return {
    quoteDraft: newDraft,
    isQuoteDraftEdited: isEdited,
  };
}

const QuoteDetailsContextProvider: React.FC<QuoteContextProviderProps> = ({ children, fetchDraft = true }) => {
  const { session } = useSessionContext();
  const { addedQuote, selected } = useQuoteContext();
  const [quoteDraft, updateQuoteDraft] = useImmer<QuoteDraft | undefined>(undefined);
  const [loadingQuoteDetails, setLoadingQuoteDetails] = useState<boolean>(true);
  const [loadingError, setLoadingError] = useState<ErrorDetails | undefined>(undefined);
  const [currentQuoteId, setCurrentQuoteId] = useState<Guid | undefined>(undefined);
  const [resPrice, setResPrice] = useState<ReservationPriceResponse | undefined>(undefined);

  useEffect(() => {
    // get reservation price for selected quote
    if (selected && !resPrice) {
      setCurrentQuoteId(selected.quoteData.id);
      setLoadingQuoteDetails(true);
      fetchRetry(`${AppConfig.api.efPlanner}/quote/reservation-price/${selected.quoteData.id}`, {
        method: ApiRequestType.GET,
        headers: GenerateHeaderInformation(session.accessToken),
      })
        .then((response) => returnJsonOrThrowError<ReservationPriceResponse>(response))
        .then((reservationPriceResponse) => {
          setResPrice(reservationPriceResponse);
          if (selected && reservationPriceResponse && !quoteDraft && fetchDraft) {
            fetchRetry(`${AppConfig.api.efPlanner}/quotes/${selected.quoteData.id}/draft`, {
              method: ApiRequestType.GET,
              headers: GenerateHeaderInformation(session.accessToken),
            })
              .then((response) => returnJsonOrThrowError<Quote>(response))
              .then((result) => {
                const processedDraft = processBundleItemsAndPaymentStatus(result);
                updateQuoteDraft(getQuoteWithReservationPrice(processedDraft, reservationPriceResponse, false));
              })
              .catch((error) => {
                const errorDetails = convertToErrorDetails(error, ErrorCode.QuoteDraftLoadingFailed, {
                  fallbackMessage: `Could not load quote.`,
                  additionalDetails: `Tracking id: ${session.opportunity.id}. Quote id: ${selected.quoteData.id}`,
                });
                logError(errorDetails);
                setLoadingError(errorDetails);
              })
              .finally(() => {
                setLoadingQuoteDetails(false);
              });
          }
        })
        .catch((error) => {
          const errorDetails = convertToErrorDetails(error, ErrorCode.ReservationPriceLoadingFailed, {
            fallbackMessage: `Could not load reservation price for quote ${selected?.quoteData.id}.`,
            additionalDetails: `Tracking id: ${session.opportunity.id}`,
          });
          logError(errorDetails);
          setLoadingError(errorDetails);
        })
        .finally(() => {
          if (!fetchDraft) {
            setLoadingQuoteDetails(false);
          }
        });
    }
    if (currentQuoteId && selected && selected.quoteData.id !== currentQuoteId) {
      setResPrice(undefined);
      updateQuoteDraft(undefined);
    }
  }, [selected?.quoteData.id, resPrice]);

  const upgrade = async (draft: Quote, body: PatchRequestBody[]): Promise<QuoteModificationResponse> => {
    if (!draft.id) {
      return getErrorDetails({
        code: ErrorCode.QuoteUpgradeFailed,
        details: {
          additionalDetails: `Cannot upgrade quote draft - draft id not provided.`,
        },
      });
    }

    return await fetchRetry(`${AppConfig.api.efPlanner}/quote-drafts/${draft.id}`, {
      method: ApiRequestType.PATCH,
      headers: GenerateHeaderInformation(session.accessToken),
      body: JSON.stringify(body),
    })
      .then((response) => returnJsonOrThrowError<Quote>(response))
      .then((result) => {
        updateQuoteDraft(getQuoteWithReservationPrice(result, resPrice!, true));
        return { quote: result as Quote, isSuccessful: true };
      })
      .catch((error) => {
        const errorDetails = convertToErrorDetails(error, ErrorCode.QuoteUpgradeFailed);
        return getErrorDetails(errorDetails);
      });
  };

  const save = async (draft: Quote): Promise<QuoteModificationResponse> => {
    if (!draft.id) {
      return getErrorDetails({
        code: ErrorCode.QuoteSaveFailed,
        details: {
          additionalDetails: `Cannot save quote draft - draft id not provided.`,
        },
      });
    }

    return await fetchRetry(`${AppConfig.api.efPlanner}/quotes/${draft.id}`, {
      method: ApiRequestType.POST,
      headers: GenerateHeaderInformation(session.accessToken),
    })
      .then((response) => returnJsonOrThrowError<Quote>(response))
      .then((result) => {
        if (!result?.id) {
          return getErrorDetails({
            code: ErrorCode.QuoteSaveFailed,
            details: {
              additionalDetails: `Saved quote was returned without an id.`,
            },
          });
        }
        result.createdAt = new Date().toISOString();
        addedQuote(result);
        return { quote: result as Quote, isSuccessful: true };
      })
      .catch((error) => {
        const errorDetails = convertToErrorDetails(error, ErrorCode.QuoteSaveFailed);
        return getErrorDetails(errorDetails);
      });
  };

  return (
    <QuoteDetailsContext.Provider
      value={{
        updateQuote: upgrade,
        saveQuote: save,
        quoteDraft: quoteDraft,
        loadingQuoteError: loadingError,
        loadingQuote: loadingQuoteDetails,
        reservationPrice: resPrice,
      }}
    >
      {children}
    </QuoteDetailsContext.Provider>
  );
};

const useQuoteDetailsContext = () => {
  return useContext(QuoteDetailsContext);
};

export { QuoteDetailsContextProvider, useQuoteDetailsContext };
