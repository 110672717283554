/**
 * Join path parts with a separator and removes any extra separators
 * @param parts Array of path parts
 * @param separator Separator to join the parts with (default: "/")
 * @returns Joined path
 */
export const pathJoin = (parts: Array<string>, separator: string = "/"): string => {
  if (parts.length === 0) return "";

  const protocolMatch = parts[0].match(/^(https?:\/\/)/i);
  const protocol = protocolMatch ? protocolMatch[0] : "";

  if (protocol) {
    parts[0] = parts[0].slice(protocol.length);
  }

  const path = parts
    .map((part) => part.replace(new RegExp(`^${separator}+|${separator}+$`, "g"), ""))
    .filter((part) => part)
    .join(separator);

  return protocol + path;
};

export function convertUrlToPageTitle(url: string = ""): string {
  const segments = url.split("/").filter((x) => x.length > 0);

  if (segments.length <= 1) {
    return "quotes";
  }
  return segments[0];
}

export function keepProperties(obj: any, propsToKeep: Array<string>): object {
  if (typeof obj !== "object" || !obj) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => keepProperties(item, propsToKeep));
  }

  const newObj: any = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const nestedProps = propsToKeep
        .filter((prop) => prop.startsWith(key + "."))
        .map((prop) => prop.substring(key.length + 1));
      if (propsToKeep.includes(key) || nestedProps.length > 0) {
        newObj[key] = keepProperties(obj[key], nestedProps);
      }
    }
  }
  return newObj;
}
