import { useEffect, useState } from "react";
import { Item, Select, Text, useNotifications } from "@ilc-technology/luik";
import UpgradeModal from "../Modal/UpgradeModal";
import { useDatasources } from "../../contexts/StoryblokContext/StoryblokContext";
import { LabelKey } from "../../Common/StoryblokTypes";
import Enrich from "../../Common/services/TextEnricher";
import { FormattedMoney } from "../../Common/Helpers/MoneyHelper";
import moment from "moment";
import {
  ApiRequestType,
  DurationUpgradeOptions,
  DurationUpgradesQuoteDraftResponse,
  ErrorCode,
  ErrorDetails,
  HttpPatchOperationType,
  PatchRequestBody,
  Quote,
  SalesItemType,
  SegmentType,
} from "../../Common/Types";
import { formatDateRange } from "../../Common/Helpers/DateHelper";
import { useSessionContext } from "../../contexts/SessionContext/SessionContext";
import { fetchRetry } from "../../Common/services/FetchRetry";
import { AppConfig } from "../../AppConfig";
import { GenerateHeaderInformation } from "../../Common/Helpers/ApiHelper";
import {
  ItemListNames,
  StandardTrackingEvent,
  trackAddToCartEvent,
  trackEvent,
  trackRemoveFromCartEvent,
} from "../../Common/services/Analytics";
import ErrorComponentWrapper from "../ErrorHandling/ErrorComponentWrapper";
import { QuoteDraft, useQuoteDetailsContext } from "../../contexts/QuoteDetailsContext";
import { convertToErrorDetails, returnJsonOrThrowError, logError } from "../../Common/services/ErrorService";
import { generateItemFromLineItem } from "../../Common/services/AnalyticsMappings";

type UpgradeDurationModalProps = {
  isModalOpen: boolean;
  editableQuoteDraft: QuoteDraft;
  handleModalClose: () => void;
};

function GetCourseLineItem(quoteDraft: Quote) {
  return quoteDraft.segments
    .filter((segment) => segment.type === SegmentType.Course)[0]
    .lineItems.filter((lineItem) => lineItem.type === SalesItemType.Course)[0];
}

const UpgradeDurationModal = ({ isModalOpen, editableQuoteDraft, handleModalClose }: UpgradeDurationModalProps) => {
  const { session, language } = useSessionContext();
  const { labels, featureSettings } = useDatasources();
  const { updateQuote } = useQuoteDetailsContext();
  const notifications = useNotifications();
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [loadingError, setLoadingError] = useState<ErrorDetails | undefined>(undefined);
  const courseLineItem = GetCourseLineItem(editableQuoteDraft.quoteDraft.quoteData);

  const [durationUpgradeOptions, setDurationUpgradeOptions] = useState<Array<DurationUpgradeOptions>>();
  const [upgradeDuration, setUpgradeDuration] = useState(0);

  const originalStartDate = moment(courseLineItem?.startAt);
  const originalEndDate = moment(courseLineItem?.endAt);
  const originalDuration = originalEndDate.diff(originalStartDate, "weeks") + 1;
  const durationOption =
    durationUpgradeOptions?.find((x) => x.duration.value === upgradeDuration + originalDuration) ?? undefined;

  const upgradeEndDate = originalEndDate.clone().add(upgradeDuration, "weeks");

  const originalWeeksLabel = originalDuration === 1 ? labels[LabelKey.durationWeek] : labels[LabelKey.durationWeeks];
  const upgradedWeeksLabel = upgradeDuration === 1 ? labels[LabelKey.durationWeek] : labels[LabelKey.durationWeeks];

  useEffect(() => {
    setIsLoading(true);
    fetchRetry(
      `${AppConfig.api.efPlanner}/quote-drafts/${editableQuoteDraft.quoteDraft.quoteData.id}/duration-upgrades?weekslist=${featureSettings.addWeeksOptions}`,
      {
        method: ApiRequestType.GET,
        headers: GenerateHeaderInformation(session.accessToken),
      }
    )
      .then((response) => returnJsonOrThrowError<DurationUpgradesQuoteDraftResponse>(response))
      .then((result) => {
        if (!result) return;
        if (result.durationOptions?.length > 0) {
          setDurationUpgradeOptions(result.durationOptions);
          setUpgradeDuration(result.durationOptions[0].difference.duration.value);
        } else {
          setLoadingError({
            code: ErrorCode.NoUpgradesLoaded,
            details: {
              additionalDetails: `got no duration upgrades for requested options: ${featureSettings.addWeeksOptions}`,
            },
          });
        }
      })
      .catch((error) => {
        const errorDetails = convertToErrorDetails(error, ErrorCode.UpgradesLoadingFailed, {
          additionalDetails: "Could not load possible upgrade options.",
        });
        setLoadingError(errorDetails);
        logError(errorDetails);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [editableQuoteDraft.quoteDraft.quoteData.id]);

  useEffect(() => {
    if (durationUpgradeOptions) {
      const items = durationUpgradeOptions.map((option) => {
        return generateItemFromLineItem(
          courseLineItem,
          editableQuoteDraft.quoteDraft.quoteData,
          ItemListNames.DurationUpgrades,
          undefined,
          option.difference.pricePerWeek,
          option.duration.value
        );
      });
      trackEvent(StandardTrackingEvent.ViewItemList, {
        items: items,
      });
    }
  }, [durationUpgradeOptions]);

  const upgradeCourseDuration = async () => {
    if (courseLineItem) {
      setIsSaving(true);

      const body: PatchRequestBody[] = [
        {
          op: HttpPatchOperationType.REPLACE,
          path: `/segments/0/lineItems/${courseLineItem.id}`,
          value: {
            sku: courseLineItem.sku,
            quantity: upgradeDuration + originalDuration,
            startDate: courseLineItem.startAt,
            endDate: upgradeEndDate.format("YYYY-MM-DD"),
          },
        },
      ];
      const result = await updateQuote(editableQuoteDraft.quoteDraft.quoteData, body);
      if (result.isSuccessful) {
        if (result.quote) {
          trackRemoveFromCartEvent(
            generateItemFromLineItem(
              courseLineItem,
              editableQuoteDraft.quoteDraft.quoteData,
              ItemListNames.DurationUpgrades
            )
          );
          trackAddToCartEvent(
            generateItemFromLineItem(
              GetCourseLineItem(result.quote),
              editableQuoteDraft.quoteDraft.quoteData,
              ItemListNames.DurationUpgrades,
            )
          );
        }
        setIsSaving(false);
        handleModalClose();
      } else {
        logError(result.error);
        handleModalClose();
        notifications.addErrorNotification({
          title: labels[LabelKey.error],
          description: labels[LabelKey.upgradeFailed],
        });
      }
    }
  };

  return (
    <UpgradeModal
      isOpen={isModalOpen}
      isSaving={isSaving}
      isSavingDisabled={isLoading || isSaving || loadingError !== undefined}
      closeHandler={handleModalClose}
      header={labels[LabelKey.addWeeks]}
      cancelHandler={handleModalClose}
      upgradeHandler={upgradeCourseDuration}
    >
      <section className="px-6 md:px-10">
        <div className="cards-separator" />
        <Text as="h4" variant="heading-5-bold">
          {labels[LabelKey.whyExtendYourCourse_Title]}
        </Text>
        <Text variant="paragraph-body" className="mt-2">
          {labels[LabelKey.whyExtendYourCourse_Description]}
        </Text>
        <div className="cards-separator" />
        <Text variant="paragraph-body">{labels[LabelKey.originalDuration]}</Text>
        <Text variant="paragraph-body-bold">
          {`${formatDateRange(
            originalStartDate.format("YYYY-MM-DD"),
            originalEndDate.format("YYYY-MM-DD"),
            language
          )} | ${Enrich(originalWeeksLabel, { numWeeks: originalDuration })}`}
        </Text>
      </section>
      <ErrorComponentWrapper isLoading={isLoading} error={loadingError}>
        {durationUpgradeOptions && (
          <div className="mx-0 mt-6 rounded-3xl bg-neutral-50 p-6 md:mx-10 md:p-10" data-testid="upgrade-chooser">
            <label className="text-label-lg">{labels[LabelKey.howManyWeeksToAdd]}</label>
            <Select
              label={labels[LabelKey.noOfWeeks]}
              name="week-select"
              trackingInfo="weeks-select"
              isInvalid={false}
              defaultSelectedKey={upgradeDuration.toString()}
              onSelectionChange={(value) => {
                const weeksCount = parseInt(value.toString(), 10);
                setUpgradeDuration(weeksCount);
                trackEvent(StandardTrackingEvent.SelectItem, {
                  items: [
                    generateItemFromLineItem(courseLineItem, editableQuoteDraft.quoteDraft.quoteData, ItemListNames.DurationUpgrades, undefined, courseLineItem.price, weeksCount),
                  ],
                });
              }}
            >
              {durationUpgradeOptions.map((option) => {
                const durationValue = option.difference.duration.value;
                return (
                  <Item key={durationValue} value={durationValue.toString()}>
                    {durationValue}
                  </Item>
                );
              })}
            </Select>
            <div className="cards-separator" />
            <Text variant="paragraph-body-bold" className="mt-8">
              {`${formatDateRange(originalStartDate.format("YYYY-MM-DD"), upgradeEndDate.format("YYYY-MM-DD"), language)} | ${Enrich(upgradedWeeksLabel, { numWeeks: upgradeDuration + originalDuration })}`}
            </Text>
            {durationOption && (
              <div className="mt-8 flex flex-col gap-4">
                <div className="flex justify-between">
                  <Text variant="paragraph-body">{labels[LabelKey.upgradeCost]}</Text>
                  <Text variant="paragraph-body-bold">
                    {`+ ${FormattedMoney(durationOption.difference?.totalPrice, 0)}`}
                  </Text>
                </div>
                <div className="flex justify-between">
                  <Text variant="paragraph-body">{labels[LabelKey.totalAfter]}</Text>
                  <Text variant="paragraph-body">{FormattedMoney(durationOption.totalPrice)}</Text>
                </div>
              </div>
            )}
          </div>
        )}
      </ErrorComponentWrapper>
    </UpgradeModal>
  );
};

export default UpgradeDurationModal;
