import React, { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { useImmer } from "use-immer";
import {
  ApiRequestType,
  ErrorCode,
  ErrorDetails,
  Money,
  PaymentInfo,
  PaymentInfoStatus,
  Quote,
  QuotePaymentStatus,
  SegmentType,
} from "../../Common/Types";
import { AppConfig } from "../../AppConfig";
import { useSessionContext } from "../SessionContext/SessionContext";
import { useDatasources } from "../StoryblokContext/StoryblokContext";
import { GenerateHeaderInformation } from "../../Common/Helpers/ApiHelper";
import { AtlasFallbackDestination } from "../../Common/Helpers/LineItemHelper";
import { fetchRetry } from "../../Common/services/FetchRetry";
import { useParams } from "react-router-dom";
import { convertToErrorDetails, returnJsonOrThrowError, logError } from "../../Common/services/ErrorService";
import { processBundleItemsAndPaymentStatus } from "../../Common/services/QuoteService";

export type QuoteData = {
  quoteData: Quote;
  reservationPrice?: Money;
};

export type QuoteModificationResponse = {
  quote?: Quote;
  isSuccessful: boolean;
  error?: ErrorDetails;
};

export type DestinationContent = {
  name: string;
  heroImage: string;
  schoolImage: string;
  accommodationCodes: string[];
};

export type QuotesContent = {
  destinations: Map<string, DestinationContent>;
};

interface QuotesContextValue {
  quotes: Array<QuoteData>;
  isQuoteListLoading: boolean;
  quotesContentCache: QuotesContent;
  isQuotesDestinationsLoading: boolean;
  selected?: QuoteData;
  setSelectedQuoteById: (x: string) => void;
  addedQuote: (x: Quote) => void;
  replaceQuote: (x: QuoteData) => boolean;
  addPaymentToQuote: (x: Quote, payment: PaymentInfo) => boolean;
  selectedQuoteData?: QuoteData;
  activeLanguage?: string;
  setupError?: ErrorDetails;
}

const initialValue: QuotesContextValue = {
  quotes: [],
  isQuoteListLoading: true,
  quotesContentCache: {
    destinations: new Map(),
  },
  isQuotesDestinationsLoading: true,
  setSelectedQuoteById: () => {},
  addedQuote: () => {},
  replaceQuote: () => true,
  addPaymentToQuote: () => true,
};

const QuoteContext = createContext<QuotesContextValue>(initialValue);

interface QuoteContextProviderProps {
  children: ReactNode;
}

const QuoteContextProvider: React.FC<QuoteContextProviderProps> = ({ children }) => {
  const { quoteId } = useParams();
  const { session } = useSessionContext();
  const { activeLanguage, atlasfallbackDestinationMappings } = useDatasources();
  const [quotes, setQuotes] = useState<Array<QuoteData>>([] as Array<QuoteData>);
  const [destinationsContent, setDestinationsContent] = useState<Map<string, DestinationContent>>(new Map());
  const [selected, updateSelected] = useImmer<QuoteData | undefined>(undefined);
  const [isQuoteListLoading, setIsQuoteListLoading] = useState(true);
  const [isQuotesDestinationsLoading, setIsQuotesDestinationsLoading] = useState(true);
  const [setupError, setSetupError] = useState<ErrorDetails | undefined>(undefined);

  const setSelectedQuoteById = (id?: string) => {
    if (!quotes || quotes.length === 0) {
      return;
    }
    if (!id) {
      updateSelected(undefined);
      return;
    }
    const matchedQuote = quotes.find((q) => q.quoteData.id === id);
    updateSelected(matchedQuote || undefined);
    setSetupError(
      !matchedQuote
        ? {
            details: {
              fallbackMessage: "Requested quote could not be found on the list of customer's quotes.",
              additionalDetails: `Quote id: ${id} (was it published?)`,
            },
            code: ErrorCode.LoadedQuoteNotListed,
          }
        : undefined
    );
  };

  const quotesContent = {
    destinations: destinationsContent,
  };

  useEffect(() => {
    if (quotes && quoteId) setSelectedQuoteById(quoteId);
  }, [quoteId, quotes]);

  useEffect(() => {
    // get quotes for opportunity
    if (session?.opportunity?.id) {
      setIsQuoteListLoading(true);
      fetchRetry(`${AppConfig.api.efPlanner}/quotes?opportunityId=${session.opportunity.id}`, {
        method: ApiRequestType.GET,
        headers: GenerateHeaderInformation(session.accessToken),
      })
        .then((response) => returnJsonOrThrowError<Array<Quote>>(response))
        .then((result) => {
          if (!result) return;
          //Remove all the bundled lineItems and add their price to the price of the course
          const items: Array<QuoteData> = result
            .filter((x: Quote) => x.isPublic)
            .map((q: Quote) => {
              return { quoteData: processBundleItemsAndPaymentStatus(q) };
            });
          if (items.length == 0) {
            setSetupError({
              code: ErrorCode.NoQuotesLoaded,
              details: {
                additionalDetails: `Tracking id: ${session.opportunity.id}`,
              },
            });
          }
          setQuotes(items);
        })
        .catch((error) => {
          const errorDetails = convertToErrorDetails(error, ErrorCode.QuotesLoadingFailed, {
            fallbackMessage: `Could not load quotes`,
            additionalDetails: `Tracking id: ${session.opportunity.id}`,
          });
          logError(errorDetails);
          setSetupError(errorDetails);
          setQuotes([] as Array<QuoteData>);
        })
        .finally(() => {
          setIsQuoteListLoading(false);
        });
    }
  }, [session?.opportunity?.id]);

  useEffect(() => {
    if (!quotes || quotes.length === 0 || destinationsContent.size > 0) {
      return;
    }
    // get unique destinations for opportunity once quotes are loaded
    const uniqueDestinationCodes = quotes
      .map((quote) =>
        quote.quoteData.segments
          .filter((segment) => segment.type === SegmentType.Course) // Filter segments
          .map((segment) => segment.destinationCode)
      )
      .flat()
      .filter((val, ix, arr) => arr.indexOf(val) === ix);

    if (session.accessToken && uniqueDestinationCodes.length > 0) {
      setIsQuotesDestinationsLoading(true);
      Promise.allSettled(
        uniqueDestinationCodes.map((destination) =>
          fetchRetry(
            `${AppConfig.api.efPlanner}/destinations?destinationCode=${AtlasFallbackDestination(destination, atlasfallbackDestinationMappings).toLowerCase()}&language=${activeLanguage?.atlas}`,
            {
              method: ApiRequestType.GET,
              headers: GenerateHeaderInformation(session.accessToken),
            }
          ).then((response) => {
            if (response.ok) {
              return response.json();
            }
            throw new Error(`Error retrieving atlas information about destination ${destination}.`);
          })
        )
      )
        .then((result) => {
          const destinationsContent = new Map<string, DestinationContent>();
          result.forEach((result, index) => {
            if (result.status === "fulfilled") {
              const destinationCode = uniqueDestinationCodes[index];
              destinationsContent.set(destinationCode, result.value);
            }
          });
          setDestinationsContent(destinationsContent);
          setIsQuotesDestinationsLoading(false);
        })
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .catch((_) => {
          setIsQuotesDestinationsLoading(false);
          // handle error, pending api response codes
        });
    }
  }, [quotes]);

  const addedQuote = (quote: Quote): void => {
    quote.isPublic = true;
    setQuotes([...quotes, { quoteData: { ...quote, status: QuotePaymentStatus.Unreserved } } as unknown as QuoteData]);
  };

  const addPaymentToQuote = (x: Quote, payment: PaymentInfo) => {
    let updated = false;
    const updatedQuotes = quotes.map((q) => {
      if (q.quoteData.id === x.id) {
        updated = true;
        let old = q.quoteData.payments || [];
        old.push(payment);
        q.quoteData.payments = old;
        q.quoteData.status =
          payment.status === PaymentInfoStatus.Paid ? QuotePaymentStatus.Paid : QuotePaymentStatus.Reserved;
        return q;
      }
      return q;
    });
    setQuotes(updatedQuotes);
    setSelectedQuoteById(x.id);
    return updated;
  };

  const replaceQuote = (quoteData: QuoteData): boolean => {
    let updated = false;
    const updatedQuotes = quotes.map((q) => {
      if (q.quoteData.id === quoteData.quoteData.id) {
        updated = true;
        return quoteData;
      }
      return q;
    });

    setQuotes(updatedQuotes);
    return updated;
  };

  return (
    <QuoteContext.Provider
      value={{
        quotes,
        isQuoteListLoading,
        quotesContentCache: quotesContent,
        isQuotesDestinationsLoading: isQuotesDestinationsLoading,
        selected,
        setSelectedQuoteById: setSelectedQuoteById,
        addedQuote: addedQuote,
        replaceQuote: replaceQuote,
        addPaymentToQuote: addPaymentToQuote,
        activeLanguage: activeLanguage?.efcom,
        setupError: setupError,
      }}
    >
      {children}
    </QuoteContext.Provider>
  );
};

const useQuoteContext = () => {
  return useContext(QuoteContext);
};

export { QuoteContextProvider, useQuoteContext };
