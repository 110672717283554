import React, { useCallback, useEffect } from "react";
import { useDatasources } from "../../../contexts/StoryblokContext/StoryblokContext";
import { useLocation, useNavigate } from "react-router-dom";
import StudentDetailsFullUpgrade from "../../StudentDetails/StudentDetailsFullUpgrade";
import { TRUE } from "../../../Common/Constants";
import { getQuoteInformation } from "../../../Common/getQuoteInformation";
import BannerCard from "./BannerCard";
import PaymentSummaryCard from "./PaymentSummaryCard";
import ConstructPaymentDetails from "../../../Common/Helpers/PaymentHelper";
import { Money, PaymentStatus, Quote } from "../../../Common/Types";
import WhatsNext from "./WhatsNext/WhatsNext";

interface PaymentSummaryProps {
  quote: Quote;
  reservationPrice?: Money;
}

const PaymentSummary: React.FC<PaymentSummaryProps> = ({ quote, reservationPrice }) => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { featureSettings } = useDatasources();
  const { quoteInfo } = getQuoteInformation(quote);

  const isStudentDetailsUpdateEnabled = featureSettings.isStudentDetailsUpdateEnabled === TRUE;

  const queryStrings = new URLSearchParams(search);
  const referenceId = queryStrings.get("referenceId");
  const paymentStatus = queryStrings.get("status") as PaymentStatus | null;
  const paymentAmount = queryStrings.get("amount") ? Number(queryStrings.get("amount")) : null;

  const timeoutInMinutes = paymentStatus === PaymentStatus.ReservationSuccess ? 60 * 24 : 60;

  const getPaymentDetails = useCallback((paymentStatus: PaymentStatus | null) => {
    return ConstructPaymentDetails(paymentStatus);
  }, []);

  const paymentDetails = getPaymentDetails(paymentStatus);
  const paymentCurrency = quoteInfo.prices.totalPrice.currency ?? "";

  const paid: Money | null =
    paymentAmount && paymentCurrency
      ? {
          amount: paymentAmount,
          currency: paymentCurrency,
        }
      : null;

  useEffect(() => {
    const handleTimeout = () => {
      navigate("/", { replace: true });
    };

    const timeoutId = setTimeout(handleTimeout, timeoutInMinutes * 60 * 1000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <>
      <BannerCard
        paymentDetails={paymentDetails}
        paid={paid}
        totalAmount={quoteInfo.prices.totalPrice.amount ?? 0}
        referenceId={referenceId}
        showStudentDetailsBtn={isStudentDetailsUpdateEnabled}
      />
      <div className="m-auto max-w-[960px] py-8" data-testid="paymentSummary-page">
        <div className="flex flex-col gap-8 lg:flex-row-reverse lg:justify-between">
          <div className="flex w-full flex-col gap-8">
            <PaymentSummaryCard
              quote={quoteInfo}
              reservationPrice={reservationPrice}
              paymentDetails={paymentDetails}
              totalAmount={quoteInfo.prices.totalPrice.amount}
              paid={paid}
            />
            {isStudentDetailsUpdateEnabled && <WhatsNext paymentStatus={paymentStatus} />}
          </div>
          {isStudentDetailsUpdateEnabled && (
            <div className="flex flex-col gap-4 lg:min-w-[600px] lg:max-w-[600px]">
              <StudentDetailsFullUpgrade />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default PaymentSummary;
