import { useNotifications } from "@ilc-technology/luik";
import { HttpPatchOperationType, LineItem, PatchRequestBody, Quote } from "../../Common/Types";
import { LabelKey } from "../../Common/StoryblokTypes";
import { useDatasources } from "../../contexts/StoryblokContext/StoryblokContext";
import { Divider, IconButton, Text } from "@ilc-technology/luik";
import { LineItemSeeDetails } from "../LineItemSeeDetails/LinteItemSeeDetails";
import { FormattedMoney } from "../../Common/Helpers/MoneyHelper";
import { ItemListNames, trackAddToCartEvent, trackRemoveFromCartEvent } from "../../Common/services/Analytics";
import { useQuoteDetailsContext } from "../../contexts/QuoteDetailsContext";
import { logError } from "../../Common/services/ErrorService";
import { getOptionalItems } from "../../Common/services/UpgradeService";
import { generateItemFromLineItem } from "../../Common/services/AnalyticsMappings";
import React from "react";

interface UpgradeOptionalItemsProps {
  isSaveInProgress: boolean;
  setIsSaveInProgress: (value: boolean) => void;
  currentSegmentIndex: number;
  quote: Quote;
  quoteDraft: Quote;
}

export const UpgradeOptionalItems: React.FC<UpgradeOptionalItemsProps> = ({
  isSaveInProgress,
  setIsSaveInProgress,
  currentSegmentIndex,
  quote,
  quoteDraft,
}) => {
  const notifications = useNotifications();
  const { labels } = useDatasources();
  const { updateQuote } = useQuoteDetailsContext();

  const quoteDraftOptionalItems = getOptionalItems(currentSegmentIndex, quote, quoteDraft);
  const toggleOptionalItem = async (lineItem: LineItem) => {
    setIsSaveInProgress(true);
    const gaItem = generateItemFromLineItem(lineItem, quote, ItemListNames.OptionalItems);
    if (lineItem.isOptional) {
      trackAddToCartEvent(gaItem);
    } else {
      trackRemoveFromCartEvent(gaItem);
    }
    const body: PatchRequestBody[] = [
      {
        op: HttpPatchOperationType.REPLACE,
        path: `/segments/${currentSegmentIndex}/lineItems/${lineItem?.id}`,
        value: {
          isOptional: !lineItem.isOptional,
        },
      },
    ];
    const result = await updateQuote(quoteDraft, body);
    if (!result.isSuccessful) {
      logError(result.error);
      notifications.addErrorNotification({
        title: labels[LabelKey.error],
        description: labels[LabelKey.upgradeFailed],
      });
    }
    setIsSaveInProgress(false);
  };

  return (
    <>
      <div className="g-2 flex flex-col items-start self-stretch pb-8 pt-8" data-testid="upgrade-optional-items">
        <Text variant="label-lg-bold"> {labels[LabelKey.addOptionalItems]} </Text>
        {labels[LabelKey.optionalItemsAdditionalDescription] ?? (
          <Text variant="paragraph-body">{labels[LabelKey.optionalItemsAdditionalDescription]}</Text>
        )}
      </div>
      <div className="flex flex-col gap-4">
        {quoteDraftOptionalItems.length > 0 &&
          quoteDraftOptionalItems.map((lineItem, index) => {
            return (
              <div key={index} className="flex flex-col items-start">
                <div className="flex flex-col items-start self-stretch">
                  <div className="flex items-center self-stretch">
                    <div className="flex flex-1 items-center justify-between">
                      <Text variant="label-md-bold">{lineItem?.description}</Text>
                      <Text className="ml-2 text-nowrap" variant="label-md">
                        {lineItem?.totalPrice.amount !== 0
                          ? FormattedMoney(lineItem?.totalPrice)
                          : labels[LabelKey.included]}
                      </Text>
                    </div>
                    <div className="flex pl-4">
                      <IconButton
                        data-testid={`optional-item-button-${lineItem.sku}`}
                        isDisabled={isSaveInProgress}
                        backgroundClassName={lineItem.isOptional ? "" : "bg-aya"}
                        iconName={lineItem.isOptional ? "plus" : "check"}
                        size="xs"
                        intent="primary-black"
                        aria-label={labels[LabelKey.addButton]}
                        onPress={() => toggleOptionalItem(lineItem)}
                      />
                    </div>
                  </div>
                  <LineItemSeeDetails lineItem={lineItem!} />
                  {index !== quoteDraftOptionalItems.length - 1 && <Divider light className="mt-4 w-full" />}
                </div>
              </div>
            );
          })}
      </div>
      <div className="mt-8 h-px w-full bg-neutral-300" />
    </>
  );
};
