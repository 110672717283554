import React from "react";
import { Button, Text } from "@ilc-technology/luik";
import Enrich from "../../Common/services/TextEnricher";
import { useDatasources } from "../../contexts/StoryblokContext/StoryblokContext";
import { LabelKey } from "../../Common/StoryblokTypes";
import { TRUE } from "../../Common/Constants";

interface SalesUserInfoProps {
  title?: string;
  firstName: string;
  lastName: string;
  description?: string;
  phone?: string;
  email?: string;
  imageUrl?: string;
}

const SalesUserInfo: React.FC<SalesUserInfoProps> = ({
  title,
  firstName,
  lastName,
  description,
  phone,
  email,
  imageUrl,
}) => {
  const { labels, featureSettings } = useDatasources();
  const isWhatsAppButtonEnabled = featureSettings.isWhatsAppButtonEnabled === TRUE;

  return (
    <div className="p-8 lg:px-0 bg-white" data-testid="sales-user-card">
      <div className="flex flex-col items-left gap-8 md:mx-auto max-w-[960px]  bg-white">
        <div>
          <div data-testid="sales-user-header">
            <Text variant="heading-3-bold">{labels[LabelKey.contactUs]}</Text>
          </div>
        </div>
        <div className="flex flex-col md:flex-row">
          {imageUrl && (
            <div
              className="md:w-2/5 lg:w-2/6 2xl:mx-auto xl:max-w-[200px] 2xl:mr-8 mb-8 lg:mr-8 md:mb-0 flex items-center justify-center"
              data-testid="sales-user-image"
            >
              <img
                className="w-40 h-40 lg:w-48 lg:h-48 object-cover rounded-full border-2 border-white shadow-lg ring-4 ring-white"
                src={imageUrl}
              />
            </div>
          )}
          <div className="flex flex-col items-center text-center md:w-4/5 md:text-left md:place-items-start gap-4">
            <div data-testid="sales-user-header">
              <Text className="color-gray-45" variant="special-eyebrow">
                {title ?? labels[LabelKey.headerSalesUser]}
              </Text>
            </div>
            <div data-testid="sales-user-name">
              <Text variant="heading-5-bold">
                {firstName} {lastName}
              </Text>
            </div>
            <div data-testid="sales-user-description">
              <Text variant="paragraph-body">
                {description ?? Enrich(labels[LabelKey.descriptionSalesUser], { firstName: firstName })}
              </Text>
            </div>
            <div className="flex flex-col gap-3 w-full md:flex-row md:space-x-3">
              {phone && (
                <div className="w-full md:w-auto" data-testid="sales-user-phone-button">
                  <Button
                    iconName="phone"
                    size="base"
                    className="w-full md:w-auto"
                    intent="secondary-black"
                    onPress={() => {
                      window.open("tel:" + phone, "_self");
                    }}
                  >
                    <Text variant="label-lg">{labels[LabelKey.phoneCallButton]}</Text>
                  </Button>
                </div>
              )}
              {isWhatsAppButtonEnabled && phone && (
                <div data-testid="sales-user-whatsapp-button">
                  <Button
                    iconName="whatsapp"
                    size="base"
                    className="w-full md:w-auto"
                    intent="secondary-black"
                    onPress={() => {
                      window.open("https://api.whatsapp.com/send?phone=" + encodeURIComponent(phone), "_blank");
                    }}
                  >
                    <Text variant="label-lg">
                      {window.innerWidth < 720
                        ? labels[LabelKey.sendMessageButtonMobile]
                        : labels[LabelKey.sendMessageButton]}
                    </Text>
                  </Button>
                </div>
              )}
              {email && (
                <div data-testid="sales-user-email-button">
                  <Button
                    iconName="mail"
                    size="base"
                    className="w-full md:w-auto"
                    intent="secondary-black"
                    onPress={() => {
                      window.open("mailto:" + email);
                    }}
                  >
                    <Text variant="label-lg">{labels[LabelKey.sendEmailButton]}</Text>
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesUserInfo;
