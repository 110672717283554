import React, { useState } from "react";
import styles from "./WhatsIncluded.module.scss";
import { Accordion, Text } from "@ilc-technology/luik";
import { Button } from "@ilc-technology/luik";
import { WhatsIncludedItemType } from "../../Common/Types";
import Surface from "../Surface/Surface";
import parse from "html-react-parser";
import { useDatasources } from "../../contexts/StoryblokContext/StoryblokContext";
import { LabelKey } from "../../Common/StoryblokTypes";
import WhatsIncludedTitle from "./WhatsIncludedTitle";
import WhatsIncludedContent from "./WhatsIncludedContent";

interface WhatsIncludedProps {
  title: string;
  whatsIncludedItems: Array<WhatsIncludedItemType>;
}

const WhatsIncluded: React.FC<WhatsIncludedProps> = ({ title, whatsIncludedItems }) => {
  const { labels } = useDatasources();
  const initialNumnerOfItems = 3;
  const totalNumberOfItems = whatsIncludedItems.length;
  const [showNumberOfItems, setShowNumberOfItems] = useState(initialNumnerOfItems);
  const isExpanded = () => showNumberOfItems === totalNumberOfItems;

  return (
    <Surface>
      <div className={styles.whatsIncludedContainer}>
        <Text variant="heading-5-bold"> {parse(title)} </Text>
        <div data-testid="whatsincluded-container">
          {whatsIncludedItems.slice(0, showNumberOfItems).map((item, index) => (
            <Accordion
              key={index}
              data={[
                {
                  title: <WhatsIncludedTitle title={item.title} icon={item.icon} />,
                  children: <WhatsIncludedContent content={item.description} />,
                },
              ]}
              startsOpen={false}
              intent="black"
            />
          ))}
        </div>
        <div className={styles.whatsIncludedButtons}>
          <Button
            data-testid={isExpanded() ? "whatsincluded-see-less-button" : "whatsincluded-see-more-button"}
            intent="tertiary-black"
            iconName={isExpanded() ? "chevron-up" : "chevron-down"}
            iconPosition="end"
            size="sm"
            onPress={() => setShowNumberOfItems(isExpanded() ? initialNumnerOfItems : totalNumberOfItems)}
          >
            {isExpanded() ? labels[LabelKey.seeLess] : labels[LabelKey.seeAll]}
          </Button>
        </div>
      </div>
    </Surface>
  );
};

export default WhatsIncluded;
