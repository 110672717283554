import React, { useEffect, useState } from "react";
import styles from "./Payment.module.scss";
import { Button, Divider, Loading, Radio, RadioGroup, Text, useNotifications } from "@ilc-technology/luik";
import { useQuoteContext } from "../../../contexts/QuoteContext/QuoteContext";
import Enrich from "../../../Common/services/TextEnricher";
import {
  ApiRequestType,
  ErrorCode,
  ErrorDetails,
  InitiateOraclePaymentRequest,
  InitiateOraclePaymentSessionResponse,
  Money,
  OraclePaymentLibraryResult,
  OraclePaymentResult,
  PaymentInfo,
  PaymentInfoStatus,
  PaymentOptions,
  PaymentStatus,
  Quote,
  QuotePaymentStatus,
  ReservationRequest,
  ReservationResponse,
  StringIndexableDictionary,
} from "../../../Common/Types";
import { LabelKey } from "../../../Common/StoryblokTypes";
import { useDatasources } from "../../../contexts/StoryblokContext/StoryblokContext";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Surface from "../../Surface/Surface";
import { useSessionContext } from "../../../contexts/SessionContext/SessionContext";
import parse from "html-react-parser";
import { FormattedMoney } from "../../../Common/Helpers/MoneyHelper";
import { AppConfig } from "../../../AppConfig";
import { GenerateHeaderInformation } from "../../../Common/Helpers/ApiHelper";
import { AdyenResultCodes, TRUE } from "../../../Common/Constants";
import { formatDateRange } from "../../../Common/Helpers/DateHelper";
import InformationBox from "../../InformationBox/InformationBox";
import StudentDetails from "./StudentDetails";
import { CustomTrackingEvent, trackEvent, trackPurchaseEvent } from "../../../Common/services/Analytics";
import { fetchRetry } from "../../../Common/services/FetchRetry";
import { generatePaymentSummaryUrl } from "../../../Common/generatePaymentSummaryUrl";
import { FetchTermsAndConditions, TermsAndCondition } from "../../../Common/services/CentralApiService";
import { getQuoteInformation } from "../../../Common/getQuoteInformation";
import {
  convertToErrorDetails,
  getErrorDetailsToDisplay,
  logError,
  returnJsonOrThrowError,
} from "../../../Common/services/ErrorService";
import ErrorComponentWrapper from "../../ErrorHandling/ErrorComponentWrapper";
import { getAllMarketInfo } from "@ilc-technology/env-utils";
import { generatePriceQuoteEventItems } from "../../../Common/services/AnalyticsMappings";

interface PaymentProps {
  quote: Quote;
  reservationPrice: Money;
}

const Payment: React.FC<PaymentProps> = ({ quote, reservationPrice }) => {
  const { pathname, search } = useLocation();
  const { quoteId } = useParams();
  const { session, language } = useSessionContext();
  const { quotesContentCache, activeLanguage, addPaymentToQuote } = useQuoteContext();
  const { labels, featureSettings } = useDatasources();
  const navigate = useNavigate();
  const { user } = session;
  const notifications = useNotifications();
  const isFullPaymentEnabled = featureSettings.isFullPaymentEnabled === TRUE;
  const isPaymentEnabled = featureSettings.isPaymentEnabled === TRUE;
  const isReservationEnabled = featureSettings.isReservationEnabled === TRUE;
  const showOnlyReservation = !isPaymentEnabled && isReservationEnabled;
  const isUserInfoValid = !!user?.country;
  const [termsAndConditions, setTermsAndConditions] = useState<
    StringIndexableDictionary<TermsAndCondition> | undefined
  >(undefined);
  const [termsAndConditionsError, setTermsAndConditionsError] = useState<ErrorDetails | undefined>(undefined);

  const [isTermsSelected, setIsTermsSelected] = useState(false);
  const [isPrivacyPolicySelected, setIsPrivacyPolicySelected] = useState(false);
  const [privacyPolicyLink, setPrivacyPolicyLink] = useState("");
  const [disabledTermsCheckbox, setDisabledTermsCheckbox] = useState(false);
  const [disabledPrivacyCheckbox, setDisabledPrivacyCheckbox] = useState(false);
  const [referenceId, setReferenceId] = useState("");
  const [destinationName, setDestinationName] = useState<string>("");
  const [showPaymentFailureError, setShowPaymentFailureError] = useState<ErrorDetails | undefined>(undefined);
  const [isFullPayment, setIsFullPayment] = useState(false);
  const quoteInfo = getQuoteInformation(quote);

  const weeksLabel =
    quoteInfo.totalDuration === 1
      ? labels[LabelKey.durationWeek]
      : quoteInfo.totalDuration > 4
        ? labels[LabelKey.duration5orMoreWeeks]
        : labels[LabelKey.duration2to4Weeks];
  const terms = termsAndConditions && termsAndConditions[quote.program ?? ""];
  const paymentValue = isFullPayment ? quote.prices?.totalPrice : reservationPrice;
  const quoteStatus = quote.status;
  const fallbackMarket = "UKA";

  useEffect(() => {
    if (!activeLanguage) {
      return;
    }
    FetchTermsAndConditions(activeLanguage)
      .then((data) => {
        const programQueried = quote.program == "ILC" ? "ILSY" : quote.program;
        if (data[quote.program] === undefined) {
          const error: ErrorDetails = {
            code: ErrorCode.TermsForChosenProgramNotFound,
            details: {
              additionalDetails: `Could not find terms and conditions for program ${programQueried}`,
            },
          };
          setTermsAndConditionsError(error);
          logError(error);
        } else if (!data[quote.program].version) {
          const error: ErrorDetails = {
            code: ErrorCode.TermsVersionForChosenProgramNotFound,
            details: {
              additionalDetails: `Could not find terms and conditions version for program ${programQueried}`,
            },
          };
          setTermsAndConditionsError(error);
          logError(error);
        } else {
          setTermsAndConditions(data);
        }
      })
      .catch((error) => {
        const errorDetails = convertToErrorDetails(error, ErrorCode.DownloadingTermsFailed);
        logError(errorDetails);
      });
  }, [activeLanguage]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const link = getPrivacyPolicyLink();
    setPrivacyPolicyLink(link);
  }, []);

  useEffect(() => {
    if (quotesContentCache && quoteInfo.courseSegments.length != 0) {
      const allDestinationNames = quoteInfo.courseSegments.map(
        (segment) => quotesContentCache?.destinations.get(segment.destinationCode)?.name
      );
      setDestinationName(allDestinationNames.join(" - "));
    }
  }, [quotesContentCache, quoteInfo.courseSegments]);

  const getPrivacyPolicyLink = () => {
    const marketsInfo = getAllMarketInfo();

    let marketInfo = marketsInfo.find(
      (m) =>
        m.junoMarketCode.toLowerCase() === quote.market.toLowerCase() &&
        m.languageCode.toLowerCase() === user.languageCode.toLowerCase()
    );

    if (marketInfo == null) {
      marketInfo = marketsInfo.find((m) => m.junoMarketCode.toLowerCase() === quote.market.toLowerCase());
    }

    if (marketInfo == null) {
      marketInfo = marketsInfo.find((m) => m.junoMarketCode.toLowerCase() === fallbackMarket.toLowerCase());

      const errorDetails = {
        code: ErrorCode.PrivacyPolicyForMarketNotFound,
        details: {
          additionalDetails: `Privacy policy not found for market ${quote.market}`,
        },
      };
      logError(errorDetails);
    }

    const domain = AppConfig.environment === "PROD" ? marketInfo?.liveDomain : marketInfo?.qaDomain;

    return `${domain}/legal`;
  };

  const redirectToPaymentSummary = (
    status: PaymentStatus,
    referenceId: string | undefined,
    paymentValue: Money | undefined
  ) => {
    const redirectUrl = generatePaymentSummaryUrl(status, referenceId, paymentValue, quoteId!);
    trackEvent(isFullPayment ? CustomTrackingEvent.FullPaymentChosen : CustomTrackingEvent.DownPaymentChosen);
    navigate(redirectUrl);
  };

  const sendReservationEvent = async () => {
    trackEvent(CustomTrackingEvent.Reserved);
    fetchRetry(`${AppConfig.api.efPlanner}/reservations`, {
      method: "POST",
      headers: GenerateHeaderInformation(session.accessToken),
      body: JSON.stringify({
        quoteId: quote.id,
        termsVersion: terms?.version,
      } as ReservationRequest),
    })
      .then((response) => returnJsonOrThrowError<ReservationResponse>(response))
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .then((_) => {
        const payment: PaymentInfo = {
          payment: {
            amount: 0.0,
            currency: quote.prices.totalPrice.currency,
          },
          status: PaymentInfoStatus.Reserve,
          madeAt: new Date().toISOString(),
        };
        addPaymentToQuote(quote, payment);
        redirectToPaymentSummary(PaymentStatus.ReservationSuccess, undefined, undefined);
      })
      .catch((error) => {
        notifications.addErrorNotification({
          title: labels[LabelKey.paymentStatus_ReservationFailureHeader],
          description: labels[LabelKey.paymentStatus_ReservationFailureMessage],
        });
        logError(convertToErrorDetails(error, ErrorCode.ReservationsPostFailed));
      });
  };

  const initiateOraclePaymentSession = () => {
    const request: InitiateOraclePaymentRequest = {
      externalId: quote.id,
      termsVersion: terms!.version,
      isFullPayment: isFullPayment,
      returnUrl: "payment/" + quote.id,
    };

    fetchRetry(`${AppConfig.api.efPlanner}/payments/initiate-oracle-session`, {
      method: "POST",
      redirect: "manual",
      headers: GenerateHeaderInformation(session.accessToken),
      body: JSON.stringify(request),
    })
      .then((response) => returnJsonOrThrowError<InitiateOraclePaymentSessionResponse>(response))
      .then((res) => {
        if (!document.getElementById("paymentForm")) {
          return;
        }
        if (!res.correlationId) {
          const errorDetails = {
            code: ErrorCode.InitPaymentSessionFailed,
            details: {
              additionalDetails: JSON.stringify(res),
            },
          };
          setShowPaymentFailureError(errorDetails);
          logError(errorDetails);
        }

        setReferenceId(res.correlationId);
        window.adyenDropin && window.adyenDropin.destroy();
        window.adyenDropin = new window.EF.AdyenDropin();

        window.adyenDropin.startSessionsFlow({
          containerId: "#paymentForm",
          sessionId: res.sessionId,
          sessionData: res.sessionData,
          correlationId: res.correlationId,
          onPaymentCompleted: function (result: OraclePaymentLibraryResult) {
            const payment: PaymentInfo = {
              payment: {
                amount: paymentValue?.amount,
                currency: quote.prices.totalPrice.currency,
              },
              status: PaymentInfoStatus.Paid,
              madeAt: new Date().toISOString(),
            };
            if (result.resultCode === AdyenResultCodes.Authorised) {
              trackPurchaseEvent(
                res.merchantReference,
                quote.opportunityUuid,
                generatePriceQuoteEventItems(quote),
                paymentValue
              );
              addPaymentToQuote(quote, payment);
              redirectToPaymentSummary(PaymentStatus.PaymentSuccess, res.correlationId, paymentValue);
            } else if (
              result.resultCode === AdyenResultCodes.Pending ||
              result.resultCode === AdyenResultCodes.Received
            ) {
              trackPurchaseEvent(
                res.merchantReference,
                quote.opportunityUuid,
                generatePriceQuoteEventItems(quote),
                paymentValue
              );
              addPaymentToQuote(quote, payment);
              redirectToPaymentSummary(PaymentStatus.PaymentProcessed, res.correlationId, paymentValue);
            } else if (isError(result.resultCode)) {
              let details: OraclePaymentResult = {
                resultCode: result.resultCode,
                sessionId: res.sessionId,
                correlationId: res.correlationId,
              };
              const errorDetails: ErrorDetails = {
                code: ErrorCode.PaymentFailed,
                details: {
                  additionalDetails: JSON.stringify(details),
                },
              };
              logError(errorDetails);
              setShowPaymentFailureError(errorDetails);
            }
          },
          onError: function () {
            const errorDetails: ErrorDetails = {
              code: ErrorCode.PaymentGatewayReturnedError,
            };
            logError(errorDetails);
            setShowPaymentFailureError(errorDetails);
          },
        });
      })
      .catch((error) => {
        const errorDetails = convertToErrorDetails(error, ErrorCode.InitPaymentSessionFailed);
        setShowPaymentFailureError(errorDetails);
        logError(convertToErrorDetails(error, ErrorCode.OracleSessionInitFailed));
      });
  };

  const isError = (result: string) => {
    return (
      result === AdyenResultCodes.Error || result === AdyenResultCodes.Cancelled || result === AdyenResultCodes.Refused
    );
  };

  useEffect(() => {
    if (isTermsSelected && isPrivacyPolicySelected && isUserInfoValid && quote !== undefined && session.user) {
      initiateOraclePaymentSession();
    }
  }, [paymentValue, isTermsSelected, isPrivacyPolicySelected, quote]);

  useEffect(() => {
    if (search) {
      const qs = new URLSearchParams(search);
      const correlationId = qs.get("correlationId");
      const sessionId = qs.get("sessionId");
      const redirectResult = qs.get("redirectResult");
      const merchantReference = qs.get("merchantReference");

      if (sessionId && redirectResult && correlationId && window.EF?.AdyenDropin) {
        const adyenDropin = new window.EF.AdyenDropin();

        fetchRetry(`${AppConfig.api.efPlanner}/payments?correlationId=${correlationId}`, {
          method: ApiRequestType.GET,
          headers: GenerateHeaderInformation(session.accessToken),
        })
          .then((response) => returnJsonOrThrowError<any>(response))
          .then((res) => {
            if (res) {
              adyenDropin.submitRedirectResult({
                sessionId: encodeURIComponent(sessionId),
                redirectResult: encodeURIComponent(redirectResult),

                onPaymentCompleted: function (result: OraclePaymentLibraryResult) {
                  const value = res.errors !== undefined ? undefined : { amount: res.amount, currency: res.currency };

                  switch (result.resultCode) {
                    case AdyenResultCodes.Authorised:
                      paymentValue &&
                        merchantReference &&
                        trackPurchaseEvent(
                          merchantReference,
                          quote.opportunityUuid,
                          generatePriceQuoteEventItems(quote),
                          res
                        );
                      redirectToPaymentSummary(PaymentStatus.PaymentSuccess, correlationId, value);
                      break;
                    case AdyenResultCodes.Pending:
                    case AdyenResultCodes.Received:
                    case AdyenResultCodes.PresentToShopper:
                      paymentValue &&
                        merchantReference &&
                        trackPurchaseEvent(
                          merchantReference,
                          quote.opportunityUuid,
                          generatePriceQuoteEventItems(quote),
                          res
                        );
                      redirectToPaymentSummary(PaymentStatus.PaymentProcessed, correlationId, value);
                      break;
                    case AdyenResultCodes.Error:
                    case AdyenResultCodes.Refused:
                    case AdyenResultCodes.Cancelled:
                      navigate(pathname);
                      setShowPaymentFailureError({
                        code: ErrorCode.ReceivingPaymentsWasCancelled,
                        details: {
                          additionalDetails: `Payment correlation id: ${correlationId}`,
                        },
                      });
                      break;
                  }
                },
                onError: function () {
                  navigate(pathname);
                  const errorDetails: ErrorDetails = {
                    code: ErrorCode.ReceivingPaymentsError,
                  };
                  setShowPaymentFailureError(errorDetails);
                },
              });
            }
          })
          .catch((error) => {
            logError(
              convertToErrorDetails(error, ErrorCode.PaymentsWithCorrelationFailedToFetch, {
                additionalDetails: `Payment correlation id: ${correlationId}`,
              })
            );
          });
      }
    }
  }, [search, window.EF?.AdyenDropin]);

  return (
    <div className={styles.quoteContainer} data-testid="payment-page">
      <div className={styles.mobileTitleContainer}>
        {!isUserInfoValid && (
          <InformationBox
            intent="warning"
            title={labels[LabelKey.missingDetails]}
            content={labels[LabelKey.missingCountry]}
            iconName={"alert-circle-outlined"}
          />
        )}
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.quoteInformationContainer}>
          <Surface borderRadius="top">
            <div className={styles.headerContainer}>
              <div className={styles.destinationRow}>
                <Text variant="special-eyebrow">
                  {`${formatDateRange(quoteInfo.startAt, quoteInfo.endAt, language)} | ${Enrich(weeksLabel, { numWeeks: quoteInfo.totalDuration })}`}
                </Text>
                <Text variant="heading-5-bold">{destinationName}</Text>
                {quoteInfo.courseInfo && (
                  <Text variant="paragraph-body" className={styles.courseInfo}>
                    {quoteInfo.courseInfo}
                  </Text>
                )}
              </div>
              <div className={styles.priceRow}>
                <div className={styles.totalPrice}>
                  <Text variant="label-sm">{labels[LabelKey.total]}</Text>
                  <Text variant="label-lg-bold" data-testid="payment-summary-total">
                    {FormattedMoney(quote.prices.totalPrice)}
                  </Text>
                </div>
              </div>
            </div>
          </Surface>
          <div className={`${styles.paymentInfo} ${styles.paidBackground}`}>
            <div className={styles.downPaymentPriceRow}>
              <div className={styles.totalPrice}>
                <Text variant="label-lg">
                  {isFullPayment ? labels[LabelKey.fullPaymentLabel] : labels[LabelKey.downPaymentLabel]}
                </Text>
                <Text variant="label-lg-bold" data-testid="payment-summary-to-pay">
                  {FormattedMoney(paymentValue)}
                </Text>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.paymentInformationContainer}>
          <div className={styles.titleContainer}>
            <Text variant="heading-3-bold">{labels[LabelKey.completeBooking]}</Text>
            {!isUserInfoValid && (
              <InformationBox
                intent="warning"
                title={labels[LabelKey.missingDetails]}
                content={labels[LabelKey.missingCountry]}
                iconName={"alert-circle-outlined"}
              />
            )}
          </div>
          <StudentDetails />
          <Surface>
            <div className={styles.detailsContainer}>
              <Text variant="heading-5-bold">{labels[LabelKey.bookingConditions]}</Text>
              <div className="flex w-full flex-row items-center gap-2 self-stretch bg-white">
                <ErrorComponentWrapper
                  isLoading={!termsAndConditions && !termsAndConditionsError}
                  error={termsAndConditionsError}
                >
                  {termsAndConditions && terms && (
                    <>
                      <div>
                        <label className="container">
                          <input
                            type="checkbox"
                            onChange={() => {
                              trackEvent(CustomTrackingEvent.TermsAndConditionsAccepted);
                              setIsTermsSelected(!isTermsSelected);
                              setDisabledTermsCheckbox(true);
                              if (showPaymentFailureError) {
                                setShowPaymentFailureError(undefined);
                              }
                            }}
                            disabled={disabledTermsCheckbox}
                            data-testid="terms-checkbox"
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      <Text variant="label-md">
                        {parse(
                          Enrich(
                            labels[LabelKey.agreeToTermsAndConditions]?.replace(
                              /(\{termsAndConditions\})/,
                              `<a className=${styles.tcLink} href="${terms.filePath}" target="_blank">$1</a>`
                            ),
                            { label: labels[LabelKey.termsAndConditions] }
                          )
                        )}
                      </Text>
                    </>
                  )}
                </ErrorComponentWrapper>
              </div>
              <div className="flex w-full flex-row items-center gap-2 self-stretch bg-white">
                <>
                  <div>
                    <label className="container">
                      <input
                        type="checkbox"
                        onChange={() => {
                          trackEvent(CustomTrackingEvent.PrivacyPolicyAccepted);
                          setIsPrivacyPolicySelected(!isPrivacyPolicySelected);
                          setDisabledPrivacyCheckbox(true);
                          if (showPaymentFailureError) {
                            setShowPaymentFailureError(undefined);
                          }
                        }}
                        disabled={disabledPrivacyCheckbox}
                        data-testid="privacy-checkbox"
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <Text variant="label-md">
                    {parse(
                      Enrich(
                        labels[LabelKey.agreeToPrivacyPolicy]?.replace(
                          /(\{privacyPolicy\})/,
                          `<a className=${styles.tcLink} href="//${privacyPolicyLink}" target="_blank">$1</a>`
                        ),
                        { label: labels[LabelKey.privacyPolicy] }
                      )
                    )}
                  </Text>
                </>
              </div>
            </div>
          </Surface>
          {isPaymentEnabled && quoteStatus !== QuotePaymentStatus.Paid && paymentValue && (
            <Surface>
              <div className={styles.paymentContainer}>
                <div>
                  <Text variant="heading-5-bold">{labels[LabelKey.payment]}</Text>
                </div>
                {showPaymentFailureError && (
                  <InformationBox
                    testid="payment-error-info-box"
                    intent="alert"
                    title={labels[LabelKey.paymentStatus_PaymentFailureHeader]}
                    content={labels[LabelKey.paymentStatus_PaymentFailureMessage]}
                    iconName={"alert-circle-outlined"}
                    additionalInfo={getErrorDetailsToDisplay(showPaymentFailureError)}
                  />
                )}
                {isFullPaymentEnabled && isTermsSelected && isPrivacyPolicySelected && (
                  <div data-testid="payment-options">
                    <Text variant="label-xl-bold">{labels[LabelKey.paymentInformation]}</Text>
                    <RadioGroup className={styles.radioGroup} defaultValue={PaymentOptions.PartialPayment}>
                      <Radio
                        card
                        onClick={() => {
                          setIsFullPayment(false);
                        }}
                        cardOptions={{
                          hint: labels[LabelKey.downPaymentDetails],
                        }}
                        value={PaymentOptions.PartialPayment}
                      >
                        <div>
                          <div className={styles.radioTotalPrice}>
                            {labels[LabelKey.downPayment]}
                            <Text variant="label-lg-bold" data-testid="down-payment-amount">
                              {FormattedMoney(reservationPrice)}
                            </Text>
                          </div>
                        </div>
                      </Radio>
                      <Radio
                        card
                        onClick={() => {
                          setIsFullPayment(true);
                        }}
                        cardOptions={{
                          hint: labels[LabelKey.fullPaymentDetails],
                        }}
                        value={PaymentOptions.FullPayment}
                      >
                        <div>
                          <div className={styles.radioTotalPrice}>
                            {labels[LabelKey.fullPayment]}
                            <Text variant="label-lg-bold" data-testid="full-payment-amount">
                              {FormattedMoney(quote.prices?.totalPrice)}
                            </Text>
                          </div>
                        </div>
                      </Radio>
                    </RadioGroup>
                  </div>
                )}
                {!isTermsSelected && (
                  <InformationBox
                    data-testid="check-tandc-warning"
                    intent="warning"
                    title={labels[LabelKey.missingTerms]}
                    iconName={"alert-circle-outlined"}
                    testid="terms-warning-info-box"
                  />
                )}
                {!isPrivacyPolicySelected && (
                  <InformationBox
                    data-testid="check-tandc-warning"
                    intent="warning"
                    title={labels[LabelKey.missingPrivacyPolicy]}
                    iconName={"alert-circle-outlined"}
                    testid="privacy-warning-info-box"
                  />
                )}
                {isTermsSelected && isPrivacyPolicySelected && showPaymentFailureError === undefined && (
                  <div className={styles.paymentContainer} data-testid="payment-container">
                    <div className={styles.orderSummaryContainer}>
                      <div className={styles.orderSummary}>
                        <Text variant="label-md-bold">{labels[LabelKey.referenceNumber]}</Text>
                        {!referenceId ? (
                          <Loading size="lg" intent="black" />
                        ) : (
                          <Text variant="paragraph-body">{referenceId}</Text>
                        )}
                      </div>
                      <Divider light />
                      <div className={styles.orderSummary}>
                        <Text variant="label-md-bold">{labels[LabelKey.toPayNow]}</Text>
                        <Text variant="paragraph-body">{FormattedMoney(paymentValue)}</Text>
                      </div>
                      <Divider light />
                      <div className={styles.orderSummary}>
                        <Text variant="label-md-bold">{labels[LabelKey.beneficiary]}</Text>
                        <Text variant="paragraph-body">{labels[LabelKey.efEducationFirst]}</Text>
                      </div>
                      <Divider light />
                    </div>
                    {isUserInfoValid && (
                      <div className={styles.paymentFormSection}>
                        <Text variant="label-xl-bold">{labels[LabelKey.paymentMethod]}</Text>
                        {!referenceId && <Loading className="loading" size="lg" intent="black" />}
                        <div id="paymentForm" data-testid="payment-form"></div>
                      </div>
                    )}
                    <div>
                      <Text variant="label-md">{labels[LabelKey.summaryOfBookingPaymentSentToEmail]}</Text>
                    </div>
                  </div>
                )}
              </div>
            </Surface>
          )}
          {(isReservationEnabled || showOnlyReservation) &&
            quoteStatus === QuotePaymentStatus.Unreserved &&
            isTermsSelected &&
            isPrivacyPolicySelected && (
              <div className={styles.reservationSection}>
                <Button
                  data-testid="reservation-button"
                  iconName="locked"
                  iconPosition="start"
                  intent="secondary-black"
                  size="base"
                  onPress={() => sendReservationEvent()}
                  isDisabled={!isTermsSelected}
                >
                  {labels[LabelKey.reservationFor24Hours]}
                </Button>
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

export default Payment;
