import React, { useEffect, useRef, useState } from "react";
import { Link, Text } from "@ilc-technology/luik";
import { useDatasources } from "../../contexts/StoryblokContext/StoryblokContext";
import { LabelKey } from "../../Common/StoryblokTypes";

interface ExpandableTextProps {
  children: React.ReactNode;
}

const ExpandableText: React.FC<ExpandableTextProps> = ({ children }) => {
  const { labels } = useDatasources();
  const [isExpanded, setIsExpanded] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // CSS can't animate dynamic content size.
    // This is a trick to animate to auto height
    if (contentRef.current) {
      contentRef.current.style.maxHeight = isExpanded ? `${contentRef.current.scrollHeight}px` : "0";
    }
  }, [isExpanded]);

  const toggleExpansion = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();

    setIsExpanded(!isExpanded);
  };

  return (
    <div className="flex flex-col" data-testid="expandable-text">
      <div ref={contentRef} className="transition-max-height max-h-0 overflow-hidden duration-300">
        {children}
      </div>
      <Link
        className="m-0 mt-2 cursor-pointer p-0"
        color="secondary"
        iconName={isExpanded ? "chevron-up" : "chevron-down"}
        iconPosition="end"
        size="base"
        data-testid="expandable-text-link"
        onClick={toggleExpansion}
      >
        <Text variant="label-lg-bold" data-testid="expandable-text-link-text">
          {isExpanded ? labels[LabelKey.hideDetails] : labels[LabelKey.showDetails]}
        </Text>
      </Link>
    </div>
  );
};

export default ExpandableText;
