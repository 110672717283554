import { SegmentType, SalesItemType, Quote, Segment } from "./Types";

export type QuoteInformation = {
  quoteInfo: Quote;
  courseSegments: Segment[];
  startAt: string;
  endAt: string;
  isMultiQuote: boolean;
  courseInfo: string | undefined;
  totalDuration: number;
};

export const getQuoteInformation = (quote: Quote): QuoteInformation => {
  const courseSegments = quote.segments?.filter((segment) => segment.type === SegmentType.Course) ?? [];
  const startAt = quote.segments[0]?.startAt || ""; //The component where this is used won't render if quoteData is undefined
  const endAt = quote.segments[quote.segments?.length - 1]?.endAt || ""; // so we can have a default of "" to avoid type errors
  const isMultiQuote = (quote.segments?.length || 0) > 1;
  const courseInfo = isMultiQuote
    ? ""
    : courseSegments?.[0]?.lineItems.find((lineItem) => lineItem.type == SalesItemType.Course)?.description;
  const totalDuration = courseSegments ? courseSegments.reduce((acc, val) => acc + val.duration.value, 0) : 0;

  return {
    quoteInfo: quote,
    courseSegments,
    startAt,
    endAt,
    isMultiQuote,
    courseInfo,
    totalDuration,
  };
};
