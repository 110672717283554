import React from "react";
import styles from "./QuoteHeader.module.scss";
import { Text } from "@ilc-technology/luik";
import Surface from "../../Surface/Surface";
import { useDatasources } from "../../../contexts/StoryblokContext/StoryblokContext";
import { formatDateRange, addDays, formatDate } from "../../../Common/Helpers/DateHelper";
import { FormatCurrency, FormattedMoney } from "../../../Common/Helpers/MoneyHelper";
import { Money, PaymentInfo, PaymentInfoStatus } from "../../../Common/Types";
import Enrich from "../../../Common/services/TextEnricher";
import { LabelKey } from "../../../Common/StoryblokTypes";
import { useSessionContext } from "../../../contexts/SessionContext/SessionContext";
import { getPaymentOrReservation } from "../../../Common/services/PaymentsService";

interface QuoteHeaderProps {
  destinationName: string;
  courseInfo?: string | undefined;
  startAt: string;
  endAt: string;
  duration: string;
  totalPrice: Money;
  payments: Array<PaymentInfo> | null;
}

const QuoteHeader: React.FC<QuoteHeaderProps> = ({
  destinationName,
  courseInfo,
  startAt,
  endAt,
  duration,
  totalPrice,
  payments,
}) => {
  const { language } = useSessionContext();
  const { labels } = useDatasources();

  const isQuotePaidOrReserved =
    payments && payments.find((x) => x.status === PaymentInfoStatus.Reserve || x.status === PaymentInfoStatus.Paid);
  const payment = getPaymentOrReservation(payments);

  const paymentMadeAt = payment?.madeAt
    ? payment?.status === PaymentInfoStatus.Paid
      ? formatDate(payment?.madeAt, language)
      : formatDate(addDays(new Date(payment?.madeAt), 1).toString(), language)
    : undefined;
  const enricherContext = isQuotePaidOrReserved
    ? {
        paymentStatus: payment?.status,
        paymentMadeAt: paymentMadeAt,
      }
    : {
        paymentStatus: "",
        paymentMadeAt: undefined,
      };
  const paymentInfoLabel = "paymentInfo_";
  const borderRadius = isQuotePaidOrReserved ? "bottom" : "all";

  return (
    <>
      <div className={styles.container}>
        {isQuotePaidOrReserved && (
          <div
            className={`${styles.paymentInfo} ${isQuotePaidOrReserved && payment?.status == PaymentInfoStatus.Reserve ? styles.reservedBackground : styles.paidBackground}`}
            data-testid="payment-component"
          >
            <Text variant="label-md-bold" className={styles.paymentInfoText}>
              {Enrich(labels[paymentInfoLabel + payment?.status], enricherContext)}
            </Text>
          </div>
        )}
        <Surface borderRadius={borderRadius}>
          <div className={styles.headerContainer}>
            <div className={`${styles.flexColumn} ${styles.destinationColumn}`}>
              <Text variant="special-eyebrow">
                {formatDateRange(startAt, endAt, language)} | {duration}
              </Text>
              <Text variant="heading-3-bold">{destinationName}</Text>
              {courseInfo && (
                <Text variant="paragraph-body" className={styles.courseInfo}>
                  {courseInfo}
                </Text>
              )}
            </div>
            <div className={`${styles.flexColumnPrice} ${styles.priceColumn}`}>
              <div className={styles.totalPrice}>
                <Text className={styles.labelTotal} variant="heading-5">
                  {labels[LabelKey.total]}
                </Text>
                <Text variant="heading-5-bold" className={styles.labelTotalPrice}>
                  {FormattedMoney(totalPrice)}
                </Text>
              </div>
              {isQuotePaidOrReserved && payment?.status === PaymentInfoStatus.Paid && (
                <div className={styles.paymentsMade} data-testid="paid-details-row">
                  <Text className={`${styles.labelPaymentsMade} color-gray-45`} variant="label-xl">
                    {labels[LabelKey.paymentsMade]}
                  </Text>
                  <Text className={styles.labelPaymentsMade} variant="label-xl-bold">
                    {FormatCurrency(payment.payment.amount, totalPrice.currency)}
                  </Text>
                </div>
              )}
            </div>
          </div>
        </Surface>
      </div>
    </>
  );
};

export default QuoteHeader;
