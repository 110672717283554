import { Button, Text } from "@ilc-technology/luik";
import styles from "./QuoteDetailsFooter.module.scss";
import Enrich from "../../../Common/services/TextEnricher";
import { LabelKey } from "../../../Common/StoryblokTypes";
import { Quote, QuotePaymentStatus } from "../../../Common/Types";
import "../../../App.scss";
import { useDatasources } from "../../../contexts/StoryblokContext/StoryblokContext";
import { FormattedMoney } from "../../../Common/Helpers/MoneyHelper";
import parse from "html-react-parser";
import { trackEvent, StandardTrackingEvent, CustomTrackingEvent } from "../../../Common/services/Analytics";
import { useNavigate } from "react-router-dom";
import { useNotifications } from "@ilc-technology/luik";
import { TRUE } from "../../../Common/Constants";
import { useSessionContext } from "../../../contexts/SessionContext/SessionContext";
import { formatDate } from "../../../Common/Helpers/DateHelper";
import { QuoteDraft, useQuoteDetailsContext } from "../../../contexts/QuoteDetailsContext";
import { logError } from "../../../Common/services/ErrorService";
import React from "react";
import { generatePriceQuoteEventItems } from "../../../Common/services/AnalyticsMappings";

interface QuoteDetailsFooterProps {
  isSaveInProgress: boolean;
  setSaveInProgress: (value: boolean) => void;
  quote: Quote;
  quoteDraft: QuoteDraft;
}

const QuoteDetailsFooter: React.FC<QuoteDetailsFooterProps> = ({
  isSaveInProgress,
  setSaveInProgress,
  quote,
  quoteDraft,
}) => {
  const { language } = useSessionContext();
  const { saveQuote } = useQuoteDetailsContext();
  const { labels, featureSettings } = useDatasources();
  const navigate = useNavigate();
  const notifications = useNotifications();
  const isPaymentEnabled = featureSettings.isPaymentEnabled === TRUE;
  const isReservationEnabled = featureSettings.isReservationEnabled === TRUE;

  const enricherContext = {
    currentQuote: quoteDraft.quoteDraft,
  };
  const reservationPrice = quoteDraft.quoteDraft.reservationPrice;
  const buttonLabelKey = quoteDraft.isQuoteDraftEdited
    ? LabelKey.saveAsNew
    : quote.status === QuotePaymentStatus.Unreserved
      ? LabelKey.bookNow
      : LabelKey.payNow;
  const quoteStatus = quote?.status;
  const totalPrice = quoteDraft.quoteDraft.quoteData.prices.totalPrice;

  const handleButtonPress = async () => {
    if (quoteDraft.isQuoteDraftEdited) {
      trackEvent(CustomTrackingEvent.SaveNewQuote);
      setSaveInProgress(true);
      const result = await saveQuote(quote);
      setSaveInProgress(false);
      if (result.isSuccessful) {
        notifications.addSuccessNotification({
          title: labels[LabelKey.success],
          description: labels[LabelKey.quoteSaved],
        });
        navigate(`/quote/${result?.quote!.id}`);
      } else {
        logError(result.error);
        notifications.addErrorNotification({
          title: labels[LabelKey.error],
          description: labels[LabelKey.saveFailed],
        });
      }
    } else {
      trackEvent(StandardTrackingEvent.BeginCheckout, {
        value: quote.prices.totalPrice.amount,
        currency: quote.prices.totalPrice.currency,
        items: generatePriceQuoteEventItems(quote),
      });
      navigate("/payment/" + quote.id);
    }
  };

  return (
    <div className={styles.footerBox}>
      <div className={styles.content}>
        {reservationPrice && (
          <div className={styles.priceBox}>
            <Text variant="label-xl-bold">{`${labels[LabelKey.total]}: ${FormattedMoney(totalPrice)}`}</Text>
            <Text variant="label-sm" className="color-gray-45">
              {Enrich(labels[LabelKey.quoteValidUntil], {
                quoteValidToDate: formatDate(quote.validUpTo, language),
              })}
            </Text>
          </div>
        )}
        <div className={styles.reservationBox}>
          {reservationPrice && quoteStatus !== QuotePaymentStatus.Paid && (
            <div className={styles.reservationPriceBox}>
              <Text variant="label-sm">
                {parse(
                  Enrich(
                    labels[LabelKey.reserveFor]?.replace(
                      /(\{quoteReservationPrice\})/,
                      `<span className=${styles.reservationPrice}>$1</span>`
                    ),
                    enricherContext
                  )
                )}
              </Text>
              <Text variant="label-sm">{labels[LabelKey.orPayLater]}</Text>
            </div>
          )}
          {(isPaymentEnabled || isReservationEnabled) &&
            reservationPrice &&
            quoteStatus !== QuotePaymentStatus.Paid && (
              <div className={styles.footerButtons}>
                <Button
                  size="base"
                  intent="primary-black"
                  isDisabled={isSaveInProgress}
                  isLoading={isSaveInProgress}
                  onPress={handleButtonPress}
                >
                  {labels[buttonLabelKey]}
                </Button>
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

export default QuoteDetailsFooter;
