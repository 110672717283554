import { createRoot } from "react-dom/client";
import App from "./App";
import QuotePage from "./components/Pages/Quote/Quote";
import HomePage from "./components/Pages/Home/HomePage";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import PreviewApp from "./PreviewApp";
import React from "react";
import { QuoteDetailsContextProvider } from "./contexts/QuoteDetailsContext";
import PaymentSummaryPage from "./components/Pages/PaymentSummary/PaymentSummaryPage";
import Banner from "./components/Pages/PaymentSummary/Banner";
import PaymentPage from "./components/Pages/Payment/PaymentPage";

const router = createBrowserRouter([
  {
    element: <App />,
    children: [
      {
        path: "/",
        element: <HomePage />,
      },
      {
        path: "/quote/:quoteId",
        element: (
          <QuoteDetailsContextProvider fetchDraft={true}>
            <QuotePage />
          </QuoteDetailsContextProvider>
        ),
      },
      {
        path: "/payment/:quoteId",
        element: (
          <QuoteDetailsContextProvider fetchDraft={false}>
            <PaymentPage />
          </QuoteDetailsContextProvider>
        ),
      },
      {
        path: "/paymentSummary/:quoteId",
        element: (
          <QuoteDetailsContextProvider fetchDraft={false}>
            <Banner />
            <PaymentSummaryPage />
          </QuoteDetailsContextProvider>
        ),
      },
    ],
  },
  {
    path: "/preview/*",
    element: <PreviewApp />,
  },
]);

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(<RouterProvider router={router} />);
