import { useEffect, useState } from "react";
import { Text, useNotifications } from "@ilc-technology/luik";
import { useDatasources } from "../../contexts/StoryblokContext/StoryblokContext";
import { LabelKey } from "../../Common/StoryblokTypes";
import { CourseContent, HttpPatchOperationType, Money, PatchRequestBody, Quote, SegmentType } from "../../Common/Types";
import parse from "html-react-parser";
import Enrich from "../../Common/services/TextEnricher";
import { FormattedMoney } from "../../Common/Helpers/MoneyHelper";
import UpgradeModal from "./UpgradeModal";
import {
  ItemListNames,
  StandardTrackingEvent,
  trackAddToCartEvent,
  trackEvent,
  trackRemoveFromCartEvent,
} from "../../Common/services/Analytics";
import { useQuoteDetailsContext } from "../../contexts/QuoteDetailsContext";
import { logError } from "../../Common/services/ErrorService";
import { generateItemFromLineItem } from "../../Common/services/AnalyticsMappings";

interface CourseUpgradeModalProps {
  isOpen: boolean;
  closeHandler: () => void;
  content: CourseContent;
  sku: string;
  duration: number;
  totalPriceDifference: Money;
  quote: Quote;
  quoteDraft: Quote;
}

function getCourseLineItem(quoteDraft: Quote) {
  return quoteDraft.segments
    .filter((segment) => segment.type === SegmentType.Course)[0]
    .lineItems.filter((lineItem) => lineItem.type === 90)[0];
}

const CourseUpgradeModal = ({
  isOpen,
  closeHandler,
  content,
  sku,
  duration,
  totalPriceDifference,
  quote,
  quoteDraft,
}: CourseUpgradeModalProps) => {
  const { labels } = useDatasources();
  const notifications = useNotifications();
  const { updateQuote } = useQuoteDetailsContext();
  const [isLoading, setIsLoading] = useState(false);

  const weeksLabel =
    duration === 1
      ? labels[LabelKey.durationWeek]
      : duration > 4
        ? labels[LabelKey.duration5orMoreWeeks]
        : labels[LabelKey.duration2to4Weeks];

  const courseLineItem = getCourseLineItem(quoteDraft);

  useEffect(() => {
    trackEvent(StandardTrackingEvent.SelectItem, {
      items: [generateItemFromLineItem(courseLineItem, quoteDraft, ItemListNames.CourseUpgrades, sku)],
    });
  }, []);

  const upgradeCourse = async () => {
    if (courseLineItem && sku) {
      setIsLoading(true);

      const body: PatchRequestBody[] = [
        {
          op: HttpPatchOperationType.REPLACE,
          path: `/segments/0/lineItems/${courseLineItem.id}`,
          value: {
            sku: sku,
            quantity: courseLineItem.quantity,
            startDate: courseLineItem.startAt,
            endDate: courseLineItem.endAt,
          },
        },
      ];
      const result = await updateQuote(quoteDraft, body);
      if (result.isSuccessful) {
        if (result.quote) {
          trackRemoveFromCartEvent(generateItemFromLineItem(courseLineItem, quote, ItemListNames.CourseUpgrades));
          trackAddToCartEvent(
            generateItemFromLineItem(getCourseLineItem(result.quote), quote, ItemListNames.CourseUpgrades, sku)
          );
        }
        setIsLoading(false);
        closeHandler();
      } else {
        logError(result.error);
        notifications.addErrorNotification({
          title: labels[LabelKey.error],
          description: labels[LabelKey.upgradeFailed],
        });
      }
    }
  };

  return (
    <UpgradeModal
      isOpen={isOpen}
      isSaving={isLoading}
      isSavingDisabled={false}
      closeHandler={closeHandler}
      header={labels[LabelKey.upgradeCourse]}
      cancelHandler={closeHandler}
      upgradeHandler={upgradeCourse}
    >
      <section className="px-6 pb-6 pt-10 md:px-10">
        <Text as="h3" variant="heading-4-bold" role="heading">
          {labels[LabelKey.learnFaster]}
        </Text>
        <div className="mt-6 md:mt-6">
          <Text variant="paragraph-body">{parse(content.description)}</Text>
        </div>
      </section>
      <div className="mx-0 rounded-3xl bg-neutral-50 p-6 md:mx-10">
        <div>
          <Text variant="heading-5-bold">{labels[LabelKey.learnFaster]}</Text>
          <Text variant="paragraph-body">
            {content.title} {content.lessonsPerWeek} {content.lessonsPerWeekText}
          </Text>
        </div>
        <div className="mt-12">
          <div className="mt-8 flex flex-col gap-4">
            <div className="flex justify-between">
              <Text variant="label-md">{labels[LabelKey.total]}</Text>
              <Text variant="label-md">{FormattedMoney(totalPriceDifference)}</Text>
            </div>
          </div>
          <Text variant="label-md">{Enrich(weeksLabel, { numWeeks: duration })}</Text>
        </div>
      </div>
    </UpgradeModal>
  );
};

export default CourseUpgradeModal;
