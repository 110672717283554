import React from "react";
import styles from "./InformationBox.module.scss";
import { Icon, Text } from "@ilc-technology/luik";
import { AdditionalInfo } from "../ErrorHandling/AdditionalInfo";

interface InformationBoxProps {
  intent?: "default" | "warning" | "alert";
  title: string;
  content?: string;
  iconName: string;
  testid?: string;
  additionalInfo?: string;
}

const Surface: React.FC<InformationBoxProps> = ({
  title,
  content,
  iconName,
  intent = "default",
  testid = "information-box",
  additionalInfo,
}) => {
  const backgroundColorStyles = {
    default: styles.default,
    warning: styles.warning,
    alert: styles.alert,
  };

  const iconColorStyles = {
    default: styles.defaultIcon,
    warning: styles.warningIcon,
    alert: styles.alertIcon,
  };

  return (
    <div>
      <div className={`${styles.container} ${backgroundColorStyles[intent]}`} data-testid={testid}>
        <div className={styles.icon}>
          <Icon iconName={iconName} size="lg" className={`${iconColorStyles[intent]}`} />
        </div>
        <div className={styles.textContainer}>
          <Text variant="label-lg-bold">{title}</Text>
          {content && <Text variant="label-md">{content}</Text>}
        </div>
      </div>
      <AdditionalInfo additionalInfo={additionalInfo} />
    </div>
  );
};

export default Surface;
