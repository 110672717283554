import { v4 as uuidv4 } from "uuid";
import { ResponseWithFlowId } from "../Types";
import { formattedEfFlowId } from "./ErrorService";

function wait(delay: number) {
  return new Promise((resolve) => setTimeout(resolve, delay));
}

// retry times, uses array index for access, index 0 unused
const retryTimes = [Number.MAX_SAFE_INTEGER, 2500, 1000];

export const efFlowIDHeaderName = "EF-Flow-ID";

/**
 * Simple retry wrapper on fetch, used for retrying a request multiple times in case of errors thrown
 * @param url address to call
 * @param fetchOptions fetch options, passed to fetch directly
 * @param retries retry count
 * @returns Promise<Response> for underlying request
 */
export function fetchRetry(url: string, fetchOptions: RequestInit = {}, retries = 3): Promise<ResponseWithFlowId> {
  if (!retries || retries > 3 || retries < 0) {
    retries = 3;
  }
  let flowId: string;
  if (fetchOptions?.headers) {
    flowId = uuidv4();
    (fetchOptions.headers as Record<string, string>)["EF-Flow-ID"] = flowId;
    fetchOptions.keepalive = true;
  }

  function onError(err: Error) {
    const triesLeft = retries - 1;
    if (!triesLeft) {
      err.message = `${err.message}. ${formattedEfFlowId(flowId)}`;
      throw err;
    }
    const delay = retryTimes[triesLeft];
    return wait(delay).then(() => fetchRetry(url, fetchOptions, triesLeft));
  }
  return fetch(url, fetchOptions)
    .then((response) => {
      const responseWithFlowId = response as ResponseWithFlowId;
      responseWithFlowId.efFlowId = flowId;
      return responseWithFlowId;
    })
    .catch(onError);
}
