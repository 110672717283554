import { useEffect, useState } from "react";
import { Radio, RadioGroup, Text, useNotifications } from "@ilc-technology/luik";
import UpgradeModal from "../Modal/UpgradeModal";
import { useDatasources } from "../../contexts/StoryblokContext/StoryblokContext";
import { LabelKey } from "../../Common/StoryblokTypes";
import { FormatCurrency, FormattedMoney } from "../../Common/Helpers/MoneyHelper";
import { QuoteData } from "../../contexts/QuoteContext/QuoteContext";
import { HttpPatchOperationType, LineItem, PatchRequestBody, Quote, SalesItemType } from "../../Common/Types";
import { LineItemIsExtraNight } from "../../Common/Helpers/LineItemHelper";
import { LineItemSeeDetails } from "../LineItemSeeDetails/LinteItemSeeDetails";
import {
  ItemListNames,
  StandardTrackingEvent,
  trackAddToCartEvent,
  trackEvent,
  trackRemoveFromCartEvent,
} from "../../Common/services/Analytics";
import { useQuoteDetailsContext } from "../../contexts/QuoteDetailsContext";
import { logError } from "../../Common/services/ErrorService";
import { generateItemFromLineItem } from "../../Common/services/AnalyticsMappings";

type UpgradeAccommodationModalProps = {
  isModalOpen: boolean;
  isSaveInProgress: boolean;
  handleModalClose: () => void;
  quote: Quote;
  quoteDraft: QuoteData;
};

const UpgradeAccommodationModal = ({
  isModalOpen,
  isSaveInProgress,
  handleModalClose,
  quote,
  quoteDraft,
}: UpgradeAccommodationModalProps) => {
  const { labels } = useDatasources();
  const { updateQuote } = useQuoteDetailsContext();
  const [isLoading, setIsLoading] = useState(false);
  const notifications = useNotifications();
  const [selectedAccommodation, setSelectedAccommodation] = useState<LineItem | undefined>(undefined);

  const currentSegment = quoteDraft.quoteData.segments[0];
  const defaultAccommodation = currentSegment?.lineItems.find(
    (lineItem) => lineItem.type == SalesItemType.Accommodation && !lineItem.isOptional
  );
  const accommodationOptions = currentSegment?.lineItems.filter(
    (lineItem) =>
      lineItem.type === SalesItemType.Accommodation &&
      !LineItemIsExtraNight(lineItem) &&
      lineItem.sku !== defaultAccommodation?.sku
  );

  useEffect(() => {
    setSelectedAccommodation(defaultAccommodation);
  }, [currentSegment]);

  useEffect(() => {
    if (accommodationOptions) {
      trackEvent(StandardTrackingEvent.ViewItemList, {
        items: accommodationOptions.map((x) => {
          return generateItemFromLineItem(x, quoteDraft.quoteData, ItemListNames.AccommodationUpgrades);
        }),
      });
    }
  }, []);

  const upgradeAccommodation = async () => {
    if (selectedAccommodation && defaultAccommodation) {
      setIsLoading(true);

      const body: PatchRequestBody[] = [
        {
          op: HttpPatchOperationType.REPLACE,
          path: `/segments/0/lineItems/${selectedAccommodation.id}`,
          value: {
            isOptional: false,
          },
        },
        {
          op: HttpPatchOperationType.REPLACE,
          path: `/segments/0/lineItems/${defaultAccommodation.id}`,
          value: {
            isOptional: true,
          },
        },
      ];

      const result = await updateQuote(quoteDraft.quoteData, body);

      if (!result.isSuccessful) {
        logError(result.error);
        notifications.addErrorNotification({
          title: labels[LabelKey.error],
          description: labels[LabelKey.upgradeFailed],
        });
      } else {
        trackRemoveFromCartEvent(
          generateItemFromLineItem(defaultAccommodation, quote, ItemListNames.AccommodationUpgrades)
        );
        trackAddToCartEvent(
          generateItemFromLineItem(selectedAccommodation, quote, ItemListNames.AccommodationUpgrades)
        );
        notifications.addSuccessNotification({
          title: labels[LabelKey.success],
          description: labels[LabelKey.accommodationHasChanged],
        });
      }
      setIsLoading(false);
      handleModalClose();
    }
  };

  return (
    <UpgradeModal
      isOpen={isModalOpen}
      isSaving={isLoading}
      isSavingDisabled={false}
      closeHandler={handleModalClose}
      header={labels[LabelKey.upgradeCourse]}
      cancelHandler={handleModalClose}
      upgradeHandler={upgradeAccommodation}
    >
      <section className="px-6 pt-10 md:px-10">
        <Text as="h3" variant="heading-4-bold" role="heading">
          {labels[LabelKey.changeAccommodation]}
        </Text>
        <Text variant="paragraph-body" className="mt-6">
          {labels[LabelKey.changeAccommodationDescription]}
        </Text>
        <Text variant="paragraph-body" className="mt-4">
          {labels[LabelKey.currentAccommodation]}
        </Text>
        {defaultAccommodation && (
          <Text variant="paragraph-body-bold" className="mt-1">
            {defaultAccommodation?.description}
            {` (${labels[LabelKey.original]})`}
          </Text>
        )}
      </section>

      <div
        className="mx-0 mt-6 rounded-3xl bg-neutral-50 p-6 md:mx-10 md:p-10"
        data-testid="upgrade-accommodation-modal"
      >
        <div className="mb-4">
          <label className="text-label-lg">{labels[LabelKey.changeAccommodationTo]}</label>
        </div>
        <RadioGroup
          data-testid="select-accommodation-radioButton"
          containerClassName="w-full"
          className="flex flex-col items-start gap-6 self-stretch"
          isDisabled={isSaveInProgress}
          onChange={(value) => {
            const lineItem = currentSegment?.lineItems.find((lineItem) => lineItem.sku === value);
            if (lineItem) {
              trackEvent(StandardTrackingEvent.SelectItem, {
                items: [generateItemFromLineItem(lineItem, quoteDraft.quoteData, ItemListNames.AccommodationUpgrades)],
              });
              setSelectedAccommodation(lineItem);
            }
          }}
        >
          {accommodationOptions?.map((lineItem) => (
            <div
              key={lineItem.sku}
              className={`flex flex-col gap-1 self-stretch rounded-lg bg-white p-6 ${selectedAccommodation?.sku === lineItem.sku ? "border border-primary-accessible" : "border border-inactive"}`}
            >
              <div className="flex flex-col items-start gap-2">
                <div className="flex h-auto items-center justify-between gap-2 self-stretch">
                  <div className="min-w-0 flex-1">
                    <Radio value={lineItem.sku} className="py-1">
                      <Text
                        data-testid={`select-accommodation-radio-${lineItem.sku}`}
                        variant="label-md-bold"
                        className="break-words"
                      >
                        {lineItem.description}
                        {lineItem.sku === defaultAccommodation?.sku && ` (${labels[LabelKey.original]})`}
                      </Text>
                    </Radio>
                  </div>
                  <Text variant="label-md" className="whitespace-nowrap">
                    {lineItem.totalPrice.amount !== 0 ? FormattedMoney(lineItem.totalPrice) : labels[LabelKey.included]}
                  </Text>
                </div>
                <Text className="whitespace-nowrap" variant="label-md">
                  {FormattedMoney(lineItem.price)}/week
                </Text>
                <LineItemSeeDetails lineItem={lineItem} />
              </div>
            </div>
          ))}
        </RadioGroup>
        {selectedAccommodation && defaultAccommodation && (
          <div className="mt-6">
            <Text variant="paragraph-body-bold">{selectedAccommodation?.description}</Text>
            <div className="mt-8 flex flex-col gap-4">
              <div className="flex justify-between">
                <Text variant="paragraph-body">{labels[LabelKey.upgradeCost]}</Text>
                <Text variant="paragraph-body-bold" data-testid="upgrade-accommodation-cost">
                  {selectedAccommodation.totalPrice?.amount < defaultAccommodation.totalPrice?.amount
                    ? `- ${FormatCurrency(defaultAccommodation.totalPrice?.amount - selectedAccommodation.totalPrice?.amount, selectedAccommodation.totalPrice?.currency)}`
                    : `+ ${FormattedMoney(selectedAccommodation.totalPrice)}`}
                </Text>
              </div>
              <div className="flex justify-between">
                <Text variant="paragraph-body">{labels[LabelKey.totalAfter]}</Text>
                <Text variant="paragraph-body" data-testid="upgrade-total-payment-amount">
                  {currentSegment?.price?.totalPrice && FormattedMoney(currentSegment.price.totalPrice)}
                </Text>
              </div>
            </div>
          </div>
        )}
      </div>
    </UpgradeModal>
  );
};

export default UpgradeAccommodationModal;
