import React, { useEffect } from "react";
import { useQuoteContext } from "../../../contexts/QuoteContext/QuoteContext";
import PaymentSummary from "./PaymentSummary";
import ErrorComponentWrapper from "../../ErrorHandling/ErrorComponentWrapper";
import { useQuoteDetailsContext } from "../../../contexts/QuoteDetailsContext";

interface PaymentSummaryPageProps {}

const PaymentSummaryPage: React.FC<PaymentSummaryPageProps> = () => {
  const { selected, isQuoteListLoading, setupError } = useQuoteContext();
  const { loadingQuote, loadingQuoteError, reservationPrice } = useQuoteDetailsContext();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <ErrorComponentWrapper
      isLoading={!selected || isQuoteListLoading || loadingQuote}
      error={setupError || loadingQuoteError}
    >
      {selected && <PaymentSummary quote={selected.quoteData} reservationPrice={reservationPrice} />}
    </ErrorComponentWrapper>
  );
};

export default PaymentSummaryPage;
