import React from "react";
import { Button } from "@ilc-technology/luik";
import { LabelKey } from "../../../Common/StoryblokTypes";
import { StringIndexableDictionary } from "../../../Common/Types";

export interface SortItem {
  name: string;
  translationKey: LabelKey;
}

export interface SortListHeaderProps {
  sortOrders: Array<SortItem>;
  labels: StringIndexableDictionary<string>;
  chosenSortKey: string;
  sortQuotes: (sortKey: string) => void;
}

const SortListHeader: React.FC<SortListHeaderProps> = ({ sortOrders, labels, chosenSortKey, sortQuotes }) => {
  if (sortOrders.length === 0) {
    return null;
  }
  return (
    <div className="flex gap-2 overflow-auto p-1" data-testid="sort-list-header">
      {sortOrders.map((item, index) => {
        const buttonIntent = item.name === chosenSortKey ? "primary-black" : "secondary-black";
        const buttonIsDisabled = item.name === chosenSortKey;
        return (
          <div key={index} className="flex-shrink-0">
            <Button
              size="sm"
              as="h1"
              intent={buttonIntent}
              isDisabled={buttonIsDisabled}
              onPress={() => sortQuotes(item.name)}
            >
              {labels[item.translationKey]}
            </Button>
          </div>
        );
      })}
    </div>
  );
};

export { SortListHeader };
