import { PaymentStatus, Money } from "./Types";

export const generatePaymentSummaryUrl = (
  status: PaymentStatus,
  referenceId: string | undefined,
  paymentValue: Money | undefined,
  quoteId: string
) => {
  const searchParams = new URLSearchParams();
  status && searchParams.append("status", status);
  referenceId && searchParams.append("referenceId", referenceId);
  paymentValue?.amount && searchParams.append("amount", paymentValue.amount.toString());
  return `/paymentSummary/${quoteId}${searchParams.toString() && "?"}${searchParams.toString()}`;
};
