import { TextInput, Text, Checkbox, Loading } from "@ilc-technology/luik";
import { useFormikContext } from "formik";
import { nameofFactory } from "../../Common/Helpers/TextHelper";
import { useEffect, useState } from "react";
import { Allergies, DietaryNeeds, Disabilities, getMedicals, MedicalNeeds } from "../../apis/customerServiceApi";
import { useSessionContext } from "../../contexts/SessionContext/SessionContext";
import { useDatasources } from "../../contexts/StoryblokContext/StoryblokContext";
import { LabelKey } from "../../Common/StoryblokTypes";
import { ErrorDetails } from "../../Common/Types";
import { logError } from "../../Common/services/ErrorService";
import { PropsFromChilds } from "./StudentDetailsFullUpgrade";

interface MedicalDetailsProps {
  onValueChange: (data: Partial<PropsFromChilds>) => void;
}

interface MedicalDetailsForm {
  allergies: string[],
  otherAllergies: string,
  otherDietaryNeed: string,
  dietaryNeeds: string[],
  medications: string,
  disabilities: string[],
  otherDisabilityNote: string,
  otherMedicalNeeds: string[],
  otherMedicalNote: string,
}

const MedicalDetails: React.FC<MedicalDetailsProps> = ({ onValueChange }) => {
  const formik = useFormikContext<MedicalDetailsForm>();
  const { labels } = useDatasources();
  const nameOfMedicalDetailsForm = nameofFactory<MedicalDetailsForm>();

  const allergieItems: { key: Allergies, label: string }[] = [
    { key: "PETS_ALLERGY", label: labels[LabelKey.medicalDetails_allergy_pets] },
    { key: "POLLEN_ALLERGY", label: labels[LabelKey.medicalDetails_allergy_pollen] },
    { key: "NUTS_ALLERGY", label: labels[LabelKey.medicalDetails_allergy_nuts] },
    { key: "INSECT_ALLERGY", label: labels[LabelKey.medicalDetails_allergy_insect] },
    { key: "DUST_ALLERGY", label: labels[LabelKey.medicalDetails_allergy_dust] },
    { key: "PENICILLIN_ALLERGY", label: labels[LabelKey.medicalDetails_allergy_penicilin] }];

  const dietaryItems: { key: DietaryNeeds, label: string }[] = [
    { key: "HALAL", label: labels[LabelKey.medicalDetails_dietary_halal] },
    { key: "GLUTEN_FREE", label: labels[LabelKey.medicalDetails_dietary_glutenFree] },
    { key: "VEGAN", label: labels[LabelKey.medicalDetails_dietary_vegan] },
    { key: "VEGETARIAN", label: labels[LabelKey.medicalDetails_dietary_vegetarian] },
    { key: "KOSHER", label: labels[LabelKey.medicalDetails_dietary_kosher] },
    { key: "LACTOSE_FREE", label: labels[LabelKey.medicalDetails_dietary_lactoseFree] }];

  const disablilityItems: { key: Disabilities, label: string }[] = [
    { key: "USES_A_WHEELCHAIR", label: labels[LabelKey.medicalDetails_disability_usesWheelchair] },
    { key: "VISUALLY_IMPAIRED", label: labels[LabelKey.medicalDetails_disability_visuallyImpaired] },
    { key: "DEAF", label: labels[LabelKey.medicalDetails_disability_deaf] }];

  const medicalNeeds: { key: MedicalNeeds, label: string }[] = [
    { key: "EPILEPTIC", label: labels[LabelKey.medicalDetails_medicalNeeds_epileptic] },
    { key: "ASTHMATIC", label: labels[LabelKey.medicalDetails_medicalNeeds_asthmatic] },
    { key: "DIABETIC", label: labels[LabelKey.medicalDetails_medicalNeeds_diabetic] }];

  const [allergies, setAllergies] = useState<string[]>([]);
  const [dietaryNeeds, setDietaryNeeds] = useState<string[]>([]);
  const [disabilities, setDisabilities] = useState<string[]>([]);
  const [otherMedicalNeeds, setOtherMedicalNeeds] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { session } = useSessionContext();

  useEffect(() => {
    setIsLoading(true);
    onValueChange({ isLoading: true })
    getMedicalInfo();
  }, [])

  const getMedicalInfo = () => {
    getMedicals(session.user.accountId)
      .then((result) => {
        setAllergies(result.allergies);
        setDietaryNeeds(result.dietaryNeeds);
        setDisabilities(result.disabilities);
        setOtherMedicalNeeds(result.medicalNeeds);
        formik.setFieldValue(nameOfMedicalDetailsForm.otherAllergies, result.allergyNotes);
        formik.setFieldValue(nameOfMedicalDetailsForm.otherDietaryNeed, result.dietaryNotes);
        formik.setFieldValue(nameOfMedicalDetailsForm.otherDisabilityNote, result.disabilityNotes);
        formik.setFieldValue(nameOfMedicalDetailsForm.otherMedicalNote, result.medicalNotes);
        formik.setFieldValue(nameOfMedicalDetailsForm.medications, result.medications);
      })
      .catch((error: ErrorDetails) => {
        if (error?.responseStatusCode != 404) {
          logError(error);
        }
      })
      .finally(() => {
        setIsLoading(false);
        onValueChange({ isLoading: false });
      });
  }

  useEffect(() => {
    formik.setFieldValue("allergies", allergies);
  }, [allergies])
  useEffect(() => {
    formik.setFieldValue("dietaryNeeds", dietaryNeeds);
  }, [dietaryNeeds])
  useEffect(() => {
    formik.setFieldValue("disabilities", disabilities);
  }, [disabilities])
  useEffect(() => {
    formik.setFieldValue("otherMedicalNeeds", otherMedicalNeeds);
  }, [otherMedicalNeeds])

  const handleAllergiesCheckboxOnchange = (value: boolean, checkbox: string) => {
    if (value && allergies.find(item => item === checkbox) === undefined) {
      setAllergies((prev) => prev.concat(checkbox))
    }
    else {
      setAllergies((prev) => prev.filter(item => item !== checkbox));
    }
  }

  const handleDietaryNeedsCheckboxOnchange = (value: boolean, checkbox: string) => {
    if (value && dietaryNeeds.find(item => item === checkbox) === undefined) {
      setDietaryNeeds((prev) => prev.concat(checkbox))
    }
    else {
      setDietaryNeeds((prev) => prev.filter(item => item !== checkbox));
    }
  }

  const handleDisabilityCheckboxOnchange = (value: boolean, checkbox: string) => {
    if (value && disabilities.find(item => item === checkbox) === undefined) {
      setDisabilities((prev) => prev.concat(checkbox))
    }
    else {
      setDisabilities((prev) => prev.filter(item => item !== checkbox));
    }
  }

  const handleMedicalNeedsCheckboxOnchange = (value: boolean, checkbox: string) => {
    if (value && otherMedicalNeeds.find(item => item === checkbox) === undefined) {
      setOtherMedicalNeeds((prev) => prev.concat(checkbox))
    }
    else {
      setOtherMedicalNeeds((prev) => prev.filter(item => item !== checkbox));
    }
  }

  return (
    <>
      {isLoading ? (
        <div className="mx-auto my-8">
          <Loading className="loading my-8" size="lg" intent="black" />
        </div>) : (

        <div className="mt-8 flex flex-col gap-4">
          <Text variant="label-lg-bold">{labels[LabelKey.medicalDetails_allergies]}</Text>
          {allergieItems.map((item: any) => (
            <Checkbox
              data-testid={`${item.key}-input`}
              key={item.key}
              value={item.key}
              isSelected={formik.values.allergies?.includes(item.key)}
              onChange={(value) => handleAllergiesCheckboxOnchange(value.target.checked, item.key)}
              isDisabled={formik.isSubmitting}>
              {item.label}
            </Checkbox>
          ))}
          <TextInput
            data-testid="medicalDetailsOtherAllergies-input"
            label={labels[LabelKey.medicalDetails_allergy_other]}
            placeholder={labels[LabelKey.medicalDetails_allergy_other]}
            type="text"
            name={nameOfMedicalDetailsForm.otherAllergies}
            value={formik.values.otherAllergies}
            onChange={formik.handleChange}
            isDisabled={formik.isSubmitting}
            onBlur={formik.handleBlur}
            touched={formik.touched.otherAllergies}
          />

          <Text variant="label-lg-bold">{labels[LabelKey.medicalDetails_dietaryNeeds]}</Text>
          <Text>{labels[LabelKey.medicalDetails_dietaryNeeds_description]}</Text>
          {dietaryItems.map((item: any) => (
            <Checkbox
              data-testid={`${item.key}-input`}
              key={item.key}
              value={item.key}
              isSelected={formik.values.dietaryNeeds?.includes(item.key)}
              onChange={(value) => handleDietaryNeedsCheckboxOnchange(value.target.checked, item.key)}
              isDisabled={formik.isSubmitting}>
              {item.label}
            </Checkbox>
          ))}
          <TextInput
            label={labels[LabelKey.medicalDetails_dietary_other]}
            placeholder={labels[LabelKey.medicalDetails_dietary_other]}
            type="text"
            data-testid="medicalDetailsOtherDietaryNeed-input"
            name={nameOfMedicalDetailsForm.otherDietaryNeed}
            value={formik.values.otherDietaryNeed}
            onChange={formik.handleChange}
            isDisabled={formik.isSubmitting}
            onBlur={formik.handleBlur}
            touched={formik.touched.otherDietaryNeed}
          />
          <Text variant="label-lg-bold">{labels[LabelKey.medicalDetails_medicalNeeds]}</Text>
          <Text>{labels[LabelKey.medicalDetails_medicalNeeds_description]}</Text>
          <TextInput
            data-testid="medicalDetailsMedication-input"
            label={labels[LabelKey.medicalDetails_medicalNeeds_medications]}
            placeholder={labels[LabelKey.medicalDetails_medicalNeeds_medications]}
            type="text"
            name={nameOfMedicalDetailsForm.medications}
            value={formik.values.medications}
            onChange={formik.handleChange}
            isDisabled={formik.isSubmitting}
            onBlur={formik.handleBlur}
            touched={formik.touched.medications}
          />
          {disablilityItems.map((item: any) => (
            <Checkbox
              data-testid={`${item.key}-input`}
              key={item.key}
              value={item.key}
              isSelected={formik.values.disabilities?.includes(item.key)}
              onChange={(value) => handleDisabilityCheckboxOnchange(value.target.checked, item.key)}
              isDisabled={formik.isSubmitting}>
              {item.label}
            </Checkbox>
          ))}

          <TextInput
            data-testid="medicalDetailsOtherDisabilityNote-input"
            label={labels[LabelKey.medicalDetails_disability_other]}
            placeholder={labels[LabelKey.medicalDetails_disability_other]}
            type="text"
            name={nameOfMedicalDetailsForm.otherDisabilityNote}
            value={formik.values.otherDisabilityNote}
            onChange={formik.handleChange}
            isDisabled={formik.isSubmitting}
            onBlur={formik.handleBlur}
            touched={formik.touched.otherDisabilityNote}
          />
          {medicalNeeds.map((item: any) => (
            <Checkbox
              data-testid={`${item.key}-input`}
              key={item.key}
              value={item.key}
              isSelected={formik.values.otherMedicalNeeds?.includes(item.key)}
              onChange={(value) => handleMedicalNeedsCheckboxOnchange(value.target.checked, item.key)}
              isDisabled={formik.isSubmitting}>
              {item.label}
            </Checkbox>
          ))}
          <TextInput
            data-testid="medicalDetailsOtherMedicalNote-input"
            label={labels[LabelKey.medicalDetails_medical_other]}
            placeholder={labels[LabelKey.medicalDetails_medical_other]}
            type="text"
            name={nameOfMedicalDetailsForm.otherMedicalNote}
            value={formik.values.otherMedicalNote}
            onChange={formik.handleChange}
            isDisabled={formik.isSubmitting}
            onBlur={formik.handleBlur}
            touched={formik.touched.otherMedicalNote}
          />
        </div>
      )}
    </>
  );
};

export default MedicalDetails;
