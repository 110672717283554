import React from "react";
import { Button, IconButton, Modal } from "@ilc-technology/luik";
import { useDatasources } from "../../contexts/StoryblokContext/StoryblokContext";
import { LabelKey } from "../../Common/StoryblokTypes";

interface UpgradeModalProps {
  isOpen: boolean;
  isDismissable?: boolean;
  isSaving: boolean;
  isSavingDisabled: boolean;
  header: string;
  children: React.ReactNode;
  closeHandler: () => void;
  cancelHandler: () => void;
  upgradeHandler: () => void;
}

const UpgradeModal = ({
  isOpen,
  isDismissable, 
  isSavingDisabled,
  isSaving,
  header,
  children: content,
  closeHandler,
  cancelHandler,
  upgradeHandler,
}: UpgradeModalProps) => {
  const { labels } = useDatasources();

  return (
    <Modal onClose={closeHandler} open={isOpen} isDismissable={isDismissable} className="max-w-[750px]">
      <IconButton
        aria-label="Close modal"
        className="absolute end-3 top-3"
        iconName="close"
        intent="primary-white"
        onPress={closeHandler}
        size="sm"
      />
      <Modal.Header className="text-label-md-bold text-center md:text-left px-16 md:px-16 py-6 md:py-6">
        {header}
      </Modal.Header>

      <Modal.Content>{content}</Modal.Content>

      <Modal.Footer className="flex justify-between py-6 px-6 mx-0 mt-10 md:px-10 md:py-6 ">
        <Button intent="secondary-black" onPress={cancelHandler}>
          {labels[LabelKey.cancel]}
        </Button>
        <Button
          intent="primary-black"
          data-testid="upgrade-button"
          isLoading={isSaving}
          isDisabled={isSavingDisabled}
          onPress={upgradeHandler}
        >
          {labels[LabelKey.upgrade]}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UpgradeModal;
