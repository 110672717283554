import React from "react";
import { ErrorDetails } from "../../Common/Types";
import { Loading } from "@ilc-technology/luik";
import { ContentError } from "./ContentError";
import { useDatasources } from "../../contexts/StoryblokContext/StoryblokContext";

interface ErrorComponentWrapperProps {
  isLoading: boolean;
  children?: React.ReactNode;
  error?: ErrorDetails;
}

const ErrorComponentWrapper: React.FC<ErrorComponentWrapperProps> = ({ isLoading, error, children }) => {
  const { labels } = useDatasources();

  if (isLoading && !error) {
    return (
      <div className="mx-auto my-8">
        <Loading className="loading my-8" size="lg" intent="black" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="my-8">
        <ContentError errorDetails={error} labels={labels} />
      </div>
    );
  }

  // Render children if there is no error and content loaded
  return !isLoading && !error && <>{children}</>;
};

export default ErrorComponentWrapper;
